import React, { useState, useEffect } from 'react';

import {
  faCommentDots,
  faExternalLinkAlt,
  faTimesCircle,
  faChartBar,
  faListOl,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Popover,
  PopoverBody,
  PopoverHeader,
  UncontrolledTooltip,
} from 'reactstrap';

import { questionTypes } from './constants';

import style from './AdminRankResponses.style';
import AdminRankResponse from './AdminRankResponse/AdminRankResponse';

import './AdminRankResponses.style.css';
import { clone } from '../../../../../../Services/schedule';
import { formatBearerToken } from '../../../../../../Common.functions';
import { useAuth0 } from '../../../../../../auth0/reactAuth0Spa';
import { fetchDataAgnostic } from '../../../../../../Services/dataApi';
const AdminRankResponses = ({ isGettingResponses, selectedUser, responses, getCandidateResponses, dContext, mode }) => {
  const [filterBy, setFilterBy] = useState({ QuestionText: 'No Data' }); // if not 'comments', assume by pk_EvaluationQuestion
  const [showFilterPopover, setShowFilterPopover] = useState(false);
  const [showExpandedView, setShowExpandedView] = useState(false);

  const [questions, setQuestions] = useState([]);
  const { getTokenSilently, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (responses && responses.length > 0) {
      if (filterBy && filterBy.QuestionText !== 'No Data') {
        const filterExists = questions.find((r) => {
          return r.pk_EvaluationQuestion == filterBy.pk_EvaluationQuestion;
        });
        if (filterExists) {
          setFilterBy(filterExists);
        } else {
          setFilterBy(responses[0]);
        }
      } else {
        setFilterBy(responses[0]);
      }
    }
  }, [responses]);

  useEffect(() => {
    getQuestions();
  }, [mode]);

  useEffect(() => {}, []);

  useEffect(() => {
    getQuestions();
  }, [dContext]);

  const getQuestions = () => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/questions',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          formatBearerToken(token),
        )
          .then((result) => {
            if (result.data && result.data.EvalQuestions) {
              const newQuestionList = [];
              result.data.EvalQuestions.forEach((group) => {
                if (group.questions) {
                  // evaluation: 1,
                  // prescreen: 2,
                  group.questions.forEach((q) => {
                    if ((mode == 1 && q.EnableEvaluationForm) || (mode == 2 && q.EnablePrescreenForm)) {
                      newQuestionList.push(q);
                    }
                  });
                }
              });
              newQuestionList.sort((a, b) => {
                return a.EvaluationQuestionSortOrder - b.EvaluationQuestionSortOrder;
              });
              setQuestions(newQuestionList);
              if (newQuestionList.length > 0) {
                setFilterBy(newQuestionList[0]);
              }
            } else {
              // setQuestions([newQuestions]);
            }
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const renderFilterPopover = () => {
    return (
      <Popover
        flip={false} // popperClassName="responsesFilterPopover"
        placementPrefix="responsesFilterPopover bs-popover"
        placement="right"
        isOpen={showFilterPopover}
        trigger="legacy"
        target="filter_button"
        toggle={() => {
          setShowFilterPopover(!showFilterPopover);
        }}
      >
        <PopoverHeader>
          <div style={style.spacedBetweenRow}>
            <div>Filter</div>
            <div>
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={style.clickable}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowFilterPopover(false);
                }}
              />
            </div>
          </div>
        </PopoverHeader>
        <PopoverBody>
          <div style={{ overflowY: 'scroll', height: 150 }}>
            {questions.map((question, i) => {
              const backgroundColor =
                question.pk_EvaluationQuestion === filterBy.pk_EvaluationQuestion
                  ? '#d9f5ff'
                  : i % 2 == 0
                  ? '#c7cbd1'
                  : 'white';

              return (
                <div
                  style={{ ...style.filterItem, backgroundColor }}
                  value={question.pk_EvaluationQuestion}
                  onClick={() => {
                    setFilterBy(question);
                    setShowFilterPopover(false);
                  }}
                >
                  <div>
                    <FontAwesomeIcon icon={question.QuestionTypeNumber1OrText2 == 2 ? faCommentDots : faListOl} />
                  </div>
                  <div style={style.centeredText}>
                    {i + 1}. {question.QuestionText}
                  </div>
                </div>
              );
            })}
          </div>
        </PopoverBody>
      </Popover>
    );
  };

  const renderExpandedView = () => {
    return (
      <Modal
        isOpen={showExpandedView}
        style={{ maxWidth: '700px', width: '100%' }}
        onClosed={() => {
          setShowExpandedView(false);
        }}
      >
        <ModalHeader
          closeButton
          toggle={() => {
            setShowExpandedView(false);
          }}
        >
          {selectedUser
            ? `${selectedUser.LastName ? `${selectedUser.LastName}, ` : ''} ${selectedUser.FirstName || ''}`
            : ''}
        </ModalHeader>
        <ModalBody style={{ height: '80vh' }}>
          <div style={{ ...style.responsesContainer, height: '73vh' }}>
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              {questions.map((question, i) => {
                const { pk_EvaluationQuestion } = question;
                const filterType = filterBy.questionType || questionTypes.Rating;

                const responsesToQuestion = responses.find((r) => {
                  return r.pk_EvaluationQuestion == pk_EvaluationQuestion;
                });
                const Answers = responsesToQuestion ? responsesToQuestion.Answers : [];
                return (
                  <AdminRankResponse
                    item={{ ...question, Answers, index: i }}
                    filterType={filterType}
                    displayType="expanded"
                  />
                );
              })}
            </div>

            {/* {responses.map((question, i) => {
              const filterType = filterBy.questionType;
              const evaluatorRatings = [];
              const item = {
                ...question,
                index: i + 1,
              };

              // Get Ratings for each evaluator
              // dummyEvaluators.forEach((evaluator) => {
              //   evaluatorRatings.push({
              //     ...evaluator,
              //     comment: question.questionType === questionTypes.Text ? getRandomComment() : null,
              //     rating: question.questionType === questionTypes.Rating ? Math.floor(Math.random() * 6) : null,
              //     maxRating: 5,
              //   });
              // });

              // item.evaluatorRatings = evaluatorRatings;
              return <AdminRankResponse item={{ ...item, index: i }} filterType={filterType} displayType="expanded" />;
            })} */}
          </div>
        </ModalBody>
      </Modal>
    );
  };

  const responsesToRender = [];

  responses.forEach((response) => {
    const filterType = filterBy.QuestionTypeNumber1OrText2 || 1;
    if (response.pk_EvaluationQuestion == filterBy.pk_EvaluationQuestion) {
      response.Answers.forEach((answer, i) => {
        responsesToRender.push(<AdminRankResponse item={{ ...answer, index: i }} filterType={filterType} />);
      });
    }
  });

  if (responsesToRender.length <= 0) {
    if (isGettingResponses) {
      responsesToRender.push(
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          Loading Responses. . .
        </div>,
      );
    } else {
      responsesToRender.push(
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
          No responses recorded for this candidate.
        </div>,
      );
    }
  }

  return (
    <div style={{ ...style.simpleColumn, width: '100%', height: '100%' }}>
      <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
        <div style={{ ...style.simpleColumn, width: '100%' }}>
          <div style={{ ...style.spacedBetweenRow, marginTop: 10 }}>
            <div style={{ ...style.simpleColumn, width: 'calc(100% - 45px)', marginRight: 5 }}>
              <Button id="filter_button">
                <div style={style.spacedBetweenRow}>
                  <FontAwesomeIcon
                    icon={filterBy.QuestionTypeNumber1OrText2 == 2 ? faCommentDots : faListOl}
                    style={{ marginRight: 10 }}
                  />

                  <div id="filterQuestionText" style={style.filterButtonText}>
                    {filterBy.QuestionText}
                  </div>
                </div>
              </Button>
              {renderFilterPopover()}
              <UncontrolledTooltip target="filterQuestionText">{filterBy.QuestionText}</UncontrolledTooltip>
            </div>
            <div style={{ ...style.simpleColumn, width: 40 }}>
              <Button
                onClick={() => {
                  setShowExpandedView(!showExpandedView);
                }}
              >
                <FontAwesomeIcon icon={faExternalLinkAlt} />
              </Button>
            </div>
          </div>
          <div style={style.responsesContainer}>
            <div style={{ ...style.simpleColumn, width: '100%' }}>{responsesToRender}</div>
          </div>
          {renderExpandedView()}
        </div>
      </div>
    </div>
  );
};

export default AdminRankResponses;

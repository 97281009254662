import React, { useState, useContext, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button, Input } from 'reactstrap';
import './AdminWeightCategories.style';
import style from './AdminWeightCategories.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { deleteData, fetchDataAgnostic, putData } from '../../../../../Services/dataApi';
import { formatBearerToken } from '../../../../../Common.functions';
import { useAuth0 } from '../../../../../auth0/reactAuth0Spa';
import { DepartmentContext } from '../../../../../DepartmentWrapper';
import { questionScaleKeys, questionScaleNames } from '../../AdminEvaluationSettings/AdminEvaluationSettingsConstants';
import { clone } from '../../../../../Services/schedule';
import Swal from 'sweetalert2';
import { RouterPrompt } from '../../../Common/RouterPrompt/RouterPrompt';
import { useAlert } from 'react-alert';

let typeTimeout = null;

const AdminWeightCategories = ({ setPageChanges, pageChanges }) => {
  const [season, setSeason] = useState({});
  const [weights, setWeights] = useState([]);
  const [editableWeights, setEditableWeights] = useState([]);
  const [evaluationWeightSummary, setEvaluationWeightSummary] = useState();
  const [prescreenWeightSummary, setPrescreenWeightSummary] = useState();
  const [isUpdating, setIsUpdating] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [DefaultScale, setDefaultScale] = useState([]);

  const newWeight = {
    CategoryName: 'New Weight',
    QuestionType: 'scale',
    EnableRequired: false,
    PrescreenWeight: 1,
    EvaluationWeight: 1,
    WeightDetails: '',
  };

  const [updatedID, setUpdatedID] = useState();

  const alert = useAlert();
  const dContext = useContext(DepartmentContext);

  const { getTokenSilently, loginWithRedirect } = useAuth0();

  useEffect(() => {
    getSeason();
    getWeights();
    getQuestions();
  }, []);

  useEffect(() => {
    getSummaries();
    if (editableWeights && editableWeights.length > 1 && JSON.stringify(editableWeights) !== JSON.stringify(weights)) {
      setPageChanges({ ...pageChanges, weightCategories: true });
    } else {
      setPageChanges({ ...pageChanges, weightCategories: false });
    }
  }, [editableWeights]);

  useEffect(() => {
    if (updatedID != null) {
      document.getElementById(updatedID).focus();
      setUpdatedID(null);
    }
  }, [updatedID]);

  const getSummaries = () => {
    let evalSummary = 0;
    let preScreenSummary = 0;
    editableWeights.forEach((d, i) => {
      if (i < editableWeights.length - 1) {
        preScreenSummary += parseFloat(d.PrescreenWeight || 0);
        evalSummary += parseFloat(d.EvaluationWeight || 0);
      }
    });
    setEvaluationWeightSummary(evalSummary.toFixed(2));
    setPrescreenWeightSummary(preScreenSummary.toFixed(2));
  };

  const getQuestions = () => {
    setIsUpdating(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/questions',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          formatBearerToken(token),
        )
          .then((result) => {
            setIsUpdating(false);
            if (result.data && result.data.EvalQuestions) {
              setQuestions(clone(result.data.EvalQuestions));
            } else {
              setQuestions([]);
            }

            if (result.data && result.data.DefaultScale) {
              setDefaultScale(result.data.DefaultScale);
            }
          })
          .catch((err) => {
            setIsUpdating(false);
          });
      })
      .catch((err) => {
        setIsUpdating(false);
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const updateWeight = (index, key, value, id) => {
    const newWeights = editableWeights.slice();
    if (Array.isArray(key)) {
      key.forEach((k, i) => {
        if (Array.isArray(value)) {
          newWeights[index][k] = value[i];
        } else {
          newWeights[index][k] = value;
        }
      });
    } else {
      newWeights[index][key] = value;
    }

    setEditableWeights(newWeights);
    setUpdatedID(id);
  };

  const getSeason = () => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/season',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          formatBearerToken(token),
        )
          .then((res) => {
            setSeason(res);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const getWeights = () => {
    setIsUpdating(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/season/weightCategories',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          formatBearerToken(token),
        )
          .then((res) => {
            const newWeights = res.data.slice();
            setIsUpdating(false);
            newWeights.push({
              CategoryName: 'Totals',
              QuestionType: 0,
              EnableRequired: false,
              PrescreenWeight: 0,
              EvaluationWeight: 0,
              WeightDetails: '',
            });
            setWeights(newWeights);
            setEditableWeights(clone(newWeights));
          })
          .catch((err) => {
            setIsUpdating(false);
          });
      })
      .catch((err) => {
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const saveWeights = () => {
    const newWeights = clone(editableWeights);
    newWeights.splice(newWeights.length - 1, 1);

    setIsUpdating(true);
    getTokenSilently()
      .then((token) => {
        putData(
          'department/season/weightCategories',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          {
            payload: newWeights,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            setIsUpdating(false);
            getWeights();
            setPageChanges({ ...pageChanges, weightCategories: false });
            alert.success('Changes saved!');
          })
          .catch((err) => {
            alert.error('Error saving changes!');
            setIsUpdating(false);
          });
      })
      .catch((err) => {
        alert.error('Error saving changes!');
        setIsUpdating(false);
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const addWeight = () => {
    const newWeights = clone(editableWeights);
    const newWeightToAdd = clone(newWeight);
    const newEvaluationSummary = 100 - evaluationWeightSummary;
    const newPrescreenSummary = 100 - prescreenWeightSummary;

    newWeightToAdd.EvaluationWeight = newEvaluationSummary > 0 ? limitDecimalPlaces(newEvaluationSummary) : 0;
    newWeightToAdd.PrescreenWeight = 100 - newPrescreenSummary ? limitDecimalPlaces(newPrescreenSummary) : 0;

    newWeights.splice(newWeights.length - 1, 0, clone(newWeightToAdd));
    setEditableWeights(newWeights);
  };

  /**
   *
   * @param {float} value - value to limit decimal places of
   * @param {int} decimalLimit - number of decimal places to limit to
   * @returns
   */
  const limitDecimalPlaces = (value, options = {}) => {
    const { decimalLimit = 2 } = options;
    const stringValue = value.toString();

    const decimalPlaces = stringValue.includes('.') ? stringValue.split('.')[1].length : 0;

    if (decimalPlaces > decimalLimit) {
      value = parseFloat(value).toFixed(decimalLimit);
    } else {
      value = parseFloat(value).toFixed(decimalPlaces);
    }

    return value;
  };

  const deleteNewWeight = (rowIndex) => {
    const newWeights = editableWeights.slice();
    newWeights.splice(rowIndex, 1);
    setEditableWeights(newWeights);
  };

  const deleteWeight = (weights = []) => {
    setIsUpdating(true);
    getTokenSilently()
      .then((token) => {
        deleteData(
          'department/season/weightCategories',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
          },
          {
            weightCategoryPKs: weights,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            setIsUpdating(false);
            getWeights();
          })
          .catch((err) => {
            setIsUpdating(false);
          });
      })
      .catch((err) => {
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const processDetailsForWeight = (row) => {
    const { EvaluationWeight, PrescreenWeight } = row;
    const rowPk = row.pk_EvaluationQuestionWeight;
    let qCount_Evaluate = 0;
    let qCount_Prescreen = 0;
    questions.forEach((qGroup) => {
      qGroup.questions.forEach((q) => {
        const { EnableEvaluationForm, EnablePrescreenForm, pk_EvaluationQuestionWeight } = q;
        if (pk_EvaluationQuestionWeight == rowPk) {
          if (EnableEvaluationForm) {
            qCount_Evaluate++;
          }
          if (EnablePrescreenForm) {
            qCount_Prescreen++;
          }
        }
      });
    });

    let ProcesssedEvaluateWeight = qCount_Evaluate
      ? EvaluationWeight
        ? `(${(EvaluationWeight / qCount_Evaluate).toFixed(2)} % Per Question)`
        : ''
      : '';
    let ProcessedPrescreenWeight = qCount_Prescreen
      ? PrescreenWeight
        ? `(${(PrescreenWeight / qCount_Prescreen).toFixed(2)}% Per Question)`
        : ''
      : '';

    return (
      <div style={{ fontSize: 11, textAlign: 'left', display: 'flex', flexDirection: 'column', width: '100%' }}>
        {' '}
        <div>{`${qCount_Evaluate} Associated Evaluation Questions ${ProcesssedEvaluateWeight}`}</div>{' '}
        <div>{`${qCount_Prescreen} Associated Prescreen Questions ${ProcessedPrescreenWeight}`}</div>
      </div>
    );
  };

  const headerFormatter = (column, colIndex) => {
    return <div style={style.headerStyle}>{column.text}</div>;
  };

  const cellFormatter = (cell, row, rowIndex, formatExtraData) => {
    const { label } = formatExtraData;
    const { pk_EvaluationQuestionWeight, QuestionType } = row;
    let questionTypeOptionLabel = questionScaleNames.likert5;

    if (DefaultScale && DefaultScale.length == 10) {
      questionTypeOptionLabel = questionScaleNames.likert10;
    }
    switch (label) {
      case 'Category Name':
        if (rowIndex != editableWeights.length - 1) {
          return (
            <div style={{ ...style.cellStyle, textAlign: 'flex-end' }}>
              <div style={{ marginRight: 5 }}>
                <Button
                  color="danger"
                  onClick={() => {
                    Swal.fire({
                      title: 'Warning!',
                      text: `Are you sure you want to delete ${cell}? This cannot be undone.`,
                      type: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: 'red',
                      confirmButtonText: `Delete`,
                    }).then((result) => {
                      if (result.value) {
                        if (pk_EvaluationQuestionWeight == null) {
                          deleteNewWeight(rowIndex);
                        } else {
                          deleteWeight([pk_EvaluationQuestionWeight]);
                        }
                      }
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Input
                  disabled={isUpdating}
                  id={`CategoryName_${rowIndex}`}
                  type="text"
                  defaultValue={cell}
                  style={{ textAlign: 'center' }}
                  onChange={(e) => {
                    updateWeight(rowIndex, 'CategoryName', e.target.value, `CategoryName_${rowIndex}`);
                  }}
                />
              </div>
            </div>
          );
        } else {
          return (
            <div style={{ ...style.cellStyle, textAlign: 'flex-end' }}>
              <div style={{ marginRight: 5 }}>
                <Button
                  disabled={isUpdating}
                  onClick={() => {
                    addWeight();
                  }}
                  color="success"
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Input
                  disabled
                  id={`CategoryName_${rowIndex}`}
                  type="text"
                  defaultValue={''}
                  style={{ textAlign: 'center', backgroundColor: 'white', border: 'none' }}
                />
                {/* <div style={style.centeredText}>Totals</div> */}
              </div>
            </div>
          );
        }

      case 'Question Type':
        if (rowIndex != editableWeights.length - 1) {
          return (
            <div style={style.cellStyle}>
              <Input
                type="select"
                disabled={isUpdating}
                value={cell}
                onChange={(e) => {
                  const newValue = e.target.value;
                  updateWeight(rowIndex, 'QuestionType', newValue);
                  if (newValue === 'text') {
                    updateWeight(rowIndex, ['QuestionType', 'PrescreenWeight', 'EvaluationWeight'], [newValue, 0, 0]);
                  }
                }}
              >
                <option style={{ textAlign: 'center' }} value={'text'}>
                  Text
                </option>
                <option style={{ textAlign: 'center' }} value={'scale'}>
                  {season.Scale || questionTypeOptionLabel}
                </option>
              </Input>
            </div>
          );
        } else {
          return;
        }

      case 'Required':
        if (rowIndex < editableWeights.length - 1) {
          return (
            <div style={style.cellStyle}>
              <div>
                <FontAwesomeIcon
                  icon={cell ? faCheckSquare : faSquare}
                  style={{ marginRight: 10, fontSize: 30, cursor: isUpdating ? 'not-allowed' : 'pointer' }}
                  onClick={(e) => {
                    if (!isUpdating) {
                      updateWeight(rowIndex, 'EnableRequired', !cell);
                    }
                  }}
                />
              </div>
            </div>
          );
        } else {
          return (
            <div style={{ ...style.cellStyle, textAlign: 'flex-end' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Input
                  disabled
                  id={`CategoryName_${rowIndex}`}
                  type="text"
                  defaultValue={'Totals'}
                  style={{ textAlign: 'center', backgroundColor: 'white', border: 'none', fontWeight: 'bold' }}
                />
              </div>
            </div>
          );

          return (
            <div style={{ ...style.cellStyle, textAlign: 'flex-end', alignItems: 'center' }}>
              <div>
                <Button
                  disabled={isUpdating}
                  onClick={() => {
                    addWeight();
                  }}
                  color="success"
                >
                  <FontAwesomeIcon icon={faPlus} />
                </Button>
              </div>
              <div style={style.centeredText}>Totals</div>
            </div>
          );
        }

      case 'Prescreen Weight':
        if (rowIndex != editableWeights.length - 1) {
          return (
            <div style={style.cellStyle}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Input
                  disabled={isUpdating || QuestionType === 'text'}
                  id={`PrescreenWeight_${rowIndex}_${cell}`}
                  type="number"
                  value={cell}
                  // step={0.01}
                  style={{ textAlign: 'center' }}
                  onChange={(e) => {
                    let newValue = e.target.value || 0;
                    let floatValue = parseFloat(newValue);

                    if (floatValue < 0) {
                      newValue = 0;
                    } else if (newValue > 100) {
                      newValue = 100;
                    }
                    newValue = limitDecimalPlaces(newValue, { options: { decimalLimit: 2 } });

                    // const stringValue = newValue.toString();
                    // const decimalPlaces = stringValue.includes('.') ? stringValue.split('.')[1].length : 0;
                    // if (decimalPlaces > 2) {
                    //   newValue = parseFloat(newValue).toFixed(2);
                    // } else {
                    //   newValue = parseFloat(newValue).toFixed(decimalPlaces);
                    // }

                    const thisElement = document.getElementById(`PrescreenWeight_${rowIndex}_${cell}`);
                    if (thisElement) {
                      thisElement.value = newValue;
                    }

                    updateWeight(rowIndex, 'PrescreenWeight', newValue || 0, `PrescreenWeight_${rowIndex}_${newValue}`);
                  }}
                />
                %
              </div>
            </div>
          );
        } else {
          return (
            <div style={style.cellStyle}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 'bold',
                  color: prescreenWeightSummary != 100 ? 'red' : 'black',
                }}
              >{`${prescreenWeightSummary}%`}</div>
            </div>
          );
        }

      case 'Evaluation Weight':
        if (rowIndex != editableWeights.length - 1) {
          return (
            <div style={style.cellStyle}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Input
                  disabled={isUpdating || QuestionType === 'text'}
                  id={`EvaluationWeight_${rowIndex}_${cell}`}
                  type="number"
                  defaultValue={cell}
                  // step={0.01}
                  style={{ textAlign: 'center' }}
                  onChange={(e) => {
                    let newValue = e.target.value || 0;
                    let floatValue = parseFloat(newValue);

                    if (floatValue < 0) {
                      newValue = 0;
                    } else if (newValue > 100) {
                      newValue = 100;
                    }

                    newValue = limitDecimalPlaces(newValue, { options: { decimalLimit: 2 } });
                    // const stringValue = newValue.toString();
                    // const decimalPlaces = stringValue.includes('.') ? stringValue.split('.')[1].length : 0;
                    // if (decimalPlaces > 2) {
                    //   newValue = parseFloat(newValue).toFixed(2);
                    // } else {
                    //   newValue = parseFloat(newValue).toFixed(decimalPlaces);
                    // }

                    const thisElement = document.getElementById(`EvaluationWeight_${rowIndex}_${cell}`);
                    if (thisElement) {
                      thisElement.value = newValue;
                    }
                    updateWeight(rowIndex, 'EvaluationWeight', newValue, `EvaluationWeight_${rowIndex}_${newValue}`);
                  }}
                />
                %
              </div>
            </div>
          );
        } else {
          return (
            <div style={style.cellStyle}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 'bold',
                  color: evaluationWeightSummary != 100 ? 'red' : 'black',
                }}
              >{`${evaluationWeightSummary}%`}</div>
            </div>
          );
        }
      case 'Details':
        if (rowIndex != editableWeights.length - 1) {
          return <div style={style.cellStyle}>{processDetailsForWeight(row)}</div>;
        } else {
          let isPrescreen = season && season.data && season.data.AllowPrescreen;
          let isFailPreScreenWeight = false;
          let isFailEvalWeight = false;

          if (isPrescreen && prescreenWeightSummary != 100) {
            isFailPreScreenWeight = true;
          }

          if (evaluationWeightSummary != 100) {
            isFailEvalWeight = true;
          }
          return (
            <div style={{ ...style.cellStyle, justifyContent: 'space-between' }}>
              <Button
                disabled={JSON.stringify(weights) === JSON.stringify(editableWeights) || isUpdating}
                style={{ width: '48%' }}
                color={!isFailPreScreenWeight && !isFailEvalWeight ? 'success' : 'danger'}
                onClick={() => {
                  if (isFailPreScreenWeight) {
                    Swal.fire('Error!', 'Prescreen total weight must be equal to 100%!', 'error');
                    return;
                  }

                  if (isFailEvalWeight) {
                    Swal.fire('Error!', 'Evaluation total weight must be equal to 100%!', 'error');
                    return;
                  }
                  saveWeights();
                }}
              >
                Save
              </Button>{' '}
              <Button
                disabled={JSON.stringify(weights) === JSON.stringify(editableWeights) || isUpdating}
                style={{ width: '48%' }}
                color={'danger'}
                onClick={() => {
                  Swal.fire({
                    title: 'Discard Changes',
                    text: 'Are you sure you want to discard changes?',
                    icon: 'warning',
                    showCancelButton: true,
                  }).then((result) => {
                    if (result.value) {
                      setEditableWeights(clone(weights));
                    }
                  });
                }}
              >
                Discard
              </Button>{' '}
            </div>
          );
        }

      default:
        return <div style={style.cellStyle}>{cell}</div>;
    }
  };

  const makeColumns = () => {
    const newColumns = [];
    const headers = [
      { text: 'Category Name', dataField: 'CategoryName', width: '20%' },
      { text: 'Question Type', dataField: 'QuestionType', width: '15%' },
      { text: 'Required', dataField: 'EnableRequired', width: '15%' },
      { text: 'Prescreen Weight', dataField: 'PrescreenWeight', width: '12%' },
      { text: 'Evaluation Weight', dataField: 'EvaluationWeight', width: '12%' },
      { text: 'Details', dataField: 'WeightDetails', width: '26%' },
    ];

    headers.forEach((header) => {
      if (
        (header.text === 'Prescreen Weight' && season && season.data && season.data.AllowPrescreen) ||
        header.text !== 'Prescreen Weight'
      ) {
        newColumns.push({
          dataField: header.dataField,
          text: header.text,
          label: header.text,
          formatter: cellFormatter,
          headerFormatter: headerFormatter,
          // classes: 'timeSlot',
          style: { verticalAlign: 'middle', width: header.width },
          headerStyle: {
            top: -2,
            position: 'sticky',
            verticalAlign: 'middle',
            backgroundColor: 'white',
          },
          // isTime: true,
          formatExtraData: { label: header.text },
          events: {
            onClick: (e, column, columnIndex, row, rowIndex) => {},
          },
          headerEvents: {
            onClick: (e, column, columnIndex) => {},
          },
          headerAttrs: {
            // id: 'timeSlotHeader',
          },
          attrs: (cell, row, rowIndex, colIndex) => {
            // if (row.CategoryName === 'Button') {
            //   if (colIndex == 0) {
            //     return { colSpan: headers.length };
            //   } else {
            //     return { hidden: true };
            //   }
            // }
            // return { id: `timeSlot_${row.pk_Timeslot}` };
          },
        });
      }
    });

    return newColumns;
  };

  const processIfHasChanges = () => {
    let hasChanges = false;
    editableWeights.forEach((w) => {
      if (w.pk_EvaluationQuestionWeight == null) {
        hasChanges = true;
        return;
      }
    });
    return hasChanges;
  };

  return (
    <div>
      <BootstrapTable
        noDataIndication={() => {
          return (
            <div style={{ display: 'flex', textAlign: 'center', justifyContent: 'center', fontWeight: 'bold' }}>
              No Data
            </div>
          );
        }}
        bordered={false}
        columns={makeColumns()}
        data={editableWeights}
        keyField="slot"
        rowStyle={(row, rowIndex) => {
          let styleActual = {};
          if (rowIndex >= editableWeights.length - 1) {
            styleActual = {
              position: 'sticky',
              bottom: 0,
              backgroundColor: 'white',
            };
          }

          if (row.pk_EvaluationQuestionWeight == null && rowIndex < editableWeights.length - 1) {
            styleActual.backgroundColor = '#a1e3a5';
          }

          return styleActual;
        }}
      />
    </div>
  );
};

export default AdminWeightCategories;

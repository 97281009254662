import React, { useContext, useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClock,
  faEdit,
  faPlus,
  faTrash,
  faTimes,
  faTimesCircle,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { Button, Popover, UncontrolledPopover, PopoverHeader, PopoverBody, UncontrolledTooltip } from 'reactstrap';
import AddTimeSlotPopover from '../AddTimeSlotPopover';
import CustomEventPopover from '../CustomEventPopover';
import CustomDurationPopover from '../CustomDurationPopover';

import style from './style';
import Swal from 'sweetalert2';
import { formatBearerToken } from '../../../../../Common.functions';
import { deleteData } from '../../../../../Services/dataApi';
import { DepartmentContext } from '../../../../../DepartmentWrapper';
import { useAuth0 } from '../../../../../auth0/reactAuth0Spa';
import { clone } from '../../../../../Services/schedule';

const TimeSlotPopover = ({
  forcedContext,
  row,
  addRowCustomEvent,
  hideHeaderPopover,
  scheduleData,
  fetchSchedule,
  pk_InterviewDate,
  setShowTimeSlotPopover,
  showTimeSlotPopover,
  fireConfirmationForEditingPastSchedule,
  dateIsInThePast,
  willEditThePast,
  showPastScheduleWarning,
}) => {
  const [showAddTimeSlotPopover, setShowAddTimeSlotPopover] = useState(false);
  const [showAddCustomPopover, setShowAddCustomPopover] = useState(false);
  const [showCustomDurationPopover, setShowCustomDurationPopover] = useState(false);
  const deptContext = useContext(DepartmentContext);
  const dContext = forcedContext ? forcedContext : deptContext;

  const { department = {} } = dContext;
  const { VirtualMeetingMode } = department;

  const { getTokenSilently, loginWithRedirect } = useAuth0();

  // return <div>hello</div>;

  useEffect(() => {
    handleParentPopoverVisibilityTracker('showAddTimeSlotPopover', showAddTimeSlotPopover);
  }, [showAddTimeSlotPopover]);

  useEffect(() => {
    handleParentPopoverVisibilityTracker('showAddCustomPopover', showAddCustomPopover);
  }, [showAddCustomPopover]);

  useEffect(() => {
    handleParentPopoverVisibilityTracker('showCustomDurationPopover', showCustomDurationPopover);
  }, [showCustomDurationPopover]);

  const handleParentPopoverVisibilityTracker = (key, value) => {
    const { pk_Timeslot } = row;
    const newShowTimeSlotPopover = clone(showTimeSlotPopover);
    const visibility = newShowTimeSlotPopover[pk_Timeslot];
    visibility[key] = value;
    newShowTimeSlotPopover[pk_Timeslot] = visibility;
    setShowTimeSlotPopover(newShowTimeSlotPopover);
  };

  const deleteRow = () => {
    getTokenSilently()
      .then((token) => {
        deleteData(
          '/department/season/schedule/row',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Timeslot: row.pk_Timeslot,
            pk_InterviewDate: pk_InterviewDate,
          },
          null,
          formatBearerToken(token),
        )
          .then((res) => {
            fetchSchedule(token);
            hideHeaderPopover();
          })
          .catch((err) => {
            hideHeaderPopover();
          });
      })
      .catch((err) => {
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const renderAddTimeSlotPopOver = () => {
    return (
      <Popover
        placementPrefix="addTimeSlotPopover bs-popover" // Change with room id later
        target={`addSlot_${row.pk_Timeslot}`} // Change with room id later
        trigger={`legacy`}
        isOpen={showAddTimeSlotPopover}
        toggle={async () => {
          if (dateIsInThePast && !willEditThePast) {
            const continueProcess = await fireConfirmationForEditingPastSchedule();
            if (!continueProcess) {
              return;
            }
          }
          setShowAddTimeSlotPopover(!showAddTimeSlotPopover);
          if (!showAddTimeSlotPopover) {
            setShowCustomDurationPopover(false);
            setShowAddCustomPopover(false);
          }
        }}
      >
        <PopoverHeader>
          <div style={style.flexEndRow}>Add time slot/s to schedule</div>
        </PopoverHeader>
        <PopoverBody
          style={style.popOverBody}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <AddTimeSlotPopover
            forcedContext={forcedContext}
            pk_InterviewDate={pk_InterviewDate}
            row={row}
            fetchSchedule={fetchSchedule}
            hideHeaderPopover={hideHeaderPopover}
            setShowAddTimeSlotPopover={setShowAddTimeSlotPopover}
          />
        </PopoverBody>
      </Popover>
    );
  };

  const renderAddCustomEventPopOver = () => {
    const isMainRoomAndNoLink =
      row.CustomMeetingTitle && row.VirtualRoomType === 'AllDay' && !scheduleData.metaData.MainRoomURL;
    const isCustomAndNoLink = row.CustomMeetingTitle && row.VirtualRoomType === 'Custom' && !row.CustomMeetingUrl;
    const urlIssue = VirtualMeetingMode !== 'Disabled' && (isMainRoomAndNoLink || isCustomAndNoLink);

    return (
      <Popover
        placementPrefix="customEvent bs-popover" // Change with room id later
        // popperClassName="customEvent" // Change with room id later
        target={`addCustomEvent_${row.pk_Timeslot}`} // Change with room id later
        trigger={`legacy`}
        modifiers={{ flip: { behavior: ['right'] }, preventOverflow: { boundariesElement: 'viewport' } }}
        isOpen={showAddCustomPopover}
        toggle={() => {
          if (showPastScheduleWarning) {
            return;
          }
          setShowAddCustomPopover(!showAddCustomPopover);
          if (!showAddCustomPopover) {
            setShowCustomDurationPopover(false);
            setShowAddTimeSlotPopover(false);
          }
        }}
      >
        <PopoverHeader>
          <div style={style.spaceBetweenRow}>
            <div style={style.headerTitle}>Custom Event</div>
            <FontAwesomeIcon
              icon={faTimesCircle}
              style={style.buttonIcon}
              onClick={(e) => {
                e.stopPropagation();
                setShowAddCustomPopover(false);
              }}
            />
          </div>
        </PopoverHeader>
        <PopoverBody style={style.popOverBody}>
          <CustomEventPopover
            row={row}
            setShowAddCustomPopover={setShowAddCustomPopover}
            addRowCustomEvent={addRowCustomEvent}
            hideHeaderPopover={hideHeaderPopover}
            scheduleData={scheduleData}
            fireConfirmationForEditingPastSchedule={fireConfirmationForEditingPastSchedule}
            dateIsInThePast={dateIsInThePast}
            willEditThePast={willEditThePast}
          />
        </PopoverBody>
      </Popover>
    );
  };

  const renderCustomDurationPopover = () => {
    return (
      <Popover
        placementPrefix="customDuration bs-popove" // Change with room id later
        target={`customDuration_${row.pk_Timeslot}`} // Change with room id later
        trigger={`legacy`}
        modifiers={{ flip: { behavior: ['right'] }, preventOverflow: { boundariesElement: 'viewport' } }}
        isOpen={showCustomDurationPopover}
        toggle={() => {
          if (showPastScheduleWarning) {
            return;
          }
          setShowCustomDurationPopover(!showCustomDurationPopover);
          if (!showCustomDurationPopover) {
            setShowAddCustomPopover(false);
            setShowAddTimeSlotPopover(false);
          }
        }}
      >
        <PopoverHeader>
          <div style={style.spaceBetweenRow}>
            <div style={style.headerTitle}>Custom Duration</div>
            <FontAwesomeIcon
              icon={faTimesCircle}
              style={style.buttonIcon}
              onClick={(e) => {
                e.stopPropagation();
                setShowCustomDurationPopover(false);
              }}
            />
          </div>
        </PopoverHeader>
        <PopoverBody style={style.popOverBody}>
          <CustomDurationPopover
            row={row}
            scheduleData={scheduleData}
            setShowCustomDurationPopover={setShowCustomDurationPopover}
            addRowCustomEvent={addRowCustomEvent}
            hideHeaderPopover={hideHeaderPopover}
            fireConfirmationForEditingPastSchedule={fireConfirmationForEditingPastSchedule}
            dateIsInThePast={dateIsInThePast}
            willEditThePast={willEditThePast}
            showPastScheduleWarning={showPastScheduleWarning}
          />
        </PopoverBody>
      </Popover>
    );
  };

  const isMainRoomAndNoLink =
    row.CustomMeetingTitle && row.VirtualRoomType === 'AllDay' && !scheduleData.metaData.MainRoomURL;
  const isCustomAndNoLink = row.CustomMeetingTitle && row.VirtualRoomType === 'Custom' && !row.CustomMeetingUrl;
  const urlIssue = VirtualMeetingMode !== 'Disabled' && (isMainRoomAndNoLink || isCustomAndNoLink);

  return (
    <div>
      <div style={{ ...style.simpleRow, width: '100%' }}>
        <Button
          color="success"
          id={`addSlot_${row.pk_Timeslot}`}
          style={{ ...style.genButton }}
          onClick={async (e) => {
            if (dateIsInThePast && !willEditThePast) {
              const continueProcess = await fireConfirmationForEditingPastSchedule();
              if (!continueProcess) {
                return;
              }
            }
            e.stopPropagation();
          }}
        >
          <div style={style.spaceBetweenRow}>
            <FontAwesomeIcon icon={faPlus} style={style.buttonIcon} />
            <div style={style.buttonLabel}>Insert Time Slots (Rows)</div>
          </div>
        </Button>
      </div>
      <div style={{ ...style.simpleRow, width: '100%' }}>
        <Button
          id={`addCustomEvent_${row.pk_Timeslot}`}
          style={{ ...style.genButton, backgroundColor: '#0a91ff' }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div style={style.spaceBetweenRow}>
            <FontAwesomeIcon icon={faEdit} style={style.buttonIcon} />
            <div style={style.buttonLabel}>
              Custom Event{' '}
              {urlIssue ? (
                <>
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    style={{ marginLeft: 5 }}
                    id={`url_warning_customEventBtn_${row.pk_Timeslot}`}
                  />
                  <UncontrolledTooltip target={`url_warning_customEventBtn_${row.pk_Timeslot}`}>
                    Warning: {isCustomAndNoLink ? `Custom Event URL is missing!` : `Main Room URL is missing!`}
                  </UncontrolledTooltip>
                </>
              ) : null}
            </div>
          </div>
        </Button>
      </div>
      <div style={{ ...style.simpleRow, width: '100%' }}>
        <Button
          id={`customDuration_${row.pk_Timeslot}`}
          color="warning"
          style={{ ...style.genButton }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div style={style.spaceBetweenRow}>
            <FontAwesomeIcon icon={faClock} style={style.buttonIcon} />
            <div style={style.buttonLabel}>
              Custom Duration{' '}
              {row.StandardPassingDurationInMinutes == 0 || row.CustomPassingDurationInMinutes == 0 ? (
                <>
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    style={{ color: 'black', marginLeft: 5 }}
                    id={`passing_period_warning_customDurationBtn_${row.pk_Timeslot}`}
                  />
                  <UncontrolledTooltip target={`passing_period_warning_customDurationBtn_${row.pk_Timeslot}`}>
                    Warning: No passing period - users will be unable to join meetings prior to the start time.
                  </UncontrolledTooltip>
                </>
              ) : null}
            </div>
          </div>
        </Button>
      </div>
      <div style={{ ...style.simpleRow, width: '100%' }}>
        <Button
          color="danger"
          style={{ ...style.genButton }}
          onClick={async (e) => {
            e.stopPropagation();
            if (dateIsInThePast && !willEditThePast) {
              const continueProcess = await fireConfirmationForEditingPastSchedule();
              if (!continueProcess) {
                return;
              }
            }
            Swal.fire({
              title: 'Are you sure?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete it!',
            }).then((result) => {
              if (result.isConfirmed) {
                deleteRow();
                // delete row here
              } else {
              }
            });
          }}
        >
          <div style={style.spaceBetweenRow}>
            <FontAwesomeIcon icon={faTrash} style={style.buttonIcon} />
            <div style={style.buttonLabel}>Delete Time Slot (Row)</div>
          </div>
        </Button>
      </div>
      {renderAddTimeSlotPopOver()}
      {renderAddCustomEventPopOver()}
      {renderCustomDurationPopover()}
    </div>
  );
};

export default TimeSlotPopover;

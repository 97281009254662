import { DepartmentContext } from 'DepartmentWrapper';
import { useAuth0 } from 'auth0/reactAuth0Spa';
import React, { useState, useContext } from 'react';
import { Button, Card, CardBody, CardHeader, Input, InputGroup, Table } from 'reactstrap';
import style from './MergeUsers.style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { fetchDataAgnostic, postDataAgnostic } from 'Services/dataApi';
import { formatBearerToken } from 'Common.functions';
import Loading from 'Body/Statuses/Loading';
import Swal from 'sweetalert2';

const MergeUsers = ({ onSearch, selectDepartment, setShowSuperUserPanel, selectRole }) => {
  const [goodEmail, setGoodEmail] = useState('');
  const [badEmail, setBadEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [searchResults, setSearchResults] = useState(null);

  const { getTokenSilently, loginWithRedirect } = useAuth0();
  const dContext = useContext(DepartmentContext);

  const validateEmails = () => {
    if (!goodEmail || !badEmail) {
      throw new Error('Please provide both email addresses');
    }
    if (goodEmail === badEmail) {
      throw new Error('Good and bad email addresses must be different');
    }
    // Basic email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(goodEmail) || !emailRegex.test(badEmail)) {
      throw new Error('Please provide valid email addresses');
    }
  };

  const handleMergeProcess = async (token, searchResponse) => {
    try {
      // Start the merge process
      const mergeResponse = await postDataAgnostic(
        'users/merge',
        {
          goodEmail,
          badEmail,
          goodUser: searchResponse.goodUser,
          badUser: searchResponse.badUser,
          auth0Users: searchResponse.auth0Users,
        },
        formatBearerToken(token),
      );

      if (mergeResponse.error) {
        throw new Error(mergeResponse.message || 'Error during merge process');
      }

      // Show success message with details
      await Swal.fire({
        icon: 'success',
        title: 'Merge Completed',
        text: mergeResponse.message || 'Users have been successfully merged',
        confirmButtonText: 'OK',
      });

      // Clear the form
      setGoodEmail('');
      setBadEmail('');
    } catch (error) {
      throw error;
    }
  };

  const handleSearch = async () => {
    try {
      setIsLoading(true);
      validateEmails();

      const token = await getTokenSilently();

      // Call the search endpoint
      const response = await postDataAgnostic(
        'organization/users/merge-users/search',
        {},
        {
          good_user_email: goodEmail,
          bad_user_email: badEmail,
        },
        formatBearerToken(token),
      );

      if (response.error) {
        throw new Error(response.message || 'Error searching users');
      }

      setSearchResults(response);

      // If no matches found
      if (!response.good_users.length && !response.bad_users.length) {
        await Swal.fire({
          icon: 'info',
          title: 'No Users Found',
          text: 'No users found matching the provided email addresses.',
        });
        return;
      }
    } catch (err) {
      console.error('Search error:', err);

      if (err.message === 'Login required' || err.message === 'Unauthorized') {
        loginWithRedirect();
        return;
      }

      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: err.message || 'Error searching users',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderUserTable = (users, title) => {
    if (!users || users.length === 0) return null;

    return (
      <div className="mt-4">
        <h5>{title}</h5>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Email</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>User ID</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.pk_User}>
                <td>{user.Auth0Email}</td>
                <td>{user.UserFirst}</td>
                <td>{user.UserLast}</td>
                <td>{user.pk_User}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  };

  return (
    <div>
      <Card>
        <CardHeader>Merge Users</CardHeader>
        <CardBody>
          <div style={{ ...style.simpleColumn, gap: '1rem' }}>
            <div style={{ ...style.simpleRow, alignItems: 'center', gap: '1rem' }}>
              <div style={{ width: '120px' }}>Good Email:</div>
              <InputGroup>
                <Input
                  type="email"
                  value={goodEmail}
                  onChange={(e) => setGoodEmail(e.target.value)}
                  placeholder="Enter good email address"
                />
              </InputGroup>
            </div>

            <div style={{ ...style.simpleRow, alignItems: 'center', gap: '1rem' }}>
              <div style={{ width: '120px' }}>Bad Email:</div>
              <InputGroup>
                <Input
                  type="email"
                  value={badEmail}
                  onChange={(e) => setBadEmail(e.target.value)}
                  placeholder="Enter bad email address"
                />
              </InputGroup>
            </div>

            <div style={{ ...style.simpleRow, justifyContent: 'center', marginTop: '1rem' }}>
              <Button color="primary" onClick={handleSearch} disabled={isLoading} style={{ width: '150px' }}>
                {isLoading ? (
                  <Loading options={{ labelText: 'Searching...' }} />
                ) : (
                  <>
                    <FontAwesomeIcon icon={faSearch} style={{ marginRight: '0.5rem' }} />
                    Search Users
                  </>
                )}
              </Button>
            </div>

            {searchResults && (
              <div className="mt-4">
                {renderUserTable(searchResults.good_users, 'Good Users Found')}
                {renderUserTable(searchResults.bad_users, 'Bad Users Found')}

                {searchResults.exact_matches?.good_user && (
                  <div className="mt-4">
                    <h5>Exact Match Found</h5>
                    <p>
                      <strong>Email:</strong> {searchResults.exact_matches.good_user.Auth0Email}
                      <br />
                      <strong>Name:</strong> {searchResults.exact_matches.good_user.UserFirst}{' '}
                      {searchResults.exact_matches.good_user.UserLast}
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default MergeUsers;

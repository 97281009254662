import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';
import { faPlusCircle, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { CustomInput, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { useAuth0 } from '../../../../../auth0/reactAuth0Spa';
import { formatBearerToken } from '../../../../../Common.functions';
import { DepartmentContext } from '../../../../../DepartmentWrapper';
import { fetchDataAgnostic } from '../../../../../Services/dataApi';
import FocusItem from './FocusItem';
import style from './FocusPalette.style.js';

let updateTimeout = null;
const FocusPalette = ({
  itemParentObject, // Parent of the reference.
  reference, // Used to identify "current" selected. Current selected have check but not highlighted.
  allowEdit = false,
  showIgnoreSwitch = true,
  updateSelectedFocuses, // Optional. Used to update focus list of parent.
  returnEntireFocusObject = false, // Used to indicate if updateSelectedFocuses returns entire focuses or just pk_Focuses.
  isUpdating,
  forcedDisable = false, // for readOnly mode
  hasDelay = true,
  ignoreEvaluatorFocuses,
  setIgnoreEvaluatorFocuses,
}) => {
  const [focuses, setFocuses] = useState([]);

  const [selectedFocuses, setSelectedFocuses] = useState([]); // pk_Focus
  const [selectAll, setSelectAll] = useState();
  const [nameToSearch, setNameToSearch] = useState('');
  const [token, setToken] = useState();
  const [isFetching, setIsFetching] = useState();
  const [myIgnoreEvaluatorFocuses, setMyIgnoreEvaluatorFocuses] = useState(ignoreEvaluatorFocuses);

  const { getTokenSilently, loginWithRedirect } = useAuth0();

  const dContext = useContext(DepartmentContext);

  useEffect(() => {
    if (ignoreEvaluatorFocuses) {
      setMyIgnoreEvaluatorFocuses(ignoreEvaluatorFocuses);
    }
  }, [ignoreEvaluatorFocuses]);

  useEffect(() => {
    getTokenSilently()
      .then((token) => {
        setToken(token);
      })
      .catch((err) => {
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  }, []);

  useEffect(() => {
    if (token && dContext && dContext.department) {
      getFocuses();
    }
  }, [token, dContext]);

  useEffect(() => {
    if (reference && focuses && focuses.length > 0) {
      const newFocuses = [];

      reference.forEach((focus) => {
        newFocuses.push(focus.pk_Focus);
      });
      setSelectedFocuses(newFocuses);
    }
  }, [reference, focuses]);

  const getFocuses = () => {
    setIsFetching(true);
    fetchDataAgnostic(
      'department/focuses',
      {
        pk_Department: dContext.department.pk_Department,
      },
      formatBearerToken(token),
    )
      .then((res) => {
        setIsFetching(false);
        setFocuses(res.data);
      })
      .catch((err) => {
        setIsFetching(false);
      });
  };

  // Updates focus list for parent component, if any
  const updateFocusForUser = (newFocuses) => {
    // setIsUpdating(true);
    if (updateSelectedFocuses) {
      if (hasDelay) {
        if (updateTimeout) {
          clearTimeout(updateTimeout);
        }
        updateTimeout = setTimeout(() => {
          if (returnEntireFocusObject) {
            const wholeFocuses = [];
            newFocuses.forEach((pk_Focus) => {
              const wholeFocus = focuses.find((f) => {
                return f.pk_Focus === pk_Focus;
              });
              if (wholeFocus) {
                wholeFocuses.push(wholeFocus);
              }
            });

            updateSelectedFocuses(wholeFocuses);
          } else {
            updateSelectedFocuses(newFocuses);
          }
        }, 900);
      } else {
        if (returnEntireFocusObject) {
          const wholeFocuses = [];
          newFocuses.forEach((pk_Focus) => {
            const wholeFocus = focuses.find((f) => {
              return f.pk_Focus === pk_Focus;
            });
            if (wholeFocus) {
              wholeFocuses.push(wholeFocus);
            }
          });

          updateSelectedFocuses(wholeFocuses);
        } else {
          updateSelectedFocuses(newFocuses);
        }
      }
    }
  };

  const filterFocuses = (focusesToFilter) => {
    const newFilters = [];
    focusesToFilter.forEach((focus, i) => {
      // const focus = focusesToFilter[key];
      const focusName = focus.Name || '';

      if (
        nameToSearch == '' ||
        nameToSearch == null ||
        focusName.toLowerCase().indexOf(nameToSearch.toLowerCase()) >= 0
      ) {
        newFilters.push(focus);
      }
    });
    return newFilters;
  };

  useEffect(() => {
    const newFocuses = JSON.parse(JSON.stringify(focuses));
    Object.keys(newFocuses).map((key) => {
      const focus = newFocuses[key];
      const name = focus.Name || '';
      if (name.toLowerCase().indexOf(nameToSearch.toLowerCase()) >= 0) {
        focus.isSelected = selectAll;
      }
    });
    if (selectAll) {
      updateFocusForUser(
        newFocuses.map((f) => {
          return f.pk_Focus;
        }),
      );
      setSelectedFocuses(
        newFocuses.map((f) => {
          return f.pk_Focus;
        }),
      );
    } else if (selectAll != null) {
      updateFocusForUser([]);
      setSelectedFocuses([]);
    }
  }, [selectAll]);

  const onToggleFocus = (id) => {
    const newFocuses = selectedFocuses.slice(); // JSON.parse(JSON.stringify(focuses));
    const index = newFocuses.indexOf(id);
    if (index >= 0) {
      newFocuses.splice(index, 1);
    } else {
      newFocuses.push(id);
    }

    setSelectedFocuses(newFocuses);
    updateFocusForUser(newFocuses);
  };

  const onDeleteFocus = (id) => {
    const newFocuses = JSON.parse(JSON.stringify(focuses));
    const index = newFocuses.indexOf(id);
    newFocuses.splice(index, 1);

    setSelectedFocuses(newFocuses);
  };

  // For both adding and updating
  const onSave = (focus) => {
    const newFocuses = JSON.parse(JSON.stringify(focuses));
    newFocuses[focus.id] = focus;
    setFocuses(newFocuses);
  };

  const filteredFocuses = filterFocuses(focuses);

  return (
    <div>
      {showIgnoreSwitch ? (
        <CustomInput
          disabled={isUpdating}
          style={{ marginBottom: 5 }}
          type="switch"
          label="Ignore Evaluator Focuses"
          id="ignore_evaluator_focuses"
          name="ignore_evaluator_focuses"
          checked={ignoreEvaluatorFocuses != null ? ignoreEvaluatorFocuses : myIgnoreEvaluatorFocuses}
          onClick={(e) => {
            if (setIgnoreEvaluatorFocuses) {
              setIgnoreEvaluatorFocuses(e.target.checked);
            } else {
              setMyIgnoreEvaluatorFocuses(e.target.checked);
            }
          }}
        />
      ) : null}

      <InputGroup>
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <FontAwesomeIcon
              icon={faTimes}
              id={`clearField`}
              style={style.clickable}
              onClick={() => {
                setNameToSearch('');
              }}
            />
          </InputGroupText>
        </InputGroupAddon>
        <Input
          disabled={isUpdating}
          value={nameToSearch}
          type="text"
          placeholder="Search Focus. . ."
          onChange={(e) => {
            setNameToSearch(e.target.value);
          }}
        />
        <InputGroupAddon addonType="append">
          <InputGroupText>
            {Object.keys(filteredFocuses).length || !allowEdit ? (
              <FontAwesomeIcon icon={faSearch} />
            ) : (
              <FontAwesomeIcon
                icon={faPlusCircle}
                style={{ ...style.clickable, color: '#2cdb5b' }}
                onClick={() => {
                  onSave({
                    id: nameToSearch.replaceAll(' ', '_'),
                    Name: nameToSearch,
                    Description: 'Description!',
                    icon: null,
                    isSelected: false,
                  });
                  setNameToSearch('');
                }}
              />
            )}
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>
      <div style={style.selectAllRow}>
        <FontAwesomeIcon
          icon={selectAll ? faCheckSquare : faSquare}
          style={{ ...style.clickable, marginRight: 5, cursor: forcedDisable ? 'not-allowed' : 'pointer' }}
          onClick={() => {
            if (!isUpdating && !forcedDisable) {
              setSelectAll(!selectAll);
            }
          }}
        />
        <div>Select All</div>
      </div>
      <div style={style.dropDownBody}>
        {filteredFocuses && filteredFocuses.length > 0 ? (
          filteredFocuses.map((focus, i) => {
            return (
              <FocusItem
                forcedDisable={forcedDisable}
                key={focus.pk_Focus}
                focus={focus}
                reference={reference}
                index={selectedFocuses.indexOf(focus.pk_Focus)}
                selectedFocuses={selectedFocuses}
                allowEdit={allowEdit}
                onToggleFocus={onToggleFocus}
                onDeleteFocus={onDeleteFocus}
                onSave={onSave}
                isUpdating={isUpdating}
              />
            );
          })
        ) : isFetching ? (
          <div style={{ textAlign: 'center' }}> Fetching . . .</div>
        ) : (
          <div style={{ textAlign: 'center' }}> No Focuses</div>
        )}
      </div>
    </div>
  );
};

export default FocusPalette;

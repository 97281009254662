import React, { useEffect, useState, useContext } from 'react';
import { useAlert } from 'react-alert';
import BellSound from '../../../../../../assets/sounds/shipbell.wav';
import { Button, Card, CardBody, CardHeader, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { faCheckSquare, faEye, faSave, faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { clone } from '../../../../../../Services/schedule';
import Swal from 'sweetalert2';
import { useAuth0 } from '../../../../../../auth0/reactAuth0Spa';
import { formatBearerToken } from '../../../../../../Common.functions';
import { DepartmentContext } from '../../../../../../DepartmentWrapper';
import { deleteData, postDataAgnostic, putData } from '../../../../../../Services/dataApi';

import style from './AddNotificationModal.style';
let typeTimeout = null;
const AddNotificationModal = ({ notification, getNotifications, setShowAddNotificationModal }) => {
  const [Sound, setSound] = useState('Bell');
  const [Message, setMessage] = useState('');
  const [Minutes, setMinutes] = useState(0);

  const [EnableAlertEvaluator, setEnableAlertEvaluator] = useState(true);
  const [EnableAlertCandidate, setEnableAlertCandidate] = useState(true);
  const [Type, setType] = useState('End of Passing');

  const [isSaving, setIsSaving] = useState(false);

  const alert = useAlert();
  const { getTokenSilently, loginWithRedirect } = useAuth0();
  const dContext = useContext(DepartmentContext);

  //   useEffect(() => {
  //     if (notification) {
  //       const {
  //         Minutes = 0,
  //         Type, // meeting_end, block_end
  //         EnableAlertEvaluator,
  //         EnableAlertCandidate,
  //         notifEndsMeeting,
  //         Message,
  //         Sound,
  //       } = notification || {};

  //       setSound(Sound);
  //       setMessage(Message);
  //       setEnableAlertEvaluator(EnableAlertEvaluator);
  //       setEnableAlertCandidate(EnableAlertCandidate);
  //       setType(Type);
  //       setMinutes(Minutes);
  //     }
  //   }, [notification]);

  const checkForChanges = () => {
    const currentValues = {
      Minutes,
      Type, // meeting_end, block_end
      EnableAlertEvaluator,
      EnableAlertCandidate,
      Message,
      Sound,
    };

    let hasChanges = false;
    if (notification) {
      Object.keys(currentValues).forEach((key) => {
        if (currentValues[key] != notification[key]) {
          hasChanges = true;
        }
      });
    }

    return hasChanges;
  };

  const handleChange = (key, value) => {
    const newNotification = { ...clone(notification) };

    newNotification.Sound = Sound;
    newNotification.Message = Message;
    newNotification.Minutes = Minutes;
    newNotification.EnableAlertEvaluator = EnableAlertEvaluator;
    newNotification.EnableAlertCandidate = EnableAlertCandidate;
    newNotification.Type = Type;

    newNotification[key] = value;
    // if (updateNotification) {
    //   updateNotification(newNotification, index);
    // }
  };

  const notifTest = () => {
    let soundToPlay = BellSound;

    switch (Sound) {
      case 'Bell':
        soundToPlay = BellSound;
        break;
      default:
        soundToPlay = BellSound;
        break;
    }

    let audio = new Audio(soundToPlay);
    audio.volume = 0.9;
    new Notification(Message);

    if (Sound !== 'silent') {
      audio.play();
    }
  };

  const fireAlertWarning = () => {
    Swal.fire({
      title: 'Error!',
      text: `You must alert at least one party - either Evaluator or Candidate!`,
      type: 'error',
    });
  };

  const addNotif = () => {
    setIsSaving(true);
    getTokenSilently()
      .then((token) => {
        postDataAgnostic(
          'department/alerts',
          { pk_Department: dContext.department.pk_Department },
          {
            payload: {
              Minutes,
              Type,
              EnableAlertEvaluator,
              EnableAlertCandidate,
              Message,
              Sound,
            },
          },
          formatBearerToken(token),
        )
          .then((res) => {
            alert.success('Added Notification!');
            setIsSaving(false);
            getNotifications();
            setShowAddNotificationModal(false);
          })
          .catch((err) => {
            alert.error('Failed to add Notification!');
            setIsSaving(false);
          });
      })
      .catch((err) => {
        setIsSaving(false);
        alert.error('Failed to add Notification!');
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  return (
    <div style={style.notifItem}>
      <div>
        <div>
          <div style={{ ...style.simpleRow, marginBottom: 20 }}>
            <div style={{ ...style.simpleColumn, width: 200, marginRight: 10 }}>
              <div style={{ ...style.alignedRow, paddingRight: 10 }}>
                <Input
                  type="number"
                  disabled={isSaving}
                  value={Minutes}
                  style={{ marginRight: 10, width: 80 }}
                  onChange={(e) => {
                    let newValue = e.target.value;
                    if (newValue < 0) {
                      newValue = 0;
                    }
                    setMinutes(newValue);
                    clearTimeout(typeTimeout);
                    typeTimeout = setTimeout(() => {
                      handleChange('Minutes', parseInt(newValue));
                    }, 1500);
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                />
                <div style={{ width: 120 }}> Minutes before </div>
              </div>
            </div>
            <div style={{ ...style.simpleColumn, width: '100%' }}>
              <Input
                type="select"
                disabled={isSaving}
                value={Type}
                onChange={(e) => {
                  handleChange('Type', e.target.value);
                  setType(e.target.value);
                }}
              >
                <option value={'End of Meeting'}>End of Meeting</option>
                <option value={'End of Passing'}>End of Passing Period</option>
              </Input>
            </div>
          </div>
          <div style={{ ...style.spaceAroundRow, marginBottom: 20 }}>
            <div style={{ ...style.simpleColumn, alignItems: 'center' }}>
              <div style={{ ...style.alignedRow }}>
                <FontAwesomeIcon
                  icon={EnableAlertEvaluator ? faCheckSquare : faSquare}
                  style={style.checkbox}
                  onClick={() => {
                    if (isSaving) {
                      return;
                    }
                    const newValue = !EnableAlertEvaluator;
                    if (!newValue && !EnableAlertCandidate) {
                      fireAlertWarning();
                      return;
                    }
                    setEnableAlertEvaluator(newValue);
                    handleChange('EnableAlertEvaluator', newValue);
                  }}
                />
                <div>Alert Evaluator</div>
              </div>
            </div>
            <div style={{ ...style.simpleColumn, alignItems: 'center' }}>
              <div style={{ ...style.alignedRow }}>
                <FontAwesomeIcon
                  icon={EnableAlertCandidate ? faCheckSquare : faSquare}
                  style={style.checkbox}
                  onClick={() => {
                    if (isSaving) {
                      return;
                    }
                    const newValue = !EnableAlertCandidate;
                    if (!newValue && !EnableAlertEvaluator) {
                      fireAlertWarning();
                      return;
                    }
                    setEnableAlertCandidate(newValue);
                    handleChange('EnableAlertCandidate', newValue);
                  }}
                />
                <div>Alert Candidate</div>
              </div>
            </div>
            {/* <div style={{ ...style.simpleColumn, alignItems: 'center' }}>
                    <div style={{ ...style.alignedRow }}>
                      <FontAwesomeIcon icon={EnableAlertCandidate ? faCheckSquare : faSquare} style={style.checkbox} />
                      <div>Terminate Virtual Meeting</div>
                    </div>
                  </div> */}
          </div>
          <div style={{ ...style.simpleRow, marginBottom: 20 }}>
            {/* <div style={{ ...style.simpleColumn, width: 70 }}>
                <div style={style.alignedRow}>Message:</div>
              </div> */}
            <div style={{ ...style.simpleColumn, width: '100%' }}>
              <Label for="message">Message</Label>
              <Input
                id="message"
                disabled={isSaving}
                type="textarea"
                value={Message}
                rows={2}
                onChange={(e) => {
                  const newValue = e.target.value;
                  setMessage(newValue);
                  clearTimeout(typeTimeout);
                  typeTimeout = setTimeout(() => {
                    handleChange('Message', newValue);
                  }, 1500);
                }}
              />
            </div>
          </div>
          <div style={{ ...style.simpleRow, marginBottom: 20 }}>
            <div style={{ ...style.simpleColumn, width: '90%', marginRight: 10 }}>
              <Label for="sound_dropdown">Sound</Label>
              <Input
                type="select"
                disabled={isSaving}
                value={Sound}
                id="sound_dropdown"
                onChange={(e) => {
                  setSound(e.target.value);
                }}
              >
                <option value={'silent'}>Silent</option>
                <option value={'Bell'}>Bell</option>
              </Input>
            </div>
            <div style={{ ...style.simpleColumn, width: 150, paddingTop: 28 }}>
              <Button
                color="warning"
                disabled={isSaving}
                onClick={() => {
                  notifTest();
                  alert.info(`${Message}`);
                }}
              >
                Test
                {/* <FontAwesomeIcon icon={faVolumeUp} /> */}
              </Button>
            </div>
          </div>
          <div style={{ alignItems: 'right', display: 'flex', justifyContent: 'right' }}>
            <Button
              color="success"
              style={{ width: 200 }}
              disabled={isSaving}
              onClick={() => {
                addNotif();
              }}
            >
              Add Notification
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNotificationModal;

import React, { useContext, useState, useRef, useEffect } from 'react';
import { Button, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useAuth0 } from '../../../../../auth0/reactAuth0Spa';
import { formatBearerToken, isInvalidEmail } from '../../../../../Common.functions';
import { DepartmentContext } from '../../../../../DepartmentWrapper';
import { postDataAgnostic } from '../../../../../Services/dataApi';
import { clone } from '../../../../../Services/schedule';
import './AddNewCandidateModal.style.css';
import style from './style';
import { NotificationManager } from 'react-notifications';

const AddNewCandidateModal = ({ showModal = false, setShowModal, token, getCandidates, ...props }) => {
  const [newCandidate, setNewCandidate] = useState({});
  const [triedToSubmit, setTriedToSubmit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const dContext = useContext(DepartmentContext);
  const firstNameRef = useRef();
  const { getTokenSilently, loginWithRedirect } = useAuth0();

  const addCandidate = (candidate) => {
    const requiredFields = ['FirstName', 'LastName', 'Email'];
    let isValid = true;
    requiredFields.forEach((f) => {
      if (!candidate[f] || candidate[f] == null) {
        isValid = false;
      }
    });
    if (!isValid) {
      return;
    }

    setIsSubmitting(true);
    getTokenSilently()
      .then((token) => {
        postDataAgnostic(
          'department/season/candidate',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
          },
          {
            candidateObject: candidate,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            setIsSubmitting(false);
            getCandidates();
            setShowModal(false);
            NotificationManager.success('Success!', 'Candidate Saved');
          })
          .catch((err) => {
            setIsSubmitting(false);
            NotificationManager.error('Error!', 'Candidate not saved');
          });
      })
      .catch((err) => {
        setIsSubmitting(false);
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
        NotificationManager.error('Error!', 'Candidate not saved');
      });
  };

  const onChange = (key, value) => {
    const newNewCandidate = clone(newCandidate);
    newNewCandidate[key] = value;
    setNewCandidate(newNewCandidate);
  };

  useEffect(() => {
    if (showModal) {
      setTimeout(() => {
        firstNameRef.current.focus();
      }, 200);
    }
  }, [showModal, firstNameRef]);

  const { FirstName, MiddleName, LastName, Email } = newCandidate;
  return (
    <Modal
      isOpen={showModal}
      centered={true}
      style={{ maxWidth: '60%' }}
      onClosed={() => {
        setTriedToSubmit(false);
        setNewCandidate({});
      }}
    >
      <ModalHeader
        closeButton
        toggle={() => {
          if (setShowModal) {
            setShowModal(!showModal);
          }
        }}
      >
        Add New Candidate
      </ModalHeader>
      <ModalBody>
        <div style={{ height: 280 }}>
          <div style={style.simpleRow}>
            <div style={{ ...style.simpleColumn, width: '33%' }}>
              <FormGroup>
                <Label for="firstName">First Name</Label>
                <Input
                  invalid={triedToSubmit && (FirstName == '' || FirstName == null)}
                  type="text"
                  name="firstName"
                  id="firstName"
                  className="focus"
                  placeholder="First Name"
                  innerRef={firstNameRef}
                  onChange={(e) => {
                    onChange('FirstName', e.target.value);
                  }}
                />
                <FormFeedback>First Name is required!</FormFeedback>
              </FormGroup>
            </div>
            <div style={{ ...style.simpleColumn, width: '33%' }}>
              <FormGroup>
                <Label for="middleName">Middle Name</Label>
                <Input
                  type="text"
                  name="middleName"
                  id="middleName"
                  placeholder="Middle Name"
                  onChange={(e) => {
                    onChange('MiddleName', e.target.value);
                  }}
                />
              </FormGroup>
            </div>
            <div style={{ ...style.simpleColumn, width: '33%' }}>
              <FormGroup>
                <Label for="lastName">Last Name</Label>
                <Input
                  invalid={triedToSubmit && (LastName == '' || LastName == null)}
                  type="lastName"
                  name="lastName"
                  id="lastName"
                  placeholder="Last Name"
                  onChange={(e) => {
                    onChange('LastName', e.target.value);
                  }}
                />
                <FormFeedback>Last Name is required!</FormFeedback>
              </FormGroup>
            </div>
          </div>
          <div style={{ borderBottom: '1px solid black', marginTop: '15px', marginBottom: '20px' }} />
          <div style={style.simpleRow}>
            <div style={{ ...style.simpleColumn, width: '50%' }}>
              <FormGroup>
                <Label for="email">Email Address</Label>
                <Input
                  invalid={(triedToSubmit && (Email == '' || Email == null)) || isInvalidEmail(Email)}
                  // invalid={(triedToSubmit && (UserEmail == '' || UserEmail == null)) || isInvalidEmail(UserEmail)}
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email"
                  onChange={(e) => {
                    onChange('Email', e.target.value);
                  }}
                />
                <FormFeedback>{isInvalidEmail(Email) ? 'Invalid Email format!' : 'Email is Required!'}</FormFeedback>
              </FormGroup>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div style={style.footerContainer}>
          <div>
            <Button
              disabled={isSubmitting}
              style={style.saveButton}
              color="success"
              onClick={() => {
                setTriedToSubmit(true);
                addCandidate(newCandidate);
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default AddNewCandidateModal;

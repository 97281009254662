import Loading from 'Body/Statuses/Loading';
import React, { useEffect, useState, useContext } from 'react';
import { Col, Row } from 'reactstrap';
import { useAuth0 } from '../../../../../auth0/reactAuth0Spa';
import { DepartmentContext } from '../../../../../DepartmentWrapper';
import { formatBearerToken } from '../../../../../Common.functions';
import { fetchDataAgnostic } from '../../../../../Services/dataApi';
import AdminEmailPreview from './AdminEmailPreview/AdminEmailPreview';
import AdminSentItems from './AdminSentItems/AdminSentItems';

const AdminCommunicationHistoryLanding = ({ activeDepartment, token }) => {
  const [selectedSentItem, setSelectedSentItem] = useState();
  const [sentMessages, setSentMessages] = useState([]);
  const [isLoadingSentMessages, setIsLoadingSentMessages] = useState(false);

  const { getTokenSilently, loginWithRedirect } = useAuth0();
  const dContext = useContext(DepartmentContext);
  const { department, season } = dContext;

  useEffect(() => {
    if (department && season) {
      getSentMessages();
    }
  }, [department, season]);

  const getSentMessages = () => {
    if (!activeDepartment || activeDepartment.pk_Department == null) {
      return;
    }
    setIsLoadingSentMessages(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/communication/history',
          { pk_Department: department.pk_Department, pk_Season: season.pk_Season },
          formatBearerToken(token),
        )
          .then((res) => {
            setIsLoadingSentMessages(false);
            setSentMessages(res.data);
          })
          .catch((err) => {
            setIsLoadingSentMessages(false);
          });
      })
      .catch((err) => {
        console.log('getSentMessages getToken err: ', err);
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  if (department == null) {
    return <div />;
  }

  return (
    <Col>
      <Row>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: 'calc(100vh - 310px)',
            width: '29%',
            padding: 10,
            paddingTop: 5,
            margin: 5,
            overflowY: 'clip',
          }}
          className="card shadow bg-light"
        >
          <AdminSentItems
            isFetchingItems={isLoadingSentMessages}
            activeDepartment={activeDepartment}
            selectedSentItem={selectedSentItem}
            setSelectedSentItem={setSelectedSentItem}
            sentMessages={sentMessages}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '69%',
            padding: 10,
            margin: 5,
            height: 'calc(100vh - 310px)',
            overflowY: 'scroll',
          }}
          className="card shadow bg-light"
        >
          {isLoadingSentMessages ? (
            <div style={{ marginTop: '10vh' }}>
              {' '}
              <Loading options={{ labelText: 'Loading Messages. . .' }} />{' '}
            </div>
          ) : (
            <AdminEmailPreview itemToPreview={selectedSentItem} />
          )}
        </div>
      </Row>
    </Col>
  );
};

export default AdminCommunicationHistoryLanding;

import React, { useEffect, useState, Fragment } from 'react';
import { fetchDataAgnostic } from '../../../../Services/dataApi';
import { Col, Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { formatBearerToken, swalErrorMessage } from '../../../../Common.functions';
import SeasonList from './Seasons/SeasonList';
import { putData, postDataAgnostic } from '../../../../Services/dataApi';
import UserPhotoTypes from '../../../DragAndDropFiles/UserPhoto/UserPhotoTypes';
import UserPhoto from '../../../DragAndDropFiles/UserPhoto/UserPhoto';

import momentTZ from 'moment-timezone';
import { NotificationManager } from 'react-notifications';

import Swal from 'sweetalert2';
import { programTypes, applicationSystems } from '../../../../enums';
import { useAuth0 } from '../../../../auth0/reactAuth0Spa';
import DepartmentListItem from './DepartmentListItem';
import Style from '../../EntityWrapper.style';
import { useAlert } from 'react-alert';

const DepartmentCreationAndEdit = ({
  departmentLogo,
  departmentName,
  departmentReplyToAddress,
  departmentReplyToName,
  departments,
  departmentShortName,
  departmentWebsite,
  selectedDepartment,
  selectedOrganization,
  setDepartmentLogo,
  setDepartmentName,
  setDepartmentReplyToAddress,
  setDepartmentReplyToName,
  setDepartments,
  setDepartmentShortName,
  setDepartmentWebsite,
  setSelectedDepartment,
}) => {
  const [applicationSystem, setApplicationSystem] = useState(applicationSystems[0]);
  const [programType, setProgramType] = useState(programTypes[0]);
  const [seasons, setSeasons] = useState([]);
  const [timeZone, setTimeZone] = useState('');
  const [pk_Department, setpk_Department] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [deptConfig, setDeptConfig] = useState({});
  const [timeZones, setTimeZones] = useState([]);
  const [disableFields, setDisableFields] = useState(false);
  const [seasonPopoverOpen, setSeasonPopoverOpen] = useState(false);
  const [updatingActiveSeason, setUpdatingActiveSeason] = useState(false);

  const [isFetchingDepartment, setIsFetchingDepartment] = useState(false);

  const alert = useAlert();
  const applicationSystemDBValues = {
    sf_match: 'SF Match',
    eras: 'ERAS',
    oral_board: 'Oral Board',
    custom: 'Custom',
  };

  const handleSeasonToggle = (e, pk_Season) => {
    setSeasonPopoverOpen((prevState) => {
      // if it matches the current organization, close it
      if (prevState === pk_Season) return false;

      // if it doesn't match, open it
      return pk_Season;
    });
  };

  const updateSeasonArray = (season) => {
    // update values in the seasons array
    setSeasons((prevState) => {
      // eslint-disable-next-line
      let clone = structuredClone(prevState);

      clone.forEach((item) => {
        if (item.pk_Season === season.pk_Season) {
          item.SeasonName = season.SeasonName;
        }
      });
      return clone;
    });
  };

  useEffect(() => {
    var timezones = momentTZ.tz.names();
    const qualifier = 'US';

    // need to move US to the beginning of the array for easy access (as most clients will be based in the US)
    const itemsToUnshift = [];
    timezones = timezones.filter((item) => {
      if (item.startsWith(qualifier)) {
        itemsToUnshift.unshift(item);
        return false;
      }
      return true;
    });

    itemsToUnshift.forEach((item) => {
      timezones.unshift(item);
    });
    setTimeZones(timezones);
  }, []);

  useEffect(() => {
    if (!pk_Department || !departments) return;

    //find out if value is in array
    const departmentIsInArray = departments.find((department) => department.pk_Department === pk_Department);

    // if the selected department is no longer in the array of possible departments, reset the state values
    if (!departmentIsInArray || departments.length === 0) {
      resetStateValues();
      setSelectedDepartment(false);
    }
  }, [pk_Department, departments]);

  const updateArrayValues = (department) => {
    setDepartments((prevState) => {
      // eslint-disable-next-line
      let clone = structuredClone(prevState);

      clone.forEach((item) => {
        if (item.pk_Department === Number(department.pk_Department)) {
          item.ApplicationSystem = department.ApplicationSystem;
          item.DepartmentName = department.DepartmentName;
          item.DepartmentShortName = department.DepartmentShortName;
          item.DepartmentEmail = department.DepartmentEmail;
          item.ReplyAddressDisplayName = department.ReplyAddressDisplayName;
          item.DepartmentWebsite = department.DepartmentWebsite;
          item.ProgramType = department.ProgramType;
          item.TimeZone = department.TimeZone;
        }
      });

      return clone;
    });
  };

  const createDepartment = () => {
    setDisableFields(true);
    getTokenSilently()
      .then((token) => {
        postDataAgnostic(
          'organization/department',
          { pk_Organization: selectedOrganization },
          {
            payload: {
              ApplicationSystem: applicationSystem,
              DepartmentName: departmentName,
              DepartmentAlias: departmentShortName,
              DepartmentWebsite: departmentWebsite,
              DepartmentEmail: departmentReplyToAddress,
              ReplyAddressDisplayName: departmentReplyToName,
              ProgramType: programType,
              TimeZone: timeZone,
            },
          },
          formatBearerToken(token),
        )
          .then((result) => {
            setDepartments((prevState) => {
              // eslint-disable-next-line
              let clone = structuredClone(prevState);
              clone.unshift(result.data);
              return clone;
            });
            setSelectedDepartment(result.data.pk_Department);
            NotificationManager.success('Success!', 'Department Created');
          })
          .catch((err) => {})
          .finally(() => {
            setDisableFields(false);
          });
      })
      .catch((err) => {
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      })
      .finally(() => {
        setDisableFields(false);
      });
  };

  const saveSeason = (seasonToSave) => {
    setUpdatingActiveSeason(true);
    const pk_Season = seasonToSave.pk_Season;
    delete seasonToSave.pk_Season;
    getTokenSilently()
      .then((token) => {
        putData(
          'department/season',
          { pk_Department: pk_Department, pk_Season: pk_Season },
          { seasonObject: seasonToSave },
          formatBearerToken(token),
        )
          .then((res) => {
            alert.success('Saved Season!');
            setUpdatingActiveSeason(false);
            if (fetchDepartment) {
              fetchDepartment();
            }
          })
          .catch((err) => {
            alert.error('Error saving Season!');

            setUpdatingActiveSeason(false);
          });
      })
      .catch((err) => {
        alert.error('Error saving Season!');
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const updateValues = () => {
    getTokenSilently()
      .then((token) => {
        putData(
          'department/settings',
          {
            pk_Department: pk_Department,
          },
          {
            payload: {
              ApplicationSystem: applicationSystem,
              DepartmentName: departmentName,
              DepartmentShortName: departmentShortName,
              DepartmentWebsite: departmentWebsite,
              DepartmentEmail: departmentReplyToAddress,
              ReplyAddressDisplayName: departmentReplyToName,
              ProgramType: programType,
              TimeZone: timeZone,
            },
          },
          formatBearerToken(token),
        )
          .then((res) => {
            if (res.status === 200) {
              updateArrayValues(res.data);
              Swal.fire({
                title: 'Success!',
                text: 'Department settings have been updated!',
                icon: 'success',
              });
            } else {
              swalErrorMessage();
            }
          })
          .catch((err) => {
            swalErrorMessage();
          });
      })
      .catch((err) => {
        swalErrorMessage();
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const handleDepartmentClick = (e) => {
    e.preventDefault();
    if (selectedDepartment) {
      updateValues();
    } else {
      createDepartment();
    }
  };

  const resetStateValues = () => {
    setDepartmentLogo('');
    setDepartmentName('');
    setDepartmentShortName('');
    setDepartmentWebsite('');
    setDepartmentReplyToAddress('');
    setDepartmentReplyToName('');
    setSeasons([]);
    setpk_Department('');

    if (applicationSystems && applicationSystems[0]) {
      setApplicationSystem(applicationSystems[0]);
    }
    if (programTypes && programTypes[0]) {
      setProgramType(programTypes[0]);
    }
    if (timeZones && timeZones[0]) {
      setTimeZone(timeZones[0]);
    }
  };

  const { getTokenSilently, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!selectedDepartment) {
      resetStateValues();
      return;
    }

    if (!timeZones || timeZones.length === 0) return;

    resetStateValues();
    setIsLoading(true);

    fetchDepartment();
  }, [selectedDepartment, timeZones]);

  const fetchDepartment = () => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic('organization/department', { pk_Department: selectedDepartment }, formatBearerToken(token))
          .then((result) => {
            setIsLoading(false);
            const {
              ApplicationSystem,
              DepartmentLogo,
              DepartmentName,
              DepartmentShortName,
              DepartmentWebsite,
              ReplyAddressDisplayName,
              DepartmentEmail,
              ProgramType,
              Seasons,
              TimeZone,
              pk_Department,
            } = result.data;
            setApplicationSystem(ApplicationSystem);
            setDepartmentLogo(DepartmentLogo);
            setDepartmentName(DepartmentName);
            setDepartmentShortName(DepartmentShortName);
            setDepartmentWebsite(DepartmentWebsite);
            setDepartmentReplyToAddress(DepartmentEmail);
            setDepartmentReplyToName(ReplyAddressDisplayName);

            // if for whatever reason the program type comes back null, set it to the first position in the program enum
            if (!ProgramType) {
              setProgramType(programType[0]);
            } else {
              setProgramType(ProgramType);
            }

            // if no application system is present set it to the first position in the application system enum
            if (!ApplicationSystem) {
              setApplicationSystem(applicationSystems[0]);
            } else {
              setApplicationSystem(applicationSystemDBValues[ApplicationSystem]);
            }
            // if no timezone is present set it to the first position in the timezone enum
            if (!TimeZone) {
              setTimeZone(timeZones[0]);
            } else {
              setTimeZone(TimeZone);
            }

            setSeasons(Seasons);
            setTimeZone(TimeZone);
            setpk_Department(pk_Department);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  if (isLoading) return <div />;

  return (
    <>
      <div style={Style.departmentWrapperWidget}>
        {pk_Department && (
          <div style={{ maxWidth: '150px' }}>
            <UserPhoto
              imgStyle={{ width: 100, objectFit: 'scale-down' }}
              photoUrl={departmentLogo}
              pk_Department={pk_Department}
              setDeptConfig={setDeptConfig}
              callback={(id, photoURL) => {
                // handleChange('UserPhotoUrl', photoURL);
                if (photoURL) {
                  setDepartmentLogo(photoURL);
                }
                setDepartments((prevState) => {
                  // eslint-disable-next-line
                  let clone = structuredClone(prevState);
                  const index = clone.findIndex((x) => x.pk_Department === pk_Department);

                  if (clone[index]) {
                    clone[index].DepartmentLogo = photoURL;
                  }
                  return clone;
                });
              }}
              type={UserPhotoTypes.DepartmentLogo}
            />
          </div>
        )}
        <Form>
          <FormGroup row>
            <Label for="departmentName" sm={2}>
              Name:
            </Label>
            <Col sm={10}>
              <Input
                size="sm"
                type="text"
                name="departmentName"
                disabled={disableFields}
                value={departmentName}
                onChange={(e) => setDepartmentName(e.target.value)}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="departmentAlias" sm={2}>
              Alias:
            </Label>
            <Col sm={10}>
              <Input
                size="sm"
                type="text"
                name="departmentAlias"
                disabled={disableFields}
                value={departmentShortName}
                onChange={(e) => setDepartmentShortName(e.target.value)}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="programSelect" sm={2}>
              Program Type:
            </Label>
            <Col sm={10}>
              <Input
                size="sm"
                type="select"
                name="select"
                item="programSelect"
                disabled={disableFields}
                value={programType}
                onChange={(e) => setProgramType(e.target.value)}
              >
                {programTypes.map((item) => {
                  return (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  );
                })}
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="applicantSourceSelect" sm={2}>
              Applicant Source:
            </Label>
            <Col sm={10}>
              <Input
                size="sm"
                type="select"
                name="select"
                item="applicantSourceSelect"
                disabled={disableFields}
                value={applicationSystem}
                onChange={(e) => setApplicationSystem(e.target.value)}
              >
                {applicationSystems.map((item) => {
                  return (
                    <option key={item.dataKey} value={item.dataKey}>
                      {item.label}
                    </option>
                  );
                })}
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="timezoneSelect" sm={2}>
              Time Zone:
            </Label>
            <Col sm={10}>
              <Input
                size="sm"
                type="select"
                name="select"
                item="timezoneSelect"
                disabled={disableFields}
                value={timeZone}
                onChange={(e) => setTimeZone(e.target.value)}
              >
                {timeZones.map((item) => {
                  return (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  );
                })}
              </Input>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="departmentAlias" sm={2}>
              Website:
            </Label>
            <Col sm={10}>
              <Input
                size="sm"
                type="text"
                name="websiteText"
                disabled={disableFields}
                value={departmentWebsite}
                onChange={(e) => setDepartmentWebsite(e.target.value)}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="departmentReplyToAddress" sm={2}>
              Reply To Address:
            </Label>
            <Col sm={10}>
              <Input
                size="sm"
                type="text"
                name="websiteText"
                disabled={disableFields}
                value={departmentReplyToAddress}
                onChange={(e) => setDepartmentReplyToAddress(e.target.value)}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label for="departmentReplyToName" sm={2}>
              Reply To Name:
            </Label>
            <Col sm={10}>
              <Input
                size="sm"
                type="text"
                name="websiteText"
                disabled={disableFields}
                value={departmentReplyToName}
                onChange={(e) => setDepartmentReplyToName(e.target.value)}
              />
            </Col>
          </FormGroup>
        </Form>
        <div className="flex-center" style={{ marginBottom: 10 }}>
          <Button onClick={handleDepartmentClick} color="success">
            {selectedDepartment ? 'Save Department' : 'Create New Department'}
          </Button>
        </div>

        <SeasonList
          fetchDepartment={fetchDepartment}
          isFetchingDepartment={isFetchingDepartment}
          saveSeason={saveSeason}
          seasons={seasons}
          seasonPopoverOpen={seasonPopoverOpen}
          setSeasons={setSeasons}
          handleSeasonToggle={handleSeasonToggle}
          selectedDepartment={selectedDepartment}
          selectedOrganization={selectedOrganization}
          updateSeasonArray={updateSeasonArray}
          updatingActiveSeason={updatingActiveSeason}
        />
      </div>
    </>
  );
};

export default DepartmentCreationAndEdit;

import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useAuth0 } from '../../../../../auth0/reactAuth0Spa';
import { Button, Input, InputGroup, InputGroupAddon, Label } from 'reactstrap';
import { dateFormatToDBFormat, formatBearerToken } from '../../../../../Common.functions';
import { putData } from '../../../../../Services/dataApi';
import Swal from 'sweetalert2';
import { DatePicker, Localization } from 'react-widgets/cjs';
import { DateLocalizer } from 'react-widgets/IntlLocalizer';

const InterviewDatePopover = ({
  interviewDate,
  onUpdate,
  dContext,
  token,
  setShowPopover,
  CountOfCandidates,
  onAdd,
}) => {
  const [showPicker, setShowPicker] = useState(false);
  const [date, setDate] = useState();
  const [label, setLabel] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);

  const { getTokenSilently, loginWithRedirect } = useAuth0();

  const { rowIndex, MomentDateOfInterview, DateOfInterview, dateTimeInTimezone, StartTime } = interviewDate || {};
  // MomentDateOfInterview works fine when formatting via moment, but new Date() offsets the time. So we need to use the dateTimeInTimezone field to get the correct time.

  const myId = `date_btn_${rowIndex}_actual`;
  useEffect(() => {
    if (interviewDate) {
      setDate(new Date(`${DateOfInterview} ${StartTime}`));
      setLabel(interviewDate.Label);
    }
  }, [interviewDate]);

  const updateInterviewDate = () => {
    getTokenSilently()
      .then((token) => {
        putData(
          'department/interviewDate/putEntry',
          {
            pk_Department: dContext.department.pk_Department,
            pk_InterviewDate: interviewDate.pk_InterviewDate,
            pk_Season: dContext.season.pk_Season,
          },
          {
            entry: {
              Label: label,
              DateOfInterview: dateFormatToDBFormat(date), // moment.tz(date, null).format('YYYY-MM-DD'),
            },
          },
          formatBearerToken(token),
        )
          .then((res) => {
            if (onUpdate) {
              onUpdate();
            }
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  return (
    <div>
      <InputGroup>
        <Label></Label>
        <DatePicker
          id={myId}
          value={date}
          open={showPicker}
          placeholder="Select a date"
          valueFormat={{ month: 'short', year: 'numeric' }}
          valueEditFormat={{ dateStyle: 'short' }}
          valueDisplayFormat={{ dateStyle: 'medium' }}
          onToggle={() => {
            setShowPicker(!showPicker);
          }}
          onClick={(e) => {
            if (e.target.id === `${myId}_input`) {
              setShowPicker(!showPicker);
            }
          }}
          onChange={(e) => {
            if (CountOfCandidates > 0) {
              Swal.fire({
                text:
                  'This Interview date already has Candidates registered to it. Candidates will NOT be automatically notified of this change. Are you sure you want to modify this interview date?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, modify it!',
              }).then((result) => {
                if (result.value) {
                  setDate(e);
                  // setDate(new Date(newDate));
                  // setDate(new Date(`${newDate}`));
                }
              });
            } else {
              setDate(e);
              // setDate(new Date(newDate));
              // setDate(new Date(`${newDate}`));
            }
          }}
        />
      </InputGroup>

      <div style={{ marginTop: 5 }}>
        <Label for="dateLabel">Label</Label>
        <Input
          id={`interviewDate_label_${interviewDate ? interviewDate.pk_InterviewDate : 'header'}`}
          type="text"
          value={label}
          placeholder="Enter Label"
          onChange={(e) => {
            setLabel(e.target.value);
          }}
        />
      </div>
      <div style={{ marginTop: 10 }}>
        <Button
          color="success"
          style={{ width: '100%' }}
          onClick={() => {
            if (onAdd) {
              onAdd({
                selectedDate: dateFormatToDBFormat(date), // moment.tz(date, null).format('YYYY-MM-DD'),
                Label: label,
              });
            } else {
              updateInterviewDate();
            }
          }}
        >
          Save
        </Button>
      </div>
      <div style={{ marginTop: 10 }}>
        <Button
          color="secondary"
          style={{ width: '100%' }}
          onClick={() => {
            setShowPopover(false);
          }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default InterviewDatePopover;

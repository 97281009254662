import React, { useContext, useEffect, useState } from 'react';
import { fetchDataAgnostic } from '../Services/dataApi';
import { DepartmentContext } from '../DepartmentWrapper';
import {
  formatBearerToken,
  fetchInterviewDates,
  evaluatorTypeEnum,
  getDefaultInterviewDate,
} from '../Common.functions';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '../auth0/reactAuth0Spa';
import { clone } from 'Services/schedule';

const EvaluatorRedirect = (props) => {
  const [redirected, setRedirected] = useState(false);
  const { type, callback } = props;
  const { getTokenSilently, loginWithRedirect } = useAuth0();
  const history = useHistory();

  const dContext = useContext(DepartmentContext);
  const { department, season } = dContext;

  useEffect(() => {
    if (dContext.season && dContext.department) {
      redirect();
    }
  }, []);

  useEffect(() => {
    if (!redirected && dContext.season && dContext.department) {
      redirect();
    }
  }, [dContext]);

  const redirect = () => {
    setRedirected(true);
    const { state } = props.reduxLocation;
    if (state && state.path) {
      history.push(state.path);
    } else {
      getTokenSilently()
        .then((token) => {
          fetchDataAgnostic(
            'department/season/defaultInterviewDate',
            {
              pk_Department: department.pk_Department,
              pk_Season: season.pk_Season,
            },
            formatBearerToken(token),
          )
            .then((result) => {
              const defaultDate = getDefaultInterviewDate(result.data, season);
              const { pk_InterviewDate } = defaultDate;
              if (callback) {
                callback();
              }
              if (type === evaluatorTypeEnum.evaluator) {
                history.push(`/user/evaluate/${pk_InterviewDate}`);
              }
            })
            .catch((err) => {});
        })
        .catch((err) => {
          console.log(err);
          if (
            err.message === 'Login required' ||
            err.message === 'Unauthorized' ||
            (err.response && err.response.status && err.response.status.toString() === '401')
          ) {
            loginWithRedirect();
          }
        });
    }
  };

  return <div></div>;
};

export default EvaluatorRedirect;

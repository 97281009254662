import React, { Fragment, useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus, faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import NewAttachmentPopover from './NewAttachmentPopover/NewAttachmentPopover';
import {
  Input,
  InputGroupAddon,
  Button,
  InputGroup,
  Popover,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
  Label,
  FormGroup,
  ButtonGroup,
} from 'reactstrap';
import { postDataAgnostic, putData, fetchDataAgnostic } from '../../../../../../../Services/dataApi';
import { DepartmentContext } from '../../../../../../../DepartmentWrapper';
import { useAuth0, Auth0Context } from '../../../../../../../auth0/reactAuth0Spa';
import style from '../AdminMessageComposer.style';
import { contactSupport, formatBearerToken, PopupWrapper } from '../../../../../../../Common.functions';
import SingleCandidateDragAndDropItem from 'Body/DragAndDropFiles/SingleCandidateDragAndDropItem';
import AttachmentItem from './AttachmentItem/AttachmentItem';

const AttachmentsPopover = ({ uniqueId = '', addAttachment }) => {
  const [documentSearchTerm, setDocumentSearchTerm] = useState('');
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [showNewAttachmentPopover, setShowNewAttachmentPopover] = useState(false);
  const [newAttachment, setNewAttachment] = useState({});

  const [isFetchingAttachments, setIsFetchingAttachments] = useState(false);
  const [attachments, setAttachments] = useState([
    { Name: 'Attachment 1' },
    { Name: 'Attachment 2' },
    { Name: 'Attachment 3' },
    { Name: 'Attachment 4' },
    { Name: 'Attachment 5' },
    { Name: 'Attachment 6' },
    { Name: 'Attachment 7' },
    { Name: 'Attachment 8' },
    { Name: 'Attachment 9' },
    { Name: 'Attachment 10' },
  ]);
  const hasSearchValues = true;

  const dContext = useContext(DepartmentContext);
  const { getTokenSilently, loginWithRedirect } = useAuth0();

  const initiateFileUpload = () => {
    console.log('here');
  };

  const getAttachments = () => {
    setIsFetchingAttachments(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/attachments',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
          },
          formatBearerToken(token),
        )
          .then((result) => {
            setIsFetchingAttachments(false);
            // console.log('getAttachments result.data: ', result.data);
            setAttachments(result.data);
          })
          .catch((err) => {
            setIsFetchingAttachments(false);
          });
      })
      .catch((err) => {
        setIsFetchingAttachments(false);
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  useEffect(() => {
    getAttachments();
  }, []);

  useEffect(() => {
    console.log('showNewAttachmentPopover change! :', showNewAttachmentPopover);
  }, [showNewAttachmentPopover]);

  const renderNewAttachmentPopover = () => {
    const { Name = '', file } = newAttachment;
    const target = `newAttachmentBtn_${uniqueId}`;

    return (
      <NewAttachmentPopover
        target={target}
        showNewAttachmentPopover={showNewAttachmentPopover}
        getAttachments={getAttachments}
        setShowNewAttachmentPopover={(newValue) => {
          console.log('setShowNewAttachmentPopover firing!');
          setShowNewAttachmentPopover(newValue);
        }}
        uniqueId={uniqueId}
      />
    );
  };

  return (
    <div style={style.simpleRow} id={`attachmentsPopover_${uniqueId}`}>
      <div style={style.simpleColumn}>
        <div style={{ ...style.simpleRow, height: 200, overflowY: 'scroll', marginBottom: 5 }}>
          <div style={style.simpleColumn}>
            {isFetchingAttachments ? (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                Loading...
              </div>
            ) : (
              attachments.map((attachment, index) => {
                return (
                  <AttachmentItem
                    attachment={attachment}
                    attachmentIndex={index}
                    addAttachment={addAttachment}
                    getAttachments={getAttachments}
                  />
                );
              })
            )}
          </div>
        </div>
        <div style={{ ...style.simpleRow, justifyContent: 'center' }}>
          Insert Attachments by clicking the green arrow icon.
        </div>
        <div style={{ ...style.simpleRow, justifyContent: 'center', marginBottom: 10 }}>
          <a
            href="https://www.rezrate.com/knowledge-base/adding-attachments-to-your-email-templates/"
            target="_blank"
            onClick={(e) => {
              e.stopPropagation();
              // window.open(
              //   'https://www.rezrate.com/knowledge-base/adding-attachments-to-your-email-templates/',
              //   '_blank',
              // );
            }}
          >
            More information here.
          </a>
        </div>
        <div style={{ ...style.simpleRow, justifyContent: 'center', alignItems: 'center' }}>
          <Button
            size="sm"
            color="success"
            id={`newAttachmentBtn_${uniqueId}`}
            onClick={() => {
              console.log('new attachment button clicked!');
              setShowNewAttachmentPopover(!showNewAttachmentPopover);
            }}
          >
            <FontAwesomeIcon icon={faPlus} style={{ marginRight: 5 }} />
            New Attachment
            {renderNewAttachmentPopover()}
          </Button>
        </div>
      </div>
    </div>
  );
  return <SingleCandidateDragAndDropItem attachedDoc={{}} initiateFileUpload={initiateFileUpload} item={{}} />;
};

export default AttachmentsPopover;

// (∩ ͡° ͜ʖ ͡°)⊃━☆ﾟ. *
import { faCheckSquare, faMinusCircle, faPlusCircle, faSearch, faSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useAlert } from 'react-alert';
import { useHistory } from 'react-router-dom';
import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { useAuth0 } from '../../../../../auth0/reactAuth0Spa.js';
import { formatBearerToken } from '../../../../../Common.functions.js';
import { DepartmentContext } from '../../../../../DepartmentWrapper.js';
import { postDataAgnostic, putData } from '../../../../../Services/dataApi.js';
import EvaluatorDropDown from '../TopBar/EvaluatorDropDown/index.js';

import style from './ScheduleWizard.style.js';

const ScheduleWizard = ({
  isOpen,
  setShowScheduleWizard,
  scheduleData,
  evaluators,
  columnTracker,
  candidates,
  updateAllData,
  forcedContext,
  pk_InterviewDate,
}) => {
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState(); // MOMENT!!

  const [standardDuration, setStandardDuration] = useState();
  const [standardPassingDuration, setStandardPassingDuration] = useState();
  const [selectedEvaluators, setSelectedEvaluators] = useState([]);
  const [timeSlotsToAdd, setTimeSlotsToAdd] = useState(1);
  const [evaluatorSearchString, setEvaluatorSearchString] = useState('');
  const [wizardIsWorking, setWizardIsWorking] = useState(false);

  const { getTokenSilently, loginWithRedirect } = useAuth0();
  const deptContext = useContext(DepartmentContext);
  const dContext = forcedContext ? forcedContext : deptContext;
  const history = useHistory();

  const pathExploded = history.location.pathname.split('/');
  const scheduleID = pathExploded[pathExploded.length - 1];
  const alert = useAlert();
  useEffect(() => {
    if (scheduleData) {
      const { metaData = {} } = scheduleData;
      const { DateOfInterview, StartTime, StandardDurationInMinutes, StandardPassingDurationInMinutes } = metaData;
      const newStartTime = moment.tz(`${DateOfInterview} ${StartTime}`, null).format('HH:mm');
      const candidateCount = candidates ? candidates.length : 0;
      setStandardDuration(StandardDurationInMinutes);
      setStandardPassingDuration(StandardPassingDurationInMinutes);
      setStartTime(newStartTime);
      setTimeSlotsToAdd(candidateCount + 2);
    }
  }, [scheduleData]);

  useEffect(() => {
    const candidateCount = candidates ? Object.values(candidates).length : 0;
    if (selectedEvaluators && selectedEvaluators.length) {
      if (selectedEvaluators.length > candidateCount) {
        setTimeSlotsToAdd(selectedEvaluators.length + 2);
      } else if (candidateCount != timeSlotsToAdd) {
        setTimeSlotsToAdd(candidateCount + 2);
      }
    }
  }, [selectedEvaluators]);

  useEffect(() => {
    const candidateCount = Object.values(candidates).length;
    if (candidates && candidateCount) {
      if (candidateCount > selectedEvaluators.length) {
        setTimeSlotsToAdd(candidateCount + 2);
      } else if (selectedEvaluators.length != timeSlotsToAdd) {
        setTimeSlotsToAdd(selectedEvaluators.length + 2);
      }
    }
  }, [candidates]);

  const calculateEndTime = () => {
    const { metaData = {} } = scheduleData || {};
    const { DateOfInterview } = metaData;

    const newTimeToEnd = moment(`${DateOfInterview} ${startTime}`).add(
      timeSlotsToAdd * (standardDuration + standardPassingDuration),
      'minutes',
    );

    return newTimeToEnd.format('hh:mm A');
  };

  const addStartTime = (data, resolve, reject) => {
    getTokenSilently()
      .then((token) => {
        putData(
          'department/interviewDate/putEntry',
          {
            pk_Department: dContext.department.pk_Department,
            pk_InterviewDate: pk_InterviewDate ? pk_InterviewDate : scheduleID,
            pk_Season: dContext.season.pk_Season,
          },
          {
            entry: {
              StartTime: startTime,
              StandardDurationInMinutes: standardDuration,
              StandardPassingDurationInMinutes: standardPassingDuration,
            },
          },
          formatBearerToken(token),
        )
          .then((res) => {
            if (resolve) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            if (reject) {
              reject();
            }
          });
      })
      .catch((err) => {
        if (reject) {
          reject();
        }
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const addColumns = (data, resolve, reject) => {
    const { evaluatorList, TotalNumberOfSplits, pk_MeetingRoom } = data;

    const payload = {
      payload: selectedEvaluators.map((pk_Evaluator) => {
        return {
          evaluatorPrimaryKeys: [pk_Evaluator],
        };
      }),
    };

    getTokenSilently()
      .then((token) => {
        postDataAgnostic(
          'department/season/schedule/column',
          {
            pk_Department: dContext.department.pk_Department,
            pk_InterviewDate: pk_InterviewDate ? pk_InterviewDate : parseInt(scheduleID),
          },
          payload,
          formatBearerToken(token),
        )
          .then((res) => {
            if (resolve) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            if (reject) {
              reject();
            }
          });
      })
      .catch((err) => {
        if (reject) {
          reject();
        }
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const addTimeSlots = (data, resolve, reject) => {
    getTokenSilently()
      .then((token) => {
        const rowSortOrders = [];

        let toAdd = timeSlotsToAdd;
        let currentSlot = 0;

        while (toAdd > 0) {
          rowSortOrders.push(currentSlot);
          toAdd--;
          currentSlot++;
        }
        postDataAgnostic(
          'department/season/schedule/row',
          {
            pk_Department: dContext.department.pk_Department,
            pk_InterviewDate: pk_InterviewDate ? pk_InterviewDate : Number(scheduleID),
          },
          {
            rowSortOrders: rowSortOrders,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            if (resolve) {
              resolve(res.data);
            }
          })
          .catch((err) => {
            if (reject) {
              reject();
            }
          });
      })
      .catch((err) => {
        if (reject) {
          reject();
        }
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const save = () => {
    const section1 = new Promise((resolve, reject) => {
      addStartTime({}, resolve, reject);
    });

    const section2 = new Promise((resolve, reject) => {
      addColumns({}, resolve, reject);
    });

    const section3 = new Promise((resolve, reject) => {
      addTimeSlots({}, resolve, reject);
    });

    setWizardIsWorking(true);
    Promise.all([section1, section2, section3])
      .then((res) => {
        alert.success('Interview date initialized!');
        updateAllData();
        setWizardIsWorking(false);
        setShowScheduleWizard(false);
      })
      .catch((err) => {
        alert.error('Interview date failed to initialize!');
        setWizardIsWorking(false);
      });
  };

  const renderEvaluatorList = () => {
    return Object.values(evaluators)
      .filter((e) => {
        const eName = `${e.UserLast} ${e.UserFirst}`;
        return !e.Deleted && (!evaluatorSearchString || eName.toLowerCase().indexOf(evaluatorSearchString) >= 0);
      })
      .sort((a, b) => {
        const aString = `${a.UserLast}, ${a.UserFirst}`;
        const bString = `${b.UserLast}, ${b.UserFirst}`;

        if (aString < bString) {
          return -1;
        }
        if (aString > bString) {
          return 1;
        }
        return 0;
      })
      .map((evaluator, index) => {
        if (evaluator.Deleted) {
          return;
        }
        let backgroundColor = index % 2 == 0 ? '#c7cbd1' : null;
        const isSelected = selectedEvaluators.indexOf(evaluator.pk_User) >= 0;
        if (isSelected) {
          backgroundColor = '#81cf7a';
        }
        return (
          <div
            key={evaluator.pk_User}
            disabled={wizardIsWorking}
            style={{ ...style.spacedBetweenRow, ...style.userItem, backgroundColor: backgroundColor }}
            onClick={() => {
              const newSelectedEvaluators = [...selectedEvaluators];
              if (isSelected) {
                const indexToDelete = selectedEvaluators.indexOf(evaluator.pk_User);

                newSelectedEvaluators.splice(indexToDelete, 1);
              } else {
                newSelectedEvaluators.push(evaluator.pk_User);
              }
              setSelectedEvaluators(newSelectedEvaluators);
            }}
          >
            <FontAwesomeIcon icon={isSelected ? faCheckSquare : faSquare} />
            <div style={style.label}>
              {evaluator.UserLast}, {evaluator.UserFirst}
            </div>
          </div>
        );
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      centered={true}
      style={{ maxWidth: '80%' }}
      onClosed={() => {
        setSelectedEvaluators([]);
      }}
    >
      <ModalHeader
        toggle={() => {
          setShowScheduleWizard(false);
        }}
      >
        Schedule Wizard
      </ModalHeader>
      <ModalBody>
        <div style={style.simpleRow}>
          <div style={{ ...style.simpleColumn, width: '33.33%', padding: 10, borderRight: '1px solid gray' }}>
            <FormGroup>
              <Label style={style.inputLabel} for="startTime">
                Start Time
              </Label>
              <Input
                type="time"
                disabled={wizardIsWorking}
                name="startTime"
                id="startTime"
                value={startTime}
                onChange={(e) => {
                  setStartTime(e.target.value);
                }}
                placeholder="Enter Time. . ."
              />
            </FormGroup>
            <FormGroup>
              <Label style={style.inputLabel} for="interviewDuration">
                Interview Duration
              </Label>
              <InputGroup>
                <Input
                  type="number"
                  disabled={wizardIsWorking}
                  style={style.inputFields}
                  name="interviewDuration"
                  id="interviewDuration"
                  value={standardDuration}
                  placeholder="Enter Duration. . ."
                  onChange={(e) => {
                    const newValue = parseInt(e.target.value) >= 0 ? parseInt(e.target.value) : 0;
                    setStandardDuration(newValue);
                  }}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>minutes</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
            <FormGroup>
              <Label style={style.inputLabel} for="passingPeriod">
                Passing Period
              </Label>
              <InputGroup>
                <Input
                  disabled={wizardIsWorking}
                  type="number"
                  style={style.inputFields}
                  name="passingPeriod"
                  id="passingPeriod"
                  value={standardPassingDuration}
                  placeholder="Enter Passing Period. . ."
                  onChange={(e) => {
                    const newValue = parseInt(e.target.value) >= 0 ? parseInt(e.target.value) : 0;
                    setStandardPassingDuration(newValue);
                  }}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>minutes</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
            <div style={style.inputLabel}>
              <b> Block Duration: {`${standardDuration + standardPassingDuration} minutes`}</b>
            </div>
          </div>
          <div style={{ ...style.simpleColumn, width: '33.33%', padding: 10, borderRight: '1px solid gray' }}>
            <div style={style.simpleRow}>
              <div style={{ ...style.simpleColumn, flex: 1 }}>
                <InputGroup>
                  <Input
                    disabled={wizardIsWorking}
                    type="text"
                    placeholder="Search for Evaluator"
                    onChange={(e) => {
                      setEvaluatorSearchString(e.target.value);
                    }}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <FontAwesomeIcon icon={faSearch} />
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </div>
            </div>
            <div style={{ marginTop: 10 }}>
              <div style={{ height: 220, overflowY: 'scroll', overflowY: 'scroll' }}>{renderEvaluatorList()}</div>
            </div>
          </div>
          <div style={{ ...style.simpleColumn, width: '33.33%', padding: 10 }}>
            <div
              style={{ ...style.largeBoldLabel, marginTop: 20 }}
            >{`${selectedEvaluators.length} Interview Rooms`}</div>
            <div style={style.largeBoldLabel}>and</div>
            <div style={style.largeBoldLabel}>{`${Object.values(candidates).length} Candidates`}</div>
            <div style={{ marginTop: 10 }}>
              {' '}
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <Button
                    disabled={wizardIsWorking}
                    onClick={() => {
                      const newCount = timeSlotsToAdd - 1 > 0 ? timeSlotsToAdd - 1 : 1;

                      setTimeSlotsToAdd(newCount);
                      setTimeSlotsToAdd(newCount);
                    }}
                  >
                    <FontAwesomeIcon icon={faMinusCircle} />
                  </Button>
                </InputGroupAddon>
                <Input type="number" value={timeSlotsToAdd} style={style.popOverHeader} disabled />
                <InputGroupAddon addonType="append">
                  <Button
                    disabled={wizardIsWorking}
                    onClick={() => {
                      const newCount = timeSlotsToAdd + 1 > 0 ? timeSlotsToAdd + 1 : 1;

                      setTimeSlotsToAdd(newCount);
                      setTimeSlotsToAdd(newCount);
                    }}
                  >
                    <FontAwesomeIcon icon={faPlusCircle} />
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </div>
            <div style={{ ...style.largeBoldLabel, marginTop: 20 }}>End Time: {calculateEndTime()}</div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%' }}>
          <Button
            color="success"
            style={{ width: 120, marginRight: 50 }}
            disabled={wizardIsWorking || selectedEvaluators.length <= 0}
            onClick={() => {
              save();
            }}
          >
            Save
          </Button>
          <Button
            disabled={wizardIsWorking}
            color="danger"
            style={{ width: 120 }}
            onClick={() => {
              setShowScheduleWizard(false);
            }}
          >
            Cancel
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ScheduleWizard;

import React, { useEffect, useState } from 'react';
import style from './ApplicationForm.style';
import { Button, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faChevronCircleLeft,
  faChevronCircleRight,
  faCircleDot,
  faExclamationCircle,
  faPencil,
} from '@fortawesome/free-solid-svg-icons';
import RezRateImg from '../../../../../../../assets/images/header/rezRate.gif';
import VerticalStepper from 'Body/RezRATE/Common/VertifcalStepper/VerticalStepper';
import { clone } from 'Services/schedule';
import ApplicationField from '../../../ApplicationField/ApplicationField';
import { useKindeAuth } from '@kinde-oss/kinde-auth-react';
import { postDataAgnostic, putData } from 'Services/dataApi';
import { formatBearerToken } from 'Common.functions';
import { useAlert } from 'react-alert';
import Swal from 'sweetalert2';
import { result } from 'lodash';

const statusIcons = {
  'Not Started': faCircleDot,
  'In Progress': faCircleDot,
  Done: faCheckCircle,
};

const statusColors = {
  'Not Started': '#a6a6a6',
  'In Progress': '#FFA500',
  Done: 'green',
};

const ApplicationForm = (props) => {
  const { applicationCode, applicationDetails, selectedOpening, applicantDetails, isReadOnly, onApply, isEdit } = props;
  const { pk_Applicant } = applicantDetails || {};
  const [currentSection, setCurrentSection] = useState();
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);

  const [isSavingForm, setIsSavingForm] = useState(false);

  const [sections, setSections] = useState([
    {
      label: 'Personal Information',
      fields: [
        { label: 'First Name', dataKey: 'FirstName', required: true },
        { label: 'Middle Name', dataKey: 'MiddleName', required: true },
        { label: 'Last Name', dataKey: 'LastName', required: true },
        { label: 'Preferred Name', dataKey: 'PreferredName' },
        { label: 'Gender', dataKey: 'Gender' },
        { label: 'Date of Birth', dataKey: 'DateOfBirth' },
        { label: 'Medical License Number', dataKey: 'MedicalLicenseNumber' },
        { label: 'Medical License State', dataKey: 'MedicalLicenseState' },
        { label: 'AAMCID', dataKey: 'AAMCID' },
        { label: 'SFMatch ID', dataKey: 'SFMatchID' },
        { label: 'NRMP ID', dataKey: 'NRMPID' },
      ],
    },
    {
      label: 'Contact Information',
      fields: [
        { label: 'Email*', dataKey: 'Email' },
        { label: 'Phone', dataKey: 'Phone' },
        { label: 'Current Cell Phone Number', dataKey: 'CurrentCellPhoneNumber' },
        { label: 'Current Street Address', dataKey: 'CurrentStreetAddress' },
        { label: 'Current City', dataKey: 'CurrentCity' },
        { label: 'Current State', dataKey: 'CurrentState' },
        { label: 'Current Zip', dataKey: 'CurrentZip' },
        { label: 'Current Country', dataKey: 'CurrentCountry' },
      ],
    },
    {
      label: 'Other Demographic Information',
      fields: [
        { label: 'Country of Birth', dataKey: 'CountryOfBirth' },
        { label: 'City of Birth', dataKey: 'CityOfBirth' },
        { label: 'State of Birth', dataKey: 'StateOfBirth' },
        { label: 'Country of Citizenship', dataKey: 'CountryOfCitizenship' },
        { label: 'Is Visa Required', dataKey: 'IsVisaRequired' },
        { label: 'Will Be Paid By Military', dataKey: 'WillBePaidByMilitary' },
        { label: 'Couples Match', dataKey: 'CouplesMatch' },
        { label: 'Couples Partner Specialty', dataKey: 'CouplesPartnerSpecialty' },
        { label: 'Felony Conviction (Y/N)', dataKey: 'FelonyConviction', type: 'switch' },
        { label: 'Felony Conviction Reason (Text)', dataKey: 'FelonyConvictionReason' },
      ],
    },
    {
      label: 'USMLE',
      fields: [
        { label: 'USMLE ID', dataKey: 'USMLEID' },
        { label: 'USMLE Step 1 Score', dataKey: 'USMLEStep1Score' },
        { label: 'USMLE Step 1 Status', dataKey: 'USMLEStep1Status' },
        { label: 'USMLE Step 2 CS Score', dataKey: 'USMLEStep2CSScore' },
        { label: 'USMLE Step 2 CS Date', dataKey: 'USMLEStep2CSDate' },
        { label: 'USMLE Step 3 Score', dataKey: 'USMLEStep3Score' },
        { label: 'USMLE Step 3 Date', dataKey: 'USMLEStep3Date' },
        { label: 'USMLE Completion Date', dataKey: 'USMLECompletionDate' },
      ],
    },
    {
      label: 'ABR Core Exam',
      fields: [
        { label: 'Score Pass Fail ABR Core Exam', dataKey: 'ScorePassFailABRCoreExam' },
        { label: 'Expected Date Of ABR Core Exam Results', dataKey: 'ExpectedDateOfABRCoreExamResults' },
      ],
    },
    {
      label: 'COMLEX USA',
      fields: [
        { label: 'COMELEX USA Level 1 Date', dataKey: 'COMELEXUSALevel1Date' },
        { label: 'COMELEX USA Level 1 Score', dataKey: 'COMELEXUSALevel1Score' },
        { label: 'COMELEX USA Level 2 CE Date', dataKey: 'COMELEXUSALevel2CEDate' },
        { label: 'COMELEX USA Level 2 CE Score', dataKey: 'COMELEXUSALevel2CEScore' },
        { label: 'COMELEX USA Level 2 PE Date', dataKey: 'COMELEXUSALevel2PEDate' },
        { label: 'COMELEX USA Level 2 PE Score', dataKey: 'COMELEXUSALevel2PEScore' },
        { label: 'COMELEX USA Level 3 Date', dataKey: 'COMELEXUSALevel3Date' },
        { label: 'COMELEX USA Level 3 Score', dataKey: 'COMELEXUSALevel3Score' },
      ],
    },
    {
      label: 'Awards',
      fields: [
        { label: 'Alpha Omega Alpha (Y/N)', dataKey: 'AlphaOmegaAlpha' },
        { label: 'Sigma Sigma Phi (Y/N)', dataKey: 'SigmaSigmaPhi' },
        { label: 'Gold Humanism Honor Society (Y/N)', dataKey: 'GoldHumanismHonorSociety' },
        { label: 'Other (Text Field + Ability to add additional line items)', dataKey: 'OtherAwards' },
      ],
    },
    {
      label: 'Education',
      fields: [
        { label: 'Education College Attended', dataKey: 'EducationCollegeAttended' },
        { label: 'Education College Degree', dataKey: 'EducationCollegeDegree' },
        { label: 'Education College Date Of Graduation', dataKey: 'EducationCollegeDateOfGraduation' },
        { label: 'Education Medical School Attended', dataKey: 'EducationMedicalSchoolAttended' },
        { label: 'Education Medical School Degree', dataKey: 'EducationMedicalSchoolDegree' },
        { label: 'Education Medical School Date Of Graduation', dataKey: 'EducationMedicalSchoolDateOfGraduation' },
        { label: 'Education Place Of Internship', dataKey: 'EducationPlaceOfInternship' },
        { label: 'Education Internship Specialty', dataKey: 'EducationInternshipSpecialty' },
        { label: 'Education Internship Start Date', dataKey: 'EducationInternshipStartDate' },
        { label: 'Education Internship End Date', dataKey: 'EducationInternshipEndDate' },
        { label: 'Education Place Of Residency', dataKey: 'EducationPlaceOfResidency' },
        { label: 'Education Residency Specialty', dataKey: 'EducationResidencySpecialty' },
        { label: 'Education Residency Date Start', dataKey: 'EducationResidencyDateStart' },
        { label: 'Education Residency Date End', dataKey: 'EducationResidencyDateEnd' },
        { label: 'Education Place Of Fellowship', dataKey: 'EducationPlaceOfFellowship' },
        { label: 'Education Fellowship Specialty', dataKey: 'EducationFellowshipSpecialty' },
        { label: 'Education Fellowship Date Start', dataKey: 'EducationFellowshipDateStart' },
        { label: 'Education Fellowship Date End', dataKey: 'EducationFellowshipDateEnd' },
        { label: 'Additional Graduate Degree', dataKey: 'AdditionalGraduateDegree' },
        { label: 'Additional Graduate Degree School', dataKey: 'AdditionalGraduateDegreeSchool' },
        { label: 'Additional Graduate Degree Date Start', dataKey: 'AdditionalGraduateDegreeDateStart' },
        { label: 'Additional Graduate Degree Date End', dataKey: 'AdditionalGraduateDegreeDateEnd' },
        { label: 'Medical Training Inturupted (Y/N)', dataKey: 'MedicalTrainingInturupted' },
        { label: 'Explanation Of Medical Training Inturupted', dataKey: 'ExplanationOfMedicalTrainingInturupted' },
      ],
    },
    {
      label: 'Publications',
      fields: [
        {
          label: 'Count Of Non-Peer Reviewed Online Publications',
          dataKey: 'CountOfNonPeerReviewedOnlinePublications',
        },
        {
          label: 'Links To Non-Peer Reviewed Online Publications',
          dataKey: 'LinksToNonPeerReviewedOnlinePublications',
        },
        { label: 'Count Of Oral Presentations', dataKey: 'CountOfOralPresentations' },
        { label: 'Count Of Peer Reviewed Book Chapter', dataKey: 'CountOfPeerReviewedBookChapter' },
        {
          label: 'Count Of Peer Reviewed Journal Articles And/or Abstracts',
          dataKey: 'CountOfPeerReviewedJournalArticlesAndOrAbstracts',
        },
        {
          label: 'Count Of Peer Reviewed Journal Articles And/or Abstracts Other Than Published',
          dataKey: 'CountOfPeerReviewedJournalArticlesAndOrAbstractsOtherThanPublished',
        },
        { label: 'Count Of Peer Reviewed Online Publication', dataKey: 'CountOfPeerReviewedOnlinePublication' },
        { label: 'Links To Peer Reviewed Online Publication', dataKey: 'LinksToPeerReviewedOnlinePublication' },
        { label: 'Count Of Poster Presentation', dataKey: 'CountOfPosterPresentation' },
        { label: 'Count Of Scientific Monograph', dataKey: 'CountOfScientificMonograph' },
        { label: 'Count Of Other Articles', dataKey: 'CountOfOtherArticles' },
      ],
    },
    {
      label: 'Hobbies',
      fields: [
        { label: 'Hobbies (Text)', dataKey: 'Hobbies' },
        { label: 'Other Interests (Text)', dataKey: 'OtherInterests' },
        { label: 'Language Fluency', dataKey: 'LanguageFluency' },
      ],
    },
  ]);

  const { isAuthenticated, login, getToken } = useKindeAuth();
  const alert = useAlert();

  useEffect(() => {
    // Your code here
    processSectionStatuses();
  }, []);

  useEffect(() => {
    // console.log('currentSection change: ', currentSection);

    if (currentSection) {
      setCurrentSectionIndex(sections.findIndex((section) => section.label === currentSection.label));
      // console.log('sections: ', sections);
      // console.log('currentSection: ', currentSection);
      processSectionStatuses({ forcedSections: sections });
    }
  }, [currentSection]);

  useEffect(() => {
    // console.log('sections change: ', sections);
  }, [sections]);

  useEffect(() => {
    if (
      (applicationCode && !applicationDetails) ||
      (applicationDetails && Object.keys(applicationDetails).length <= 0)
    ) {
      // verifyCode(applicationCode);
    }
  }, [applicationCode]);

  useEffect(() => {
    initializeSections(applicationDetails ? applicationDetails : applicantDetails);
  }, [applicantDetails, applicationDetails]);

  const verifyCode = async (codeToVerify) => {
    const token = await getToken();

    //  For testing, creating app code for Matt's dept.
    postDataAgnostic('applicant/code/verify', null, { code: codeToVerify }, formatBearerToken(token))
      .then((result) => {
        console.log('verifyCode result: ', result);
        if (result.data && result.data.message && result.data.message === 'Invalid Code') {
          // Invalid Code message. It always throws though, so we never reach here
        } else {
          // history.push(`/applicant/applicant_profile/new_application?applicationCode=${code}`);
        }
      })
      .catch((err) => {
        // setIsValidatingCode(false);
        console.log('err: ', err);
      });
  };

  const handleChange = (data) => {
    const { e, field, fieldIndex } = data;
    const { type } = field;
    const { target } = e;

    const newSections = sections;
    const currentSectionIndex = newSections.findIndex((section) => section.label === currentSection.label);
    const newSection = newSections[currentSectionIndex];
    const newField = clone(newSection.fields[fieldIndex]);

    switch (type) {
      case 'switch':
        newField.value = target.checked;
        break;
      default:
        newField.value = target.value;
        break;
    }
    newSection.fields[fieldIndex] = newField;
    newSections[currentSectionIndex] = newSection;

    processSectionStatuses({
      forcedSections: newSections,
      callback: (newData) => {
        const { newSections } = newData;
        setSections(newSections);
        setCurrentSection(newSections[currentSectionIndex]);
      },
    });
  };

  const processSectionStatuses = (options) => {
    const { callback, forcedSections } = options || {};
    const newSections = clone(forcedSections) || clone(sections);

    newSections.forEach((section, i) => {
      const { status, label, fields = [] } = section;
      const isCurrentSection = currentSection ? currentSection.label === label : false;

      let hasNonEmptyField = false; // for In Progress
      let allRequiredHasContent = true; // for Done(?)
      let allNonRequiredFieldHasContent = true;
      let newStatus = status;

      fields.forEach((field) => {
        const { value, required, label } = field;

        if (field.value) {
          hasNonEmptyField = true;
        } else {
          if (required) {
            allRequiredHasContent = false;
          } else {
            allNonRequiredFieldHasContent = false;
          }
        }
      });

      if (allRequiredHasContent && allNonRequiredFieldHasContent) {
        newStatus = 'Done';
      } else if (hasNonEmptyField) {
        newStatus = 'In Progress';
      }

      section.icon = (
        <FontAwesomeIcon
          icon={statusIcons[newStatus]}
          style={{ color: statusColors[newStatus], fontSize: isCurrentSection ? 39 : 24 }}
        />
      );

      if (!newStatus) {
        section.status = 'Not Started';
      } else {
        section.status = newStatus;
      }
    });

    if (callback) {
      callback({
        newCurrentSection: newSections[0],
        newSections,
      });
    } else {
      if (!currentSection) {
        setCurrentSection(newSections[0]);
      }

      setSections(newSections);
    }
  };

  const initializeSections = (data) => {
    const newSections = clone(sections);
    newSections.forEach((section) => {
      const { fields = [] } = section;
      let hasValue = false;
      let hasAllValues = true;
      fields.forEach((field) => {
        const { dataKey } = field;
        const newValue = data[dataKey] || '';
        field.value = newValue;

        if (newValue) {
          hasValue = true;
        } else {
          hasAllValues = false;
        }
      });
      section.status = hasAllValues ? 'Done' : hasValue ? 'In Progress' : 'Not Started';
    });

    processSectionStatuses({ forcedSections: newSections }, (newData) => {
      const { newCurrentSection, newSections } = newData;
      setSections(newSections);
      setCurrentSection(() => {
        return newSections[0];
      });
    });
  };

  const saveForm = async (submit = false) => {
    const dataToSubmit = {};
    sections.forEach((section) => {
      const { label, fields } = section;
      fields.forEach((field) => {
        const { dataKey, value } = field;
        dataToSubmit[dataKey] = value || '';
      });
    });

    if (!applicationDetails) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'No openings for Application Code found.',
      });
      return;
    }
    const { fk_Department, fk_Season, fk_ApplicationOpening, fk_ApplicationRecord } = applicationDetails;
    const token = await getToken();
    setIsSavingForm(true);
    let promiseToCall;

    if (isEdit) {
      promiseToCall = putData(
        'applicant/application/submission',
        { pk_ApplicationRecord: fk_ApplicationRecord },
        { ...dataToSubmit },
        formatBearerToken(token),
      );
    } else {
      postDataAgnostic(
        'applicant/application',
        {
          pk_Applicant: applicantDetails.pk_Applicant,
          pk_Department: fk_Department,
          pk_Season: fk_Season,
          pk_ApplicationOpening: fk_ApplicationOpening,
        },
        { ...dataToSubmit },
        formatBearerToken(token),
      );
    }

    promiseToCall
      .then((result1) => {
        alert.success('Form saved successfully');
        setIsSavingForm(false);
        if (submit) {
          putData(
            'applicant/application',
            { pk_ApplicationRecord: fk_ApplicationRecord },
            {
              IsSubmitted: true,
            },
            formatBearerToken(token),
          ).then((result2) => {
            alert.success('Form submitted successfully');
            if (onApply) {
              onApply();
            }
          });
        } else if (onApply) {
          onApply();
        }
      })
      .catch((err) => {
        setIsSavingForm(false);
        alert.error('Form failed to save');
        console.log(' saveForm err: ', err);
      });

    // applicant/application
  };

  const renderSideNavBar = () => {
    return (
      <VerticalStepper sections={sections} setCurrentSection={setCurrentSection} currentSection={currentSection} />
    );
    return (
      <div style={{ ...style.simpleColumn, width: 300 }}>
        {sections.map((section, i) => {
          const { label, status } = section;
          const isCurrentSection = currentSection.label === label;
          return (
            <>
              {i > 0 ? (
                <div
                  style={{
                    ...style.simpleRow,
                    marginLeft: 44,
                    borderLeft: '3px solid #007bff',
                    height: 15,
                    width: '100%',
                  }}
                ></div>
              ) : (
                ''
              )}
              <div
                style={{ ...style.simpleRow, cursor: 'pointer', width: 300 }}
                onClick={() => {
                  setCurrentSection(section);
                }}
              >
                <div
                  style={{
                    ...style.simpleColumn,
                    width: isCurrentSection ? 58 : 32,
                    height: isCurrentSection ? 48 : 28,
                    borderRadius: 50,
                    border: isCurrentSection ? '3px solid #007bff' : '2px solid gray',
                    marginTop: 2,
                    marginBottom: 2,
                    marginLeft: isCurrentSection ? 0 : 10,
                  }}
                >
                  <FontAwesomeIcon
                    icon={statusIcons[status]}
                    style={{ color: statusColors[status], fontSize: isCurrentSection ? 39 : 24 }}
                  />
                </div>
                <div
                  style={{
                    ...style.simpleColumn,
                    marginLeft: 10,
                    alignItems: 'flex-start',
                    fontSize: isCurrentSection ? 18 : 15,
                    fontWeight: isCurrentSection ? 'bold' : null,
                    color: isCurrentSection ? '#007bff' : 'gray',
                  }}
                >
                  {label}
                </div>
              </div>
            </>
          );
        })}
      </div>
    );
  };

  const renderFormForSection = () => {
    if (!currentSection) {
      return;
    }
    const sectionToDisplay = sections[currentSectionIndex];
    const { status } = sectionToDisplay;
    const color = statusColors[status];
    const icon = statusIcons[status];
    const { Department = {} } = applicationDetails || {};
    const { DepartmentName, DepartmentLogo } = Department;

    return (
      <div style={{ ...style.simpleColumn, width: '100%', padding: '' }}>
        <div
          style={{
            ...style.simpleRow,
            width: '100%',
            marginLeft: 20,
            marginBottom: 10,
            justifyContent: 'space-between',
          }}
        >
          <div style={{ ...style.simpleColumn, width: 'calc(50px + (100% - 320px))' }}>
            <div style={{ ...style.simpleRow, width: '100%' }}>
              <div style={{ ...style.simpleColumn, width: 50 }}>
                <img src={DepartmentLogo || RezRateImg} width={40} />
              </div>
              <div style={style.simpleColumn}>
                {/* <div
                  style={{
                    ...style.simpleRow,
                    width: '100%',
                    justifyContent: 'left',
                    paddingLeft: 5,
                    fontWeight: 'bold',
                  }}
                >
                  Organization Name {applicationCode ? `(${applicationCode})` : ''}
                </div> */}
                <div style={{ ...style.simpleRow, width: '100%', justifyContent: 'left', paddingLeft: 5 }}>
                  {DepartmentName}
                </div>
              </div>
            </div>
          </div>
          <div style={{ ...style.simpleColumn, width: 180, color: color, fontWeight: 'bold', fontSize: 18 }}>
            <div style={{ ...style.simpleRow, width: '100%' }}>
              <FontAwesomeIcon icon={icon} style={{ color: color, marginRight: 10, fontSize: 15 }} />
              {status}
            </div>
          </div>
        </div>

        <div
          style={{
            ...style.simpleRow,
            height: 'calc(100vh - 300px)',
            overflowY: 'auto',
            width: '100%',
            alignItems: 'flex-start',
            marginTop: 10,
          }}
        >
          <div style={style.simpleColumn}>
            {sectionToDisplay.fields.map((field, i) => {
              const { dataKey, type, label, required, value } = field;
              const fieldId = `${sectionToDisplay.label}-${dataKey}`;
              return (
                <ApplicationField
                  key={fieldId}
                  fieldId={fieldId}
                  field={field}
                  type={type}
                  label={label}
                  required={required}
                  value={value}
                  disabled={isReadOnly || applicationDetails.IsSubmitted}
                  onChange={(e) => {
                    handleChange({
                      e: e,
                      field: field,
                      fieldIndex: i,
                    });
                  }}
                />
              );
            })}
          </div>
        </div>

        <div style={{ ...style.simpleRow, width: '100%', justifyContent: 'space-between', marginTop: 10 }}>
          <Button
            disabled={currentSectionIndex <= 0 || isSavingForm}
            size="sm"
            color="primary"
            style={{ width: 150 }}
            onClick={() => {
              setCurrentSection(sections[currentSectionIndex - 1]);
            }}
          >
            <div style={style.simpleRow}>
              <FontAwesomeIcon icon={faChevronCircleLeft} style={{ marginRight: 10 }} />
              Previous{' '}
            </div>
          </Button>
          {isReadOnly || applicationDetails.IsSubmitted ? null : (
            <>
              <Button
                size="sm"
                color="primary"
                disabled={isSavingForm}
                style={{ width: 150, fontWeight: 'normal' }}
                onClick={() => {
                  saveForm();
                }}
              >
                Save
              </Button>
              <Button
                size="sm"
                color="success"
                disabled={isSavingForm}
                style={{ width: 150, fontWeight: 'normal' }}
                onClick={() => {
                  Swal.fire({
                    title: 'Save and Submit?',
                    text: 'Applications that are submitted cannot be edited.',
                    icon: 'warning',
                    showCancelButton: true,
                  }).then((result) => {
                    if (result.isConfirmed) {
                      saveForm(true);
                    }
                  });
                }}
              >
                Submit
              </Button>
            </>
          )}
          <Button
            disabled={currentSectionIndex >= sections.length - 1 || isSavingForm}
            size="sm"
            color="primary"
            style={{ width: 150 }}
            onClick={() => {
              setCurrentSection(sections[currentSectionIndex + 1]);
            }}
          >
            <div style={style.simpleRow}>
              Next <FontAwesomeIcon icon={faChevronCircleRight} style={{ marginLeft: 10 }} />
            </div>
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div style={{ ...style.simpleRow, width: '100%', alignItems: 'flex-start' }}>
      {renderSideNavBar()} {renderFormForSection()}
    </div>
  );
};

export default ApplicationForm;

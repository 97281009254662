import {
  faMagnifyingGlass,
  faSquare,
  faSquareCheck,
  faTimes,
  faTrashAlt,
  faUserPlus,
} from '@fortawesome/free-solid-svg-icons';
import RezRateTable from 'Body/RezRATE/Common/RezRateTable/RezRateTable';
import React, { useContext, useEffect, useState } from 'react';

import { Button, Input, InputGroup, InputGroupAddon, Modal, ModalBody, ModalHeader } from 'reactstrap';
import style from './PrescreenAssignments.style';
import { deleteData, fetchDataAgnostic, postDataAgnostic } from 'Services/dataApi';
import { DepartmentContext } from 'DepartmentWrapper';
import { formatBearerToken } from 'Common.functions';
import { useAuth0 } from 'auth0/reactAuth0Spa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAlert } from 'react-alert';
import Swal from 'sweetalert2';

let typingTimeout = null;
const PrescreenAssignments = (props) => {
  const {
    pk_User,
    pk_Candidate,
    candidates,
    selectedUser = {},
    users,
    selectedCandidate = {},
    tableHeight = `calc(100vh - 540px)`,
  } = props;

  const [isFetchingPrescreenData, setIsFetchingPrescreenData] = useState(false);
  const [prescreenData, setPrescreenData] = useState([]);

  const [candidatesToDisplay, setCandidatesToDisplay] = useState([]);
  const [usersToDisplay, setUsersToDisplay] = useState([]);

  const [selectAllCandidatesForAssignment, setSelectAllCandidatesForAssignment] = useState(false);
  const [selectAllUsersForAssignment, setSelectAllUsersForAssignment] = useState(false);

  const [isAssigningCandidates, setIsAssigningCandidates] = useState(false);
  const [isAssigningUsers, setIsAssigningUsers] = useState(false);

  const [candidateSearchString, setCandidateSearchString] = useState('');
  const [userSearchString, setUserSearchString] = useState('');

  const [showCandidatesForAssignment, setShowCandidatesForAssignment] = useState(false);
  const [showUsersForAssignment, setShowUsersForAssignment] = useState(false);

  const [candidatesBeingDeleted, setCandidatesBeingDeleted] = useState([]);
  const [usersBeingDeleted, setUsersBeingDeleted] = useState([]);

  const { getTokenSilently, loginWithRedirect } = useAuth0();
  const dContext = useContext(DepartmentContext);
  const alert = useAlert();

  const columnData = [
    {
      label: 'Last Name',
      dataKey: 'LastName',
      width: 200,
    },
    {
      label: 'First Name',
      dataKey: 'FirstName',
      width: 200,
    },
    {
      label: 'Status',
      dataKey: 'Status',
      width: 200,
    },
    {
      label: 'Action',
      dataKey: 'Action',
      width: 200,
      cellFormatter: (cellData, rowData) => {
        const { Status, pk_Candidate } = rowData;
        return (
          <div style={style.simpleRow}>
            {Status === 'Pending' ? (
              <Button
                disabled={candidatesBeingDeleted.includes(pk_Candidate)}
                size="sm"
                color="danger"
                onClick={() => {
                  Swal.fire({
                    text: 'Are you sure you want to unassign this candidate?',
                    icon: 'warning',
                    showCancelButton: true,
                    cancelButtonColor: 'gray',
                    confirmButtonColor: 'red',
                  }).then((result) => {
                    if (result.isConfirmed) {
                      removeAssignment({ pk_Candidate: rowData.pk_Candidate, pk_User: pk_User, type: 'evaluator' });
                    }
                  });
                }}
              >
                Unassign
              </Button>
            ) : null}
          </div>
        );
      },
    },
  ];

  // for when pk_Candidate != null, meaning for candidates so we show table of evaluator assignments
  const evaluatorsColumnData = [
    {
      label: 'Last Name',
      dataKey: 'UserLast',
      width: 200,
    },
    {
      label: 'First Name',
      dataKey: 'UserFirst',
      width: 200,
    },
    {
      label: 'Email',
      dataKey: 'UserEmail',
      width: 200,
    },
    {
      label: 'Status',
      dataKey: 'Status',
      width: 150,
      cellFormatter: (cellData, rowData) => {
        return <div style={style.simpleRow}>{cellData || '----'}</div>;
      },
    },
    {
      label: 'Action',
      dataKey: 'Status',
      width: 200,
      cellFormatter: (cellData, rowData) => {
        return (
          <div style={style.simpleRow}>
            <Button
              disabled={usersBeingDeleted.includes(rowData.pk_User)}
              color="danger"
              onClick={() => {
                Swal.fire({
                  text: 'Are you sure you want to unassign this screener?',
                  icon: 'warning',
                  showCancelButton: true,
                  cancelButtonColor: 'gray',
                  confirmButtonColor: 'red',
                }).then((result) => {
                  if (result.isConfirmed) {
                    removeAssignment({ pk_User: rowData.pk_User, pk_Candidate: pk_Candidate, type: 'candidate' });
                  }
                });
              }}
            >
              Remove
            </Button>
          </div>
        );
      },
    },
  ];

  const userName = `${selectedUser.UserLast || ''}${selectedUser.UserFirst ? `, ${selectedUser.UserFirst}` : ''}`;

  const candidateName = `${selectedCandidate.LastName || ''}${
    selectedCandidate.FirstName ? `, ${selectedCandidate.FirstName}` : ''
  }`;

  const candidatesForAssignmentColumns = [
    {
      label: 'select',
      width: 120,
      headerFormatter: () => {
        return (
          <div
            style={style.simpleRow}
            onClick={() => {
              setSelectAllCandidatesForAssignment(!selectAllCandidatesForAssignment);
            }}
          >
            Select{' '}
            <div>
              <FontAwesomeIcon
                icon={selectAllCandidatesForAssignment ? faSquareCheck : faSquare}
                style={{ cursor: 'pointer', marginLeft: 5 }}
              />
            </div>
          </div>
        );
      },
      cellFormatter: (cellData, rowData) => {
        const { isSelected, pk_Candidate, isAssignedAlready } = rowData;

        return (
          <div
            style={style.simpleRow}
            onClick={() => {
              if (!isAssignedAlready) {
                toggleSelectCandidateForAssignment(pk_Candidate);
              }
            }}
          >
            <FontAwesomeIcon
              icon={isSelected || isAssignedAlready ? faSquareCheck : faSquare}
              style={{ cursor: !isAssignedAlready ? 'pointer' : 'not-allowed' }}
            />
          </div>
        );
      },
    },
    {
      label: 'Last Name',
      dataKey: 'LastName',
      width: 200,
    },
    {
      label: 'First Name',
      dataKey: 'FirstName',
      width: 200,
    },
    {
      label: 'AAMC ID',
      dataKey: 'AAMCID',
      width: 100,
    },
    {
      label: 'SFMatch ID',
      dataKey: 'SFMatchID',
      width: 100,
    },
    {
      label: 'Email',
      dataKey: 'Email',
      width: 300,
    },
  ];

  const usersForAssignemntColumns = [
    {
      label: 'select',
      width: 120,
      headerFormatter: () => {
        return (
          <div
            style={style.simpleRow}
            onClick={() => {
              setSelectAllUsersForAssignment(!selectAllUsersForAssignment);
            }}
          >
            Select{' '}
            <div>
              <FontAwesomeIcon
                icon={selectAllUsersForAssignment ? faSquareCheck : faSquare}
                style={{ cursor: 'pointer', marginLeft: 5 }}
              />
            </div>
          </div>
        );
      },
      cellFormatter: (cellData, rowData) => {
        const { isSelected, pk_User, isAssignedAlready } = rowData;

        return (
          <div
            style={style.simpleRow}
            onClick={() => {
              if (!isAssignedAlready) {
                toggleSelectUserForAssignment(pk_User);
              }
            }}
          >
            <FontAwesomeIcon
              icon={isSelected || isAssignedAlready ? faSquareCheck : faSquare}
              style={{ cursor: !isAssignedAlready ? 'pointer' : 'not-allowed' }}
            />
          </div>
        );
      },
    },
    {
      label: 'Last Name',
      dataKey: 'UserLast',
      width: 200,
    },
    {
      label: 'First Name',
      dataKey: 'UserFirst',
      width: 200,
    },
  ];

  useEffect(() => {
    if (pk_User) {
      getPrescreenData({ pk_User });
    }
  }, [pk_User]);

  useEffect(() => {
    if (pk_Candidate) {
      getPrescreenData({ pk_Candidate });
    }
  }, [pk_Candidate]);

  useEffect(() => {
    if (candidates) {
      updateCandidateToDisplay();
    }
  }, [candidates, prescreenData]);

  useEffect(() => {
    if (users) {
      updateUsersToDisplay();
    }
  }, [users, prescreenData]);

  useEffect(() => {
    if (selectAllCandidatesForAssignment) {
      const newCandidatesToDisplay = candidatesToDisplay.map((c) => {
        return { ...c, isSelected: true };
      });
      setCandidatesToDisplay(newCandidatesToDisplay);
    } else {
      const newCandidatesToDisplay = candidatesToDisplay.map((c) => {
        return { ...c, isSelected: false };
      });
      setCandidatesToDisplay(newCandidatesToDisplay);
    }
  }, [selectAllCandidatesForAssignment]);

  const filterByString = (options) => {
    const { toFilter = [], searchString } = options;
    const newToDisplay = toFilter.filter((i) => {
      const firstName = i.FirstName || i.UserFirst || '';
      const lastName = i.LastName || i.UserLast || '';
      const fullName = `${lastName}, ${firstName} ${firstName} ${lastName}`;
      return !searchString || fullName.toLowerCase().includes(searchString.toLowerCase());
    });

    return newToDisplay;

    //   .sort((a, b) => {
    //   if (!a.LastName && !a.UserLast) return 1;
    //   if (!b.LastName && !b.UserLast) return -1;

    //   return `${a.LastName || a.UserLast || ''}, ${a.FirstName || a.UserFirst || ''}` <
    //     `${b.LastName || b.UserLast || ''}, ${b.FirstName || b.UserFirst || ''}`
    //     ? -1
    //     : 1;
    // });
  };

  const toggleSelectCandidateForAssignment = (pk_Candidate) => {
    const newCandidatesToDisplay = candidatesToDisplay.map((c) => {
      if (c.pk_Candidate === pk_Candidate) {
        return { ...c, isSelected: !c.isSelected };
      } else {
        return c;
      }
    });
    setCandidatesToDisplay(newCandidatesToDisplay);
  };

  const toggleSelectUserForAssignment = (pk_User) => {
    const newUsersToDisplay = usersToDisplay.map((u) => {
      if (u.pk_User === pk_User) {
        return { ...u, isSelected: !u.isSelected };
      } else {
        return u;
      }
    });

    setUsersToDisplay(newUsersToDisplay);
  };

  const getPrescreenData = (data = {}) => {
    const { pk_User, pk_Candidate } = data;

    setIsFetchingPrescreenData(true);
    const payload = {
      pk_Department: dContext.department.pk_Department,
      pk_Season: dContext.season.pk_Season,
    };

    if (pk_User) {
      payload.evaluatorPks = [pk_User];
    } else if (pk_Candidate) {
      payload.candidatePks = [pk_Candidate];
    }

    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/prescreen-assignments',
          payload,

          // 'department/season/user/scheduledate/candidates',
          // {
          //   pk_Department: dContext.department.pk_Department,
          //   pk_Season: dContext.season.pk_Season,
          //   pk_User: pk_User,
          //   mode: 'Prescreen',
          //   isAdmin: 1,
          // },

          formatBearerToken(token),
          { data: { evaluatorPks: [pk_User] } },
        )
          .then((res) => {
            if (res.status === 200) {
              setPrescreenData(res.data.sort((a, b) => (a.LastName > b.LastName ? 1 : -1)));
              const assignments = res.data[0] ? res.data[0].assignments : [];
              const sortedData = assignments.sort((a, b) =>
                a.Status === 'Pending' ? -1 : b.Status === 'Pending' ? 1 : 0,
              );

              setPrescreenData(sortedData);
            }
          })
          .catch((err) => {
            console.log('Error: ', err);
          })
          .finally(() => {
            setIsFetchingPrescreenData(false);
          });
      })
      .catch((err) => {
        console.log('Error: ', err);
        setIsFetchingPrescreenData(false);
        if (err.error === 'login_required') {
          loginWithRedirect();
        }
      });
  };

  const updateCandidateToDisplay = () => {
    const newCandidatesToDisplay = [];

    candidates.forEach((candidate) => {
      const newCandidate = { ...candidate };
      prescreenData.forEach((prescreen) => {
        if (prescreen.pk_Candidate === candidate.pk_Candidate) {
          newCandidate.isAssignedAlready = true;
        }
      });
      newCandidatesToDisplay.push(newCandidate);
    });

    newCandidatesToDisplay.sort((a, b) => {
      if (a.isAssignedAlready && !b.isAssignedAlready) return -1;
      if (!a.isAssignedAlready && b.isAssignedAlready) return 1;
      if (a.LastName > b.LastName) return 1;
      if (a.LastName < b.LastName) return -1;
      if (a.FirstName > b.FirstName) return 1;
      if (a.FirstName < b.FirstName) return -1;
      return 0;
    });

    setCandidatesToDisplay(newCandidatesToDisplay);
  };

  const updateUsersToDisplay = () => {
    const newUsersToDisplay = [];

    users.forEach((user) => {
      const newUser = { ...user };
      prescreenData.forEach((prescreen) => {
        if (prescreen.pk_User === user.pk_User) {
          newUser.isAssignedAlready = true;
        }
      });
      newUsersToDisplay.push(newUser);
    });

    newUsersToDisplay.sort((a, b) => {
      if (a.isAssignedAlready && !b.isAssignedAlready) return -1;
      if (!a.isAssignedAlready && b.isAssignedAlready) return 1;
      if (a.UserLast > b.UserLast) return 1;
      if (a.UserLast < b.UserLast) return -1;
      if (a.UserFirst > b.UserFirst) return 1;
      if (a.UserFirst < b.UserFirst) return -1;
      return 0;
    });

    setUsersToDisplay(newUsersToDisplay);
  };

  const assignCandidatesToUsers = () => {
    // const candidatesToAssign = candidatesToDisplay.filter((c) => c.isSelected).map((c) => c.pk_Candidate);
    // const usersToAssign = usersToDisplay.filter((u) => u.isSelected).map((u) => u.pk_User);
    const toAssign = pk_User
      ? candidatesToDisplay.filter((c) => c.isSelected).map((c) => c.pk_Candidate)
      : pk_Candidate
      ? usersToDisplay.filter((u) => u.isSelected).map((u) => u.pk_User)
      : [];
    setIsAssigningCandidates(true);

    getTokenSilently()
      .then((token) => {
        postDataAgnostic(
          'department/season/users/prescreen',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          { candidatePks: pk_User ? toAssign : [pk_Candidate], evaluatorPks: pk_Candidate ? toAssign : [pk_User] },
          formatBearerToken(token),
        )
          .then((res) => {
            const payload = {};
            if (pk_User) {
              payload.pk_User = pk_User;
            } else if (pk_Candidate) {
              payload.pk_Candidate = pk_Candidate;
            }

            getPrescreenData(payload);
            if (pk_Candidate) {
              alert.success(`Added Screener${toAssign.length > 1 ? 's' : ''} successfully`);
            } else {
              alert.success(`Added Candidate${toAssign.length > 1 ? 's' : ''} successfully`);
            }

            setIsAssigningCandidates(false);
            setShowCandidatesForAssignment(false);

            setIsAssigningUsers(false);
            setShowUsersForAssignment(false);
          })
          .catch((err) => {
            console.log('err: ', err);

            setIsAssigningCandidates(false);
            setIsAssigningUsers(false);

            alert.error(`Error adding ${pk_User ? 'Candidate' : 'Screener'}${toAssign.length > 1 ? 's' : ''}`);
          });
      })
      .catch((err) => {
        setIsAssigningCandidates(false);
        alert.error('Error adding Screener');
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const removeAssignment = (data) => {
    const { pk_Candidate, pk_User, type } = data;

    if (type === 'evaluator') {
      setCandidatesBeingDeleted([...candidatesBeingDeleted, pk_Candidate]);
    }
    if (type === 'candidate') {
      setUsersBeingDeleted([...usersBeingDeleted, pk_User]);
    }

    getTokenSilently()
      .then((token) => {
        deleteData(
          'department/season/users/prescreen',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          { candidatePks: [pk_Candidate], evaluatorPks: [pk_User] },

          formatBearerToken(token),
        )
          .then((res) => {
            getPrescreenData(type === 'evaluator' ? { pk_User } : { pk_Candidate });
            if (type === 'evaluator') {
              setCandidatesBeingDeleted((prevData) => {
                return prevData.filter((c) => c !== pk_Candidate);
              });
            } else if (type === 'candidate') {
              setUsersBeingDeleted((prevData) => {
                return prevData.filter((u) => u !== pk_User);
              });
            }

            alert.success('Removed successfully');
          })
          .catch((err) => {
            alert.error('Error removing');
          });
      })
      .catch((err) => {
        alert.error('Error removing');
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const renderCandidatesForAssignment = () => {
    return (
      <Modal isOpen={showCandidatesForAssignment} style={{ maxWidth: '80vw', minWidth: 500 }} centered>
        <ModalHeader
          toggle={() => {
            setShowCandidatesForAssignment(!showCandidatesForAssignment);
          }}
        >
          {' '}
          Assign Candidates to {userName} for Prescreening
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              ...style.simpleRow,
              marginTop: 10,
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <div style={{ ...style.simpleColumn, width: 'auto', justifyContent: 'flex-start', overflow: 'auto' }}>
              <div style={style.simpleRow}>
                <div style={{ ...style.simpleColumn, width: 400, marginBottom: 10 }}>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <Button
                        size="sm"
                        onClick={() => {
                          setCandidateSearchString('');
                          document.getElementById(`searchCandidatesForAssignment`).value = '';
                        }}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </Button>
                    </InputGroupAddon>
                    <Input
                      size="sm"
                      type="text"
                      id={`searchCandidatesForAssignment`}
                      // value={candidateSearchString}
                      onChange={(e) => {
                        if (typingTimeout) {
                          clearTimeout(typingTimeout);
                        }
                        const newValue = e.target.value;
                        typingTimeout = setTimeout(() => {
                          setCandidateSearchString(newValue);
                        }, 500);
                      }}
                    />
                    <InputGroupAddon addonType="append">
                      <Button color={'success'} size="sm">
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
              </div>
              <RezRateTable
                data={filterByString({ toFilter: candidatesToDisplay, searchString: candidateSearchString }) || []}
                tableBodyHeight={`45vh`}
                columns={candidatesForAssignmentColumns}
                noDataMessage={'No Data'}
                onRowClick={(rowData) => {
                  const { isSelected, pk_Candidate, isAssignedAlready } = rowData;
                  if (!isAssignedAlready) {
                    toggleSelectCandidateForAssignment(pk_Candidate);
                  }
                }}
                rowStyle={(rowData) => {
                  if (rowData.isAssignedAlready) {
                    return { backgroundColor: '#CEFFDA' };
                  }
                }}
              />

              <div style={style.simpleRow}>
                <Button
                  size="sm"
                  disabled={isAssigningCandidates || candidatesToDisplay.filter((c) => c.isSelected).length <= 0}
                  color="success"
                  onClick={() => {
                    assignCandidatesToUsers();
                  }}
                >
                  Assign to {userName}{' '}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  const renderUsersForAssignment = () => {
    return (
      <Modal isOpen={showUsersForAssignment} style={{ maxWidth: '80vw', minWidth: 500 }} centered>
        <ModalHeader
          toggle={() => {
            setShowUsersForAssignment(!showUsersForAssignment);
          }}
        >
          {' '}
          Assign Evaluators to {candidateName || ''} for Prescreening
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              ...style.simpleRow,
              marginTop: 10,
              justifyContent: 'center',
              width: '100%',
            }}
          >
            <div style={{ ...style.simpleColumn, width: 'auto', justifyContent: 'flex-start', overflow: 'auto' }}>
              <div style={style.simpleRow}>
                <div style={{ ...style.simpleColumn, width: 400, marginBottom: 10 }}>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <Button
                        size="sm"
                        onClick={() => {
                          setUserSearchString('');
                          document.getElementById(`searchUsersForAssignment`).value = '';
                        }}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </Button>
                    </InputGroupAddon>
                    <Input
                      size="sm"
                      type="text"
                      id={`searchUsersForAssignment`}
                      // value={candidateSearchString}
                      onChange={(e) => {
                        if (typingTimeout) {
                          clearTimeout(typingTimeout);
                        }
                        const newValue = e.target.value;
                        typingTimeout = setTimeout(() => {
                          setUserSearchString(newValue);
                        }, 500);
                      }}
                    />
                    <InputGroupAddon addonType="append">
                      <Button color={'success'} size="sm">
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
              </div>
              <RezRateTable
                data={filterByString({ toFilter: usersToDisplay, searchString: userSearchString }) || []}
                tableBodyHeight={`45vh`}
                columns={usersForAssignemntColumns}
                noDataMessage={'No Data'}
                onRowClick={(rowData) => {
                  const { isSelected, pk_User, isAssignedAlready } = rowData;
                  if (!isAssignedAlready) {
                    toggleSelectUserForAssignment(pk_User);
                  }
                }}
                rowStyle={(rowData) => {
                  if (rowData.isAssignedAlready) {
                    return { backgroundColor: '#CEFFDA' };
                  }
                }}
              />

              <div style={style.simpleRow}>
                <Button
                  size="sm"
                  disabled={isAssigningUsers || usersToDisplay.filter((c) => c.isSelected).length <= 0}
                  color="success"
                  onClick={() => {
                    assignCandidatesToUsers();
                  }}
                >
                  Assign to {candidateName}{' '}
                </Button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  };

  return (
    <>
      <div
        style={{
          ...style.simpleRow,
          marginTop: 10,
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <div style={{ ...style.simpleColumn, width: 'auto', justifyContent: 'flex-start', overflow: 'auto' }}>
          <div style={{ ...style.simpleRow, marginBottom: 20, justifyContent: 'space-between', width: '100%' }}>
            <div style={style.simpleColumn}>
              <div style={style.simpleRow}>
                {' '}
                <b style={{ marginRight: 10 }}>Total Assigned:</b> {prescreenData.length}
              </div>
            </div>
            <Button
              size="sm"
              color="success"
              onClick={() => {
                if (pk_User && !pk_Candidate) {
                  setShowCandidatesForAssignment(!showCandidatesForAssignment);
                } else {
                  setShowUsersForAssignment(!showUsersForAssignment);
                }
              }}
            >
              <FontAwesomeIcon icon={faUserPlus} style={{ marginRight: 10 }} />
              {pk_User ? 'Assign Candidate' : 'Assign Screener'}
            </Button>
          </div>
          <div style={style.simpleRow}>
            {' '}
            <div style={{ ...style.simpleColumn, width: 'auto', justifyContent: 'flex-start', overflow: 'auto' }}></div>
            {pk_User ? renderCandidatesForAssignment() : pk_Candidate ? renderUsersForAssignment() : null}
          </div>
          <RezRateTable
            data={prescreenData || []}
            tableBodyHeight={tableHeight}
            columns={pk_User ? columnData || [] : evaluatorsColumnData || []}
            noDataMessage={isFetchingPrescreenData ? 'Loading. . .' : 'No Data'}
            // rowStyle={(rowData) => {
            //   const { isSelected } = rowData;
            //   if (isSelected) {
            //     return {
            //       backgroundColor: '#ADD8E6',
            //     };
            //   } else {
            //     return {};
            //   }
            // }}
          />
        </div>
      </div>
      {/* <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <div style={{ ...style.simpleColumn, width: 'auto' }}>
         
        </div>
      </div> */}
    </>
  );
};

export default PrescreenAssignments;

// // for Candidate page - array of candidates with their evaluators
// [
//   {
//     pk_Candidate: 1,
//     FirstName: 'Trowa',
//     LastName: 'Barton',
//     email: 'x@xmail.com',
//     AAMCID: '123456',
//     SFMatchID: '123456',
//     assignments: [
//       {
//         pk_Evaluator: 1,
//         UserFirst: 'Heero',
//         UserLast: 'Yuy',
//         status: 'Pending',
//       },
//     ],
//   },
// ];

// // for Team page - array of users with their candidates
// [
//   {
//     pk_User: 1,
//     UserFirst: 'Heero',
//     UserLast: 'Yuy',
//     email: '123456',
//     assignments: [
//       {
//         pk_Candidate: 1,
//         FirstName: 'Trowa',
//         LastName: 'Barton',
//         email: 'x@xmail.com',
//         AAMCID: '123456',
//         SFMatchID: '123456',
//         status: 'Pending',
//       },
//     ],
//   },
// ];

import { forEach } from 'lodash';
import moment from 'moment-timezone';
import { parse } from 'html-react-parser';

/**
 * Auto populates schedule with given columns. Returns rows with pk_User
 * as keys.
 * @param {Object} scheduleData appears as object with room ids as keys.
 * @param {Array}  candidates   candidates off of fetchCandidate API
 * @param {Object} options      maxInterview limits, ignore limits, etc
 */
export const generateSchedule = (scheduleData, candidates = [], options = {}) => {
  const { headers, body } = scheduleData;
  const { clearTable = true, headersToFillUp = [], mode = 'ordered' } = options; // headersToFillUp is pk_ScheduleAssignment keys of columns we want to fill

  let bestData = null;
  let bestCandidateInterviewCount = 0; // most amount of candidates registered. We keep the highest count.
  let counter = 20;

  while (counter > 0) {
    let candidateInterviewCount = 0;
    let potentialCandidateTracker = {};
    // potentialCandidateTracker shape:
    //  {
    //   cellValues: {pk_Timeslot:
    //   {
    //       pk_scheduleAssignment: [pk_Candidate1, pkCandidate2]
    //   }}
    //   candidatePossiblePlacementCounts: {
    //     pk_Candidate1: 0,
    //     pk_Candidate2: 0,
    //   }
    // }

    // Basic Rule: No candidate on same row or column.
    // Consider Unavailable slots, slots that can't be populated with Candidates.
    // Plug the user with least interviews.
    // Stop after all candidates are in all columns.
    // TODO: ADD SPACES IN CANDIDATE KEYS! SPACES TO ACT LIKE EMPTY CANDIDATES
    let candidateKeys = shuffleItems(extractCandidateIds(candidates)); // !clearTable ? shuffleItems(extractCandidateIds(candidates)) : extractCandidateIds(candidates);
    const originalCandidateKeys = clone(candidateKeys);

    let columnTracker = extractHeaderIds(headers); // used to track if user exists in column

    let rowTracker = extractTimeSlotIds(JSON.parse(JSON.stringify(body))); // used to track if user exists in row.
    let interviewCountTracker = {}; // tracks how many interviews a pk_Candidate has
    const pendingItems = headersToFillUp.length > 0 ? {} : { all: true };
    candidateKeys.forEach((key) => {
      interviewCountTracker[key] = 0;
    });

    const rowsToFill = Object.keys(rowTracker); // List of timeSlot keys, fill up per row.
    const viableColumns = getViableColumns({
      scheduleData,
      options,
      candidates,
      rowTracker: clone(rowTracker),
      columnTracker: clone(columnTracker),
    });

    const columnsToFill = headers
      .filter((h) => {
        return viableColumns.includes(h.pk_ScheduleAssignment.toString());
      })
      .map((h) => {
        return h.pk_ScheduleAssignment;
      })
      .reverse(); // Object.keys(columnTracker);

    const newBody = []; // New Schedule

    const debuggerRowTracker = {};

    let candidateIndex = Math.floor(Math.random() * candidateKeys.length);

    // Spaces for when there are extra columns. Try make random list of columns to be spaces EVERY row.
    // Don't shuffle headers, just make list of columns to be empty per row.
    // if (mode === 'ordered' && columnsToFill.length > candidateKeys.length) {
    //   let extraCounter = columnsToFill.length - candidateKeys.length;

    // while (extraCounter > 0) {
    //   candidateKeys.push(`space_${extraCounter}`);
    //   extraCounter--;
    // }

    //   candidateKeys = shuffleItems(candidateKeys);
    // }

    // ============ For Debugger purposes ==============//
    rowsToFill.forEach((row) => {
      debuggerRowTracker[row] = {};
    });

    Object.keys(debuggerRowTracker).forEach((rowId) => {
      const row = debuggerRowTracker[rowId];
      columnsToFill.forEach((column) => {
        row[column] = [];
      });
    });
    // ===================================================//

    const processedBody = clearTable ? clearTimeslots(clone(body)) : clone(body);
    // TODO: Check for generate for column. This SHOULD fire for it.
    if (!clearTable) {
      const newTrackers = trackExistingCandidates(body, rowTracker, columnTracker, headersToFillUp);

      rowTracker = newTrackers.newRowTracker;
      columnTracker = newTrackers.newColumnTracker;
      Object.keys(rowTracker).forEach((r) => {
        candidateKeys.forEach((c) => {
          if (rowTracker[r].includes(c)) {
            if (interviewCountTracker[c] != null) {
              interviewCountTracker[c] += 1;
            } else {
              interviewCountTracker[c] = 1;
            }
          }
        });
      });
    }

    potentialCandidateTracker = processAvailableCandidatesPerCell({
      scheduleData,
      headersToFillUp,
      trackers: { rowTracker, columnTracker },
      candidateKeys,
      interviewCountTracker,
      options,
      potentialCandidateTracker,
    });

    let extraColumns = columnsToFill.length - candidateKeys.length;
    let headersToIgnore = [];

    processedBody.forEach((timeSlotRow, i) => {
      const timeSlot = timeSlotRow.pk_Timeslot;
      const bodyRowIndex = i;

      const newRowForBody = bodyRowIndex != null ? clone(body[bodyRowIndex]) : null;

      const currentRowToFill = rowTracker[timeSlot];
      const shuffledColumns = mode === 'ordered' ? columnsToFill : shuffleItems(columnsToFill);

      const viableCandidatesForRow = getAvailableCandidatesForRow({
        timeSlotRow,
        candidateKeys: originalCandidateKeys,
        currentRowToFill,
        columnsToFill: shuffledColumns,
        columnTracker,
        interviewCountTracker,
        scheduleData,
        options,
      });

      if (viableCandidatesForRow.length < shuffledColumns.length) {
        // candidateKeys = clone(originalCandidateKeys);
        candidateKeys = addCandidateKeysListSpacers(
          originalCandidateKeys.filter((ck) => {
            return viableCandidatesForRow.includes(ck);
          }),
          shuffledColumns,
        );
      } else {
        candidateKeys = clone(originalCandidateKeys);
      }
      headersToIgnore = getRandomItemsFromList(extraColumns, shuffledColumns);

      shuffledColumns.forEach((column) => {
        if (
          (headersToFillUp.length > 0 && !headersToFillUp.includes(column)) ||
          (candidateKeys.length < shuffledColumns.length && headersToIgnore.includes(column))
        ) {
          return;
        }

        if (candidateIndex >= candidateKeys.length) {
          candidateIndex = 0;
        }

        newRowForBody[column].ScheduleBlockEntries = [];

        const rowCell = timeSlotRow[column];
        const { ScheduleBlockTotalNumberOfSplits, ColumnTotalNumberOfSplits, ScheduleBlockEntries = [] } =
          rowCell || {};

        const scheduleBlockCount = ScheduleBlockEntries.length;
        let scheduleBlockIndex = 0;

        while (scheduleBlockIndex < scheduleBlockCount) {
          let currentCandidateToAdd = candidateKeys[candidateIndex];
          const currentColumnToFill = columnTracker[column];
          const pendingItemKey = `${timeSlot}_${column}${scheduleBlockCount > 1 ? `_${scheduleBlockIndex}` : `_0`}`;
          let scheduleAttempts = candidateKeys.length; //- 1;
          let isFreeCandidate = canStillAddInterview(currentCandidateToAdd, interviewCountTracker, scheduleData, {
            ...options,
          });
          const scheduleBlock = rowCell.ScheduleBlockEntries[scheduleBlockIndex];

          const flexEventCandidates = (scheduleBlock && scheduleBlock.flexEventCandidates) || [];

          // Skip Unavailables. And space candidate placeholders.
          if (
            // (currentCandidateToAdd && currentCandidateToAdd.indexOf('space_') >= 0) ||
            (rowCell && rowCell.isFlexEvent) /*&& scheduleBlock.isFlexEvent*/ ||
            (headersToFillUp.length > 0 && headersToFillUp.indexOf(parseInt(column)) < 0) ||
            timeSlotRow.CustomMeetingTitle ||
            timeSlot === 'END'
          ) {
            scheduleAttempts = -1;
            candidateIndex++;
            newRowForBody[column].ScheduleBlockEntries.push({
              ...scheduleBlock,
              Candidates: [],
            });
          } else {
            let candidateIsInFlexEventForThisCell = flexEventCandidates.find((f) => {
              let hasCandidate = false;
              flexEventCandidates.forEach((c) => {
                if (c.pk_Candidate == currentCandidateToAdd) {
                  hasCandidate = true;
                }
              });

              return hasCandidate;
            });
            // Checks if candidate exists already along the column or along the row. Also checks
            // if candidate still has interviews remaining, i.e. interview count below interview limit.
            while (
              (currentColumnToFill.indexOf(currentCandidateToAdd) >= 0 ||
                currentRowToFill.indexOf(currentCandidateToAdd) >= 0 ||
                !isFreeCandidate ||
                candidateIsInFlexEventForThisCell) &&
              scheduleAttempts >= 0
            ) {
              if (candidateIndex >= candidateKeys.length) {
                candidateIndex = 0;
              }

              currentCandidateToAdd = candidateKeys[candidateIndex];
              scheduleAttempts--;

              isFreeCandidate = canStillAddInterview(
                currentCandidateToAdd,
                interviewCountTracker,
                scheduleData,
                options,
              );
              candidateIsInFlexEventForThisCell = flexEventCandidates.find((f) => {
                let hasCandidate = false;
                flexEventCandidates.forEach((c) => {
                  if (c.pk_Candidate == currentCandidateToAdd) {
                    hasCandidate = true;
                  }
                });

                return hasCandidate;
              });
              candidateIndex++;
              if (candidateIndex >= candidateKeys.length) {
                candidateIndex = 0;
              }
            }

            // IF populating cell A, and cell B above cell A contains candidate1, cell A gets
            // candidate2. However, cell C to the right of cell A gets candidate3 instead of
            // ideally candidate1 to make step-pattern.
            // EDIT: Yeah i dunno, step pattern appeared before i did anything anyway.
            if (
              scheduleAttempts >= 0 &&
              currentCandidateToAdd != null

              // && currentCandidateToAdd.indexOf('space_') < 0 // uncomment to hide spaces
            ) {
              currentColumnToFill.push(currentCandidateToAdd);
              currentRowToFill.push(currentCandidateToAdd);
              if (interviewCountTracker[currentCandidateToAdd] == null) {
                interviewCountTracker[currentCandidateToAdd] = 0;
              }
              interviewCountTracker[currentCandidateToAdd] += 1;

              // debuggerColumnTracker[column] = debuggerColumnTracker[column]
              //   ? [...debuggerColumnTracker[column], currentCandidateToAdd]
              //   : [currentCandidateToAdd];
              // if (debuggerRowTracker[timeSlot] == null) {
              //   debuggerRowTracker[timeSlot] = {};
              // }
              // debuggerRowTracker[timeSlot][column] = debuggerRowTracker[timeSlot][column]
              //   ? [...debuggerRowTracker[timeSlot][column], currentCandidateToAdd]
              //   : [currentCandidateToAdd];

              // Remove for split
              if (newRowForBody != null) {
                if (candidates[currentCandidateToAdd] == null) {
                }
                newRowForBody[column].ScheduleBlockEntries.push({
                  ...scheduleBlock,
                  Candidates: [candidates[currentCandidateToAdd]],
                  SortOrder: scheduleBlockIndex,
                  fk_Timeslot: timeSlotRow.pk_Timeslot,
                  fk_ScheduleAssignment: parseInt(column),
                });
              }
              pendingItems[pendingItemKey] = [
                {
                  ...candidates[currentCandidateToAdd],
                  pendingItemKey: pendingItemKey,
                  specialLabel: currentCandidateToAdd,
                },
              ];
            } else {
              if (currentCandidateToAdd.indexOf('space_') >= 0) {
                currentColumnToFill.push(currentCandidateToAdd);
                currentRowToFill.push(currentCandidateToAdd);
                interviewCountTracker[currentCandidateToAdd] += 1;
              } else {
                currentColumnToFill.push('space');
                currentRowToFill.push('space');
                // interviewCountTracker['space'] += 1;
              }

              newRowForBody[column].ScheduleBlockEntries.push({
                Candidates: [],
                SortOrder: scheduleBlockIndex,
                Placeholder: true,
              });

              // debuggerColumnTracker[column] = debuggerColumnTracker[column] ? [...debuggerColumnTracker[column], ' '] : [' '];
              // if (debuggerRowTracker[timeSlot] == null) {
              //   debuggerRowTracker[timeSlot] = {};
              // }
              // debuggerRowTracker[timeSlot][column] = debuggerRowTracker[timeSlot][column]
              //   ? [...debuggerRowTracker[timeSlot][column], ' ']
              //   : [' '];
              // No user can be added to slot beacause of reasons
            }

            columnTracker[column] = currentColumnToFill;
          }

          // This is for when the schedule block is IMMEDIATELY populated by the first candidate we try,
          // never passing through the while loop above. This is because the while loop is only entered
          // when the candidate is not fit for the slot.
          if (scheduleAttempts >= candidateKeys.length) {
            candidateIndex++;
          }
          if (candidateIndex >= candidateKeys.length) {
            candidateIndex = 0;
          }
          scheduleBlockIndex++;
        }
      });

      rowTracker[timeSlot] = currentRowToFill;
      newBody[bodyRowIndex] = newRowForBody;
    });

    let debuggerText = '';
    Object.keys(debuggerRowTracker).forEach((rowId) => {
      debuggerText += `${rowId} - |`;
      const row = debuggerRowTracker[rowId];
      columnsToFill.forEach((column) => {
        if (row[column]) {
          row[column].forEach((candidate) => {
            debuggerText += `${candidate ? candidate : '--'}`;
          });
        }
        debuggerText += '|';
      });

      debuggerText += '\n|';
    });

    const data = {
      newBody,
      debuggerText,
      rowTracker,
      columnTracker,
      clearTable,
      headersToFillUp,
      pendingItems,
      interviewCountTracker,
    };
    Object.keys(interviewCountTracker).forEach((key) => {
      if (key.indexOf('space_') < 0) {
        candidateInterviewCount += interviewCountTracker[key];
      }
    });

    if (candidateInterviewCount > bestCandidateInterviewCount) {
      bestData = data;
      bestCandidateInterviewCount = candidateInterviewCount;
    }
    counter--;
  }

  return bestData;
};

// sets up trackers for flex events vs evaluators.
export const setupTrackers = (scheduleDataToTrack) => {
  const { headers = [], body = [] } = scheduleDataToTrack;

  const newRowTracker = {};
  const newColumnTracker = {};
  const newScheduleBlockTracker = {};

  headers.forEach((header) => {
    if (newColumnTracker[header.pk_ScheduleAssignment] == null) {
      newColumnTracker[header.pk_ScheduleAssignment] = [];
    }
    body.forEach((timeSlotRow) => {
      const rowColumn = timeSlotRow[header.pk_ScheduleAssignment];
      if (newRowTracker[timeSlotRow.pk_Timeslot] == null) {
        newRowTracker[timeSlotRow.pk_Timeslot] = [];
      }
      if (rowColumn && rowColumn.ScheduleBlockEntries) {
        rowColumn.ScheduleBlockEntries.forEach((scheduleBlock) => {
          newScheduleBlockTracker[scheduleBlock.pk_ScheduleBlock] = { row: timeSlotRow, column: rowColumn };

          if (scheduleBlock.Candidates) {
            scheduleBlock.Candidates.forEach((candidate) => {
              if (!candidate) {
                return;
              }

              newRowTracker[timeSlotRow.pk_Timeslot].push(candidate.pk_Candidate);
              newColumnTracker[header.pk_ScheduleAssignment].push(candidate.pk_Candidate);
            });
          } else {
          }
        });
      }
    });
  });

  const dataToReturn = {
    splitCountTracker: setupSplitCountTrackers(scheduleDataToTrack),
    splitBlockCandidateTracker: setupSplitBlockCandidateTrackers(scheduleDataToTrack),
    rowTracker: newRowTracker,
    columnTracker: newColumnTracker,
    scheduleBlockTracker: newScheduleBlockTracker,
  };

  return dataToReturn;
  // setSplitCountTracker(setupSplitCountTrackers(scheduleDataToTrack));
  // setSplitBlockCandidateTracker(setupSplitBlockCandidateTrackers(scheduleDataToTrack));
  // setRowTracker(newRowTracker);
  // setColumnTracker(newColumnTracker);
  // if (setParentColumnTracker) {
  //   setParentColumnTracker(newColumnTracker);
  // }

  // if (setParentRowTracker) {
  //   setParentRowTracker(newRowTracker);
  // }
};

export const generateScheduleColumn = (scheduleData, candidates = [], options = {}) => {
  const { headers, body } = scheduleData;
  const { clearTable = true, headersToFillUp = [] } = options; // headersToFillUp is pk_ScheduleAssignment keys of columns we want to fill
  let potentialCandidateTracker = {};
  let columnTracker = extractHeaderIds(headers); // used to track if user exists in column

  let rowTracker = extractTimeSlotIds(JSON.parse(JSON.stringify(body))); // used to track if user exists in row.

  let newTrackers = trackExistingCandidates(
    body,
    rowTracker,
    columnTracker,
    headersToFillUp, //headersToFillUp.length == headers.length ? [] : headersToFillUp,
  );
  let interviewCountTracker = {}; // tracks how many interviews a pk_Candidate has

  rowTracker = clearTable ? rowTracker : newTrackers.newRowTracker;
  columnTracker = clearTable ? columnTracker : newTrackers.newColumnTracker;
  let candidateKeys = shuffleItems(extractCandidateIds(candidates)); // !clearTable ? shuffleItems(extractCandidateIds(candidates)) : extractCandidateIds(candidates);

  if (!clearTable) {
    Object.keys(rowTracker).forEach((r) => {
      candidateKeys.forEach((c) => {
        if (rowTracker[r].includes(c)) {
          if (interviewCountTracker[c] != null) {
            interviewCountTracker[c] += 1;
          } else {
            interviewCountTracker[c] = 1;
          }
        }
      });
    });
  }

  const processedScheduleData = clone(scheduleData);

  const pendingItems = headersToFillUp.length > 0 ? {} : { all: true };

  const rowsToFill = Object.keys(rowTracker); // List of timeSlot keys, fill up per row.
  const columnsToFill = headers
    .map((h) => {
      return h.pk_ScheduleAssignment;
    })
    .reverse();

  const debuggerRowTracker = {};

  // ============ For Debugger purposes ==============//
  rowsToFill.forEach((row) => {
    debuggerRowTracker[row] = {};
  });

  Object.keys(debuggerRowTracker).forEach((rowId) => {
    const row = debuggerRowTracker[rowId];
    columnsToFill.forEach((column) => {
      row[column] = [];
    });
  });
  // ===================================================//

  const processedBody = clearTable ? clearTimeslots(body) : body;
  const newBody = clone(processedBody); // New Schedule

  potentialCandidateTracker = processAvailableCandidatesPerCell({
    scheduleData: processedScheduleData,
    headersToFillUp,
    trackers: { rowTracker, columnTracker },
    candidateKeys: extractCandidateIds(candidates),
    interviewCountTracker,
    options,
    potentialCandidateTracker,
  });

  headersToFillUp.forEach((h) => {
    let cellsToProcessCount = 0;
    let currentColumnPotentialValues = potentialCandidateTracker.cellValues[h];
    let processedCells = [];
    const { cellPossibleCandidateCounts } = potentialCandidateTracker;
    Object.values(currentColumnPotentialValues).forEach((item) => {
      cellsToProcessCount += item.length;
    });
    // Find rows that haven't been processed yet
    let cellsToProcess = cellPossibleCandidateCounts[h].filter((item) => {
      return processedCells.indexOf(item.pk_Timeslot) < 0;
    });
    const currentColumnToFill = clone(columnTracker[h]);

    while (cellsToProcessCount > 0) {
      const {
        cellValues,
        cellPossibleCandidateCounts,
        candidatePossiblePlacementCounts,
        viableCandidates,
      } = potentialCandidateTracker;
      let candidateWithLeastPossibilities = [];
      // Find what is the lowest count of possible candidates among cells
      const lowestCount = null;

      cellsToProcess = cellsToProcess.filter((item) => {
        return !processedCells.find((processedItem) => {
          return (
            parseInt(item.pk_Timeslot) == parseInt(processedItem.pk_Timeslot) &&
            parseInt(item.SortOrder) == parseInt(processedItem.SortOrder)
          );
        });
      });

      cellsToProcess.forEach((item) => {
        if (lowestCount == null || item.count < lowestCount) {
          lowestCount = item.count;
        }
      });

      // Find cells that have lowest count. Could be more than 1 cell.
      cellsToProcess = cellsToProcess.filter((item) => {
        return item.count == lowestCount;
      });

      // If more than 1 cell, sort by time, then pick 0 index.
      if (cellsToProcess && cellsToProcess.length > 1) {
        cellsToProcess = cellsToProcess
          .map((item) => {
            const rowActual = body.find((row) => {
              return row.pk_Timeslot == item.pk_Timeslot;
            });

            return { ...item, slot: rowActual.slot, slotEnd: rowActual.slotEnd };
          })
          .sort((item1, item2) => {
            return new Date(item1.slot) - new Date(item2.slot);
          });
      }

      const cell = cellsToProcess[0];
      // cellsToProcess.length > 1
      //   ? cellsToProcess[Math.floor(Math.random() * cellsToProcess.length)]
      //   : cellsToProcess[0];
      const timeSlot = cell.pk_Timeslot;
      const newRowForBody = clone(
        processedBody.find((row) => {
          return row.pk_Timeslot == parseInt(timeSlot);
        }),
      );
      const rowIndex = body.findIndex((row) => {
        return row.pk_Timeslot == parseInt(timeSlot);
      });

      const columnIndex = headers.findIndex((header) => {
        return header.pk_ScheduleAssignment == parseInt(h);
      });
      const rowCell = clone(newRowForBody[h]);
      const scheduleBlockCount =
        rowCell && rowCell.ScheduleBlockTotalNumberOfSplits != null
          ? rowCell.ScheduleBlockTotalNumberOfSplits
          : rowCell.ColumnTotalNumberOfSplits != null
          ? rowCell.ColumnTotalNumberOfSplits
          : 1;

      const currentRowToFill = clone(rowTracker[timeSlot]);

      rowCell.ScheduleBlockEntries = [];
      const scheduleBlocks = clone(newRowForBody[h].ScheduleBlockEntries);
      // let scheduleBlockIndex = 0;
      // Run through cell's schedule blocks, assign candidates if available
      // TODO: Update available candidates for each block after filling up. Don't rely on potential tracker.

      // fill up candidatesInBlocks with candidates already assigned to blocks. Blocks within the same
      // cell usually share the same list of viable candidates.
      const candidatesInBlocks = [];

      scheduleBlocks.forEach((scheduleBlock, scheduleBlockIndex) => {
        // Find candidate with least possibilities for cell and assign.
        // const scheduleBlock = newRowForBody[h].ScheduleBlockEntries[scheduleBlockIndex];
        const tempItem = cellValues[h][parseInt(cell.pk_Timeslot)];

        candidateKeys = tempItem[scheduleBlock.SortOrder];
        candidateKeys = candidateKeys.filter((key) => {
          return !candidatesInBlocks.includes(key);
        });
        // Skip Unavailables. And space candidate placeholders.
        if ((rowCell && rowCell.isFlexEvent) || newRowForBody.CustomMeetingTitle || timeSlot === 'END') {
          rowCell.ScheduleBlockEntries.push({
            ...scheduleBlock,
            Candidates: [],
          });
        } else {
          candidateWithLeastPossibilities = [];
          let lowestCandidateCount = null;
          const placementCounts = candidatePossiblePlacementCounts[h]
            ? clone(candidatePossiblePlacementCounts[h]).filter((item) => {
                return candidateKeys.indexOf(item.pk_Candidate) >= 0;
              })
            : [];

          candidateKeys.forEach((c) => {
            const cActual = placementCounts.find((cpp) => {
              return cpp.pk_Candidate == parseInt(c);
            });
            if (cActual && (lowestCandidateCount == null || cActual.count < lowestCandidateCount)) {
              lowestCandidateCount = cActual.count;
            }
          });

          candidateWithLeastPossibilities = placementCounts.filter((item) => {
            return item.count == lowestCandidateCount;
          });

          if (candidateWithLeastPossibilities && candidateWithLeastPossibilities.length > 0) {
            const candidateIndexToAdd =
              candidateWithLeastPossibilities.length > 1
                ? Math.floor(Math.random() * candidateWithLeastPossibilities.length)
                : 0;
            const { pk_Candidate } = candidateWithLeastPossibilities[candidateIndexToAdd];
            currentColumnToFill.push(pk_Candidate);

            currentRowToFill.push(pk_Candidate);
            candidatesInBlocks.push(pk_Candidate);
            if (interviewCountTracker[pk_Candidate] == null) {
              interviewCountTracker[pk_Candidate] = 1;
            } else {
              interviewCountTracker[pk_Candidate] += 1;
            }

            const pendingItemKey = `${timeSlot}_${h}${scheduleBlockCount > 1 ? `_${scheduleBlockIndex}` : `_0`}`;
            pendingItems[pendingItemKey] = [
              {
                ...candidates[pk_Candidate],
                pendingItemKey: pendingItemKey,
              },
            ];

            rowCell.ScheduleBlockEntries.push({
              ...scheduleBlock,
              Candidates: [candidates[pk_Candidate]],
              SortOrder: scheduleBlockIndex,
              fk_Timeslot: timeSlot,
              fk_ScheduleAssignment: parseInt(h),
            });
          } else {
            rowCell.ScheduleBlockEntries.push({
              ...scheduleBlock,
              Candidates: [],
            });
          }
        }
        processedCells.push(
          cellsToProcess.find((item) => {
            return (
              parseInt(item.pk_Timeslot) == parseInt(timeSlot) &&
              parseInt(item.SortOrder) == parseInt(scheduleBlock.SortOrder)
            );
          }),
        );
        // cellsToProcessCount--;
      });

      //   scheduleBlockIndex++;
      // }
      newRowForBody[h] = rowCell;

      rowTracker[timeSlot] = currentRowToFill;
      columnTracker[h] = currentColumnToFill;
      newBody[rowIndex] = newRowForBody;
      processedScheduleData.body = clone(newBody);
      newTrackers = trackExistingCandidates(newBody, rowTracker, columnTracker, headersToFillUp);

      rowTracker = newTrackers.newRowTracker;
      columnTracker = newTrackers.newColumnTracker;

      potentialCandidateTracker = processAvailableCandidatesPerCell({
        scheduleData: processedScheduleData,
        headersToFillUp,
        trackers: { rowTracker, columnTracker },
        candidateKeys: extractCandidateIds(candidates),
        interviewCountTracker,
        options,
        potentialCandidateTracker,
      });

      cellsToProcess = potentialCandidateTracker.cellPossibleCandidateCounts[h].filter((item) => {
        return processedCells.indexOf(item.pk_Timeslot) < 0;
      });

      cellsToProcessCount = cellsToProcess.filter((item) => {
        return !processedCells.find((processedItem) => {
          return (
            parseInt(item.pk_Timeslot) == parseInt(processedItem.pk_Timeslot) &&
            parseInt(item.SortOrder) == parseInt(processedItem.SortOrder)
          );
        });
      }).length;

      // cellPossibleCandidateCounts[h].filter((item) => {
      //   return processedCells.indexOf(item.pk_Timeslot) < 0;
      // });
    }
  });

  let debuggerText = '';
  Object.keys(debuggerRowTracker).forEach((rowId) => {
    debuggerText += `${rowId} - |`;
    const row = debuggerRowTracker[rowId];
    columnsToFill.forEach((column) => {
      if (row[column]) {
        row[column].forEach((candidate) => {
          debuggerText += `${candidate ? candidate : '--'}`;
        });
      }
      debuggerText += '|';
    });

    debuggerText += '\n|';
  });

  return {
    newBody,
    debuggerText,
    rowTracker,
    columnTracker,
    clearTable,
    headersToFillUp,
    pendingItems,
    interviewCountTracker,
  };
};

export const getRandomItemsFromList = (numberOfItemsToGet, itemList) => {
  let counter = 0;
  let itemsToRandom = itemList.slice();
  let selectedRandomItems = [];

  while (counter < numberOfItemsToGet) {
    const itemIndex = Math.floor(Math.random() * itemsToRandom.length);

    selectedRandomItems.push(itemsToRandom[itemIndex]);
    itemsToRandom.splice(itemIndex, 1);
    counter++;
  }

  return selectedRandomItems;
};

const addCandidateKeysListSpacers = (candidateKeys, columnsToFill) => {
  let newCandidateKeys = clone(candidateKeys);
  let extraCounter = columnsToFill.length - newCandidateKeys.length;

  while (extraCounter > 0) {
    newCandidateKeys.push(`space_${extraCounter}`);
    extraCounter--;
  }
  // newCandidateKeys = shuffleItems(newCandidateKeys);

  return newCandidateKeys;
};

const removeSpacersFromCandidateKeysList = (candidateKeys) => {
  const newCandidateKeys = clone(candidateKeys);

  candidateKeys.forEach((key) => {
    if (key.indexOf('space_') >= 0) {
      newCandidateKeys.splice(newCandidateKeys.indexOf(key), 1);
    }
  });

  return newCandidateKeys;
};

// swaps a candidate in a selected cell with another candidate. Removes any and all duplicates of the new candidate along selected cell's row and column. Returns blocks to be cleared to complete the replacement.
// candidate - candidate to be swapped OUT, this is existing candidate
export const getsBlocksToClearForSwap = (data) => {
  const { candidate, scheduleDataRef, trackers = {}, selectedScheduleBlock } = data;
  const { splitBlockCandidateTracker = {} } = trackers;
  const { body = [], headers } = scheduleDataRef || {};
  const { rowOfDuplicate, columnOfDuplicate, pk_Candidate } = candidate;
  const blocksToClear = [];

  if (rowOfDuplicate) {
    // Duplicate along row.
    const rowDuplicateIndex = body.findIndex((t) => {
      return t.pk_Timeslot == rowOfDuplicate;
    });
    const rowDuplicate = body[rowDuplicateIndex];

    const splitData = splitBlockCandidateTracker[pk_Candidate] || [];
    const blockStart = moment(selectedScheduleBlock ? selectedScheduleBlock.slot : rowDuplicate.slot);
    const blockEnd = moment(selectedScheduleBlock ? selectedScheduleBlock.slotEnd : rowDuplicate.slotEnd);

    const candidateInstancesInRow = splitData.filter((d) => {
      const { pk_Timeslot } = d;
      return pk_Timeslot == rowOfDuplicate;
    });

    candidateInstancesInRow.forEach((c) => {
      // console.log('c: ');
      const existingBlockStart = moment(c.slot);
      const existingBlockEnd = moment(c.slotEnd);

      const hasTimeRangeOverlap = isTimeRangeOverlap([blockStart, blockEnd], [existingBlockStart, existingBlockEnd]);

      if (hasTimeRangeOverlap) {
        blocksToClear.push({
          pk_ScheduleBlock: c.pk_ScheduleBlock,
          rowIndex: rowDuplicateIndex,
          column: c.pk_ScheduleAssignment,
        });
      }
    });
  }

  if (columnOfDuplicate) {
    // Duplicate along column
    const cellsInGuiltyColumn = body.map((t) => {
      return t[columnOfDuplicate];
    });
    let columnDuplicateScheduleBlock;

    cellsInGuiltyColumn.forEach((c, i) => {
      const blocks = c.ScheduleBlockEntries || [];
      blocks.forEach((b) => {
        if (b.Candidates) {
          const { Candidates } = b;
          Candidates.forEach((c) => {
            if (c.pk_Candidate == candidate.pk_Candidate) {
              columnDuplicateScheduleBlock = {
                pk_ScheduleBlock: b.pk_ScheduleBlock,
                rowIndex: i,
                column: c.fk_ScheduleAssignment,
              };

              // columnDuplicateScheduleBlock = b.pk_ScheduleBlock;
            }
          });
        }
      });
    });
    blocksToClear.push(columnDuplicateScheduleBlock);
  }

  const dataToReturn = { blocksToClear };
  return dataToReturn;
};

export const swapAllIntancesOfCandidate = (data) => {
  const {
    scheduleData,
    oldCandidate,
    newCandidate,
    trackers,
    ignoreInterviewLimit,
    pendingCandidates,
    candidateLimit,
    candidates,
  } = data;

  const { body = [], headers } = scheduleData;
  const { pk_Candidate } = oldCandidate;
  const { pk_Candidate: newCandidateId } = newCandidate;
  let blocksToClear = [];
  const newBody = clone(body);

  const { scheduleBlockTracker = {}, splitBlockCandidateTracker } = trackers;
  const rowsWithGhosts = []; // get rows with ghostCandidate via the splitBlockCandidateTracker (pk_Candidates with blocks), run through rows, feed to getBlocksToClear. Put blocks to clear in blocksToClear, then feed to save scheduleData, after which add candidate to all blocks in blocksToClear.

  const blocksWithGhosts = splitBlockCandidateTracker[pk_Candidate] || [];

  blocksWithGhosts.forEach((r) => {
    const newTrackers = setupTrackers({ headers: scheduleData.headers, body: newBody });
    const rowIndex = newBody.findIndex((b) => {
      return b.pk_Timeslot == r.pk_Timeslot;
    });

    // Clear scheduleBlocks with ghosts.
    rowsWithGhosts.push({
      rowIndex: rowIndex,
      column: r.pk_ScheduleAssignment,
      pk_ScheduleBlock: r.pk_ScheduleBlock,
    });

    const availableCandidatesForBlock = getAvailableCandidates({
      row: newBody[rowIndex],
      column: {
        rawHeader: headers.find((h) => {
          return h.pk_ScheduleAssignment == r.pk_ScheduleAssignment;
        }),
      },
      selectedScheduleBlock: r,
      trackers: newTrackers,
      candidates: candidates,
      candidateLimit: candidateLimit,
      pendingCandidates: pendingCandidates,
      ignoreInterviewLimit,
      bodyReference: newBody,
    });

    const candidateToAdd = availableCandidatesForBlock.find((c) => {
      return c.pk_Candidate == newCandidateId;
    });

    // get conflict/duplicate blocks for each row/column.
    const newBlocksToClear = getsBlocksToClearForSwap({
      candidate: candidateToAdd,
      newCandidate,
      scheduleDataRef: scheduleData,
      trackers: newTrackers,
      selectedScheduleBlock: r,
    });

    // Clear everything that needs to clear

    blocksToClear = [...blocksToClear, ...newBlocksToClear.blocksToClear];

    blocksToClear.forEach((block) => {
      const { rowIndex, column, pk_ScheduleBlock } = block;
      newBody[rowIndex][column].ScheduleBlockEntries = newBody[rowIndex][column].ScheduleBlockEntries.map((b) => {
        if (b.pk_ScheduleBlock == pk_ScheduleBlock) {
          b.Candidates = [];
        }

        return b;
      });
    });
    blocksToClear = [];
  });

  //Plug in new candidate to blocksToClear. Just run through rowsWithCandidates and replace, plug to /generate.

  // Replace all ghosts with new candidate.
  rowsWithGhosts.forEach((row) => {
    const { rowIndex, column, pk_ScheduleBlock } = row;

    newBody[rowIndex][column].ScheduleBlockEntries = newBody[rowIndex][column].ScheduleBlockEntries.map((b) => {
      if (b.pk_ScheduleBlock == pk_ScheduleBlock) {
        b.Candidates = [newCandidate];
      }

      return b;
    });
  });

  return newBody;
};

export const getAvailableCandidates = (data) => {
  const {
    row,
    column = {},
    selectedScheduleBlock,
    trackers,
    candidates,
    candidateLimit,
    pendingCandidates,
    ignoreInterviewLimit = false,
  } = data;

  const { rowTracker, columnTracker, splitBlockCandidateTracker, splitCountTracker } = trackers;
  const availableCandidates = JSON.parse(JSON.stringify(Object.values(candidates)));
  const invalidCandidates = [];
  const currentCandidates = [];
  const maxScheduleLimit = candidateLimit;

  let pendingItemKey = '';

  // Delete candidates already in block.
  const cellCandidates =
    selectedScheduleBlock && selectedScheduleBlock.Candidates ? selectedScheduleBlock.Candidates : [];

  cellCandidates.map((candidate) => {
    const availableCandidate =
      availableCandidates[
        availableCandidates.findIndex((c) => {
          return c.pk_Candidate === candidate.pk_Candidate;
        })
      ];

    if (!availableCandidate) {
      return;
    }
    const newCandidate = JSON.parse(JSON.stringify(availableCandidate));

    newCandidate.type = 'current';
    currentCandidates.push(newCandidate);
    const guiltyIndex = availableCandidates.findIndex((c) => {
      return c.pk_Candidate === newCandidate.pk_Candidate;
    });
    availableCandidates.splice(guiltyIndex, 1);
  });

  const candidatesInFlexEvent = identifyCandidatesInFlexEventsForBlock({
    selectedCell: {
      row,
      column,
    },
    candidates: availableCandidates,
  });
  const cell = row[parseInt(column.dataField)];
  const blockStart = moment(selectedScheduleBlock ? selectedScheduleBlock.slot : row.slot);
  const blockEnd = moment(selectedScheduleBlock ? selectedScheduleBlock.slotEnd : row.slotEnd);
  // =================== calc row/column duplication. ============//
  let tempAvailableCandidates = availableCandidates.slice();
  tempAvailableCandidates.forEach((candidate) => {
    const { pk_Candidate } = candidate;
    const splitData = splitBlockCandidateTracker[pk_Candidate] || [];

    const candidateInstancesInRow = splitData.filter((d) => {
      const { pk_Timeslot } = d;
      return pk_Timeslot == row.pk_Timeslot;
    });

    let hasSplitConflict = false;
    candidateInstancesInRow.forEach((c) => {
      const existingBlockStart = moment(c.slot);
      const existingBlockEnd = moment(c.slotEnd);

      const hasTimeRangeOverlap = isTimeRangeOverlap([blockStart, blockEnd], [existingBlockStart, existingBlockEnd]);

      if (hasTimeRangeOverlap) {
        hasSplitConflict = true;
      }
    });

    // TODO: Check instances if all are split cells, then check if in conflict with THIS schedule block.
    // Find Schedule block where candidate occupies entire timeslot for this row.
    const isInBlockForEntireTimeSlot = splitData.find((sd) => {
      const { ColumnTotalNumberOfSplits, ScheduleBlockTotalNumberOfSplits, pk_Timeslot } = sd;
      return !ColumnTotalNumberOfSplits && !ScheduleBlockTotalNumberOfSplits && pk_Timeslot == row.pk_Timeslot;
    });

    const isInRow = hasSplitConflict || isInBlockForEntireTimeSlot;

    const isInColumn =
      column.rawHeader &&
      columnTracker[column.rawHeader.pk_ScheduleAssignment] != null &&
      columnTracker[column.rawHeader.pk_ScheduleAssignment].includes(parseInt(pk_Candidate));

    candidate.isDuplicatePosition = isInRow || isInColumn;
    const candidateIsInFlexEvent = candidatesInFlexEvent.includes(pk_Candidate);
    if (isInRow) {
      candidate.rowOfDuplicate = row.pk_Timeslot;
      candidate.isInRow = isInRow;
    }

    if (isInColumn) {
      candidate.columnOfDuplicate = column.rawHeader.pk_ScheduleAssignment;
      candidate.isInColumn = isInColumn;
    }

    if (hasSplitConflict) {
      candidate.hasSplitConflict = true;
    }

    if (
      isInRow ||
      isInColumn ||
      hasSplitConflict ||
      (maxScheduleLimit <= candidate.CountOfScheduleInterviews && !ignoreInterviewLimit) ||
      candidateIsInFlexEvent
    ) {
      candidate.color = 'gray';
      candidate.type = 'invalid';

      invalidCandidates.push(candidate);
      const guiltyIndex = availableCandidates.findIndex((c) => {
        return c.pk_Candidate === candidate.pk_Candidate;
      });
      availableCandidates.splice(guiltyIndex, 1);
    }
  });
  // ===================================================================//

  tempAvailableCandidates = availableCandidates.slice();

  // check row for pending candidates
  Object.keys(columnTracker).forEach((key) => {
    pendingItemKey = `${row.pk_Timeslot}_${key}`;
    const pendingKeysInThisCell =
      Object.keys(pendingCandidates).filter((key) => {
        return key.indexOf(`${pendingItemKey}_`) >= 0;
      }) || [];

    const pendingItemsInThisCell = pendingKeysInThisCell.map((key) => {
      return pendingCandidates[key];
    });

    pendingItemsInThisCell.forEach((candidatesActual) => {
      const candidateActual = candidatesActual[0];
      let guiltyIndex = availableCandidates.findIndex((item) => {
        return item.pk_Candidate == candidateActual.pk_Candidate;
      });

      if (guiltyIndex >= 0) {
        availableCandidates.splice(guiltyIndex, 1);
      }

      guiltyIndex = invalidCandidates.findIndex((item) => {
        return item.pk_Candidate == candidateActual.pk_Candidate;
      });

      if (guiltyIndex < 0) {
        candidateActual.color = 'gray';
        candidateActual.type = 'invalid';
        invalidCandidates.push(candidateActual);
      }
    });
  });

  Object.keys(rowTracker).forEach((key) => {
    pendingItemKey = `${key}_${column.dataField}`;
    const pendingKeysInThisCell =
      Object.keys(pendingCandidates).filter((key) => {
        return key.indexOf(`${pendingItemKey}_`) >= 0;
      }) || [];

    const pendingItemsInThisCell = pendingKeysInThisCell.map((key) => {
      return pendingCandidates[key];
    });

    pendingItemsInThisCell.forEach((candidatesActual) => {
      const candidateActual = candidatesActual[0];
      let guiltyIndex = availableCandidates.findIndex((item) => {
        return item.pk_Candidate == candidateActual.pk_Candidate;
      });

      if (guiltyIndex >= 0) {
        availableCandidates.splice(guiltyIndex, 1);
      }

      guiltyIndex = invalidCandidates.findIndex((item) => {
        return item.pk_Candidate == candidateActual.pk_Candidate;
      });

      if (guiltyIndex < 0) {
        candidateActual.color = 'gray';
        candidateActual.type = 'invalid';
        invalidCandidates.push(candidateActual);
      }
    });
  });

  return [...currentCandidates, ...availableCandidates, ...invalidCandidates];
};

const identifyCandidatesInFlexEventsForBlock = (data) => {
  const { selectedCell, candidates } = data;

  const availableCandidates = JSON.parse(JSON.stringify(candidates));
  const newCandidatesInFlexEvents = [];
  const dataField = selectedCell.column.dataField;
  const cellFlexEvents = selectedCell.row[dataField] ? selectedCell.row[dataField].FlexEvents || [] : [];
  availableCandidates.forEach((candidate) => {
    const candidateIsInFlexEventForThisCell = cellFlexEvents.find((f) => {
      let hasCandidate = false;
      f.AttendingCandidates.forEach((c) => {
        if (c.pk_Candidate == candidate.pk_Candidate) {
          hasCandidate = true;
        }
      });

      return hasCandidate;
    });

    if (candidateIsInFlexEventForThisCell && !newCandidatesInFlexEvents.includes(candidate.pk_Candidate)) {
      newCandidatesInFlexEvents.push(candidate.pk_Candidate);
    }
  });

  return newCandidatesInFlexEvents;
};
// Creates object with contains data for candidate scheduling.
// Contains:
// 1. cellValues - object with keys of pk_ScheduleAssignments. pk_ScheduleAssignments point to objects with keys of pk_TimeSlot, and array of viable candidate id's for that cell.
// 2. candidatePossiblePlacementCounts - object with key of candidate id, and value of number of possible placements
// 3. cellPossibleCandidateCounts - object with key of pk_ScheduleAssignments, with array object with pk_TimeSlot and count of viable candidates. Basically lists all cells in a column, and the number of viable candidates for each cell
// 4. viableCandidates - object with key of pk_ScheduleAssignments, with array of candidate ids that are viable for that column
const processAvailableCandidatesPerCell = (data) => {
  const {
    scheduleData,
    headersToFillUp, // if null, assume we are to process ENTIRE table
    trackers = {},
    candidateKeys,
    interviewCountTracker = {},
    options,
    potentialCandidateTracker = {},
  } = data;

  const { body, headers } = scheduleData;

  // If no trackers, then make trackers.
  const {
    rowTracker = extractTimeSlotIds(JSON.parse(JSON.stringify(body))),
    columnTracker = extractHeaderIds(headers),
  } = trackers;

  const newPotentialCandidateTracker = clone(potentialCandidateTracker);

  const cellValues = {};
  const candidatePossiblePlacementCounts = {};

  const viableCandidates = {};
  const cellPossibleCandidateCounts = {}; // array of objects with pk_Timeslot, and possibleCandidateCount

  headersToFillUp.forEach((h) => {
    Object.keys(rowTracker).forEach((r, i) => {
      if (r === 'END') {
        return;
      }
      const cellToProcess = body.find((item) => {
        return parseInt(item.pk_Timeslot) === parseInt(r);
      })[h];
      const { ScheduleBlockEntries } = cellToProcess;
      ScheduleBlockEntries.forEach((scheduleBlock) => {
        const blockIndex = scheduleBlock.SortOrder;
        const viableCandidatesForBlock = [];
        candidateKeys.forEach((c) => {
          const isFreeCandidate = canStillAddInterview(c, interviewCountTracker, scheduleData, options);

          // const cellFlexEvents = selectedCell.row[selectedCell.column.dataField].FlexEvents || [];
          // const candidateIsInFlexEventForThisCell = cellFlexEvents.find((f) => {
          //   let hasCandidate = false;
          //   f.AttendingCandidates.forEach((c) => {
          //     if (c.pk_Candidate == candidate.pk_Candidate) {
          //       hasCandidate = true;
          //     }
          //   });

          //   return hasCandidate;
          // });
          const { flexEventCandidates = [] } = scheduleBlock;
          let candidateIsInFlexEventForThisCell = flexEventCandidates.find((f) => {
            let hasCandidate = false;
            flexEventCandidates.forEach((flexCandidate) => {
              if (flexCandidate.pk_Candidate == c) {
                hasCandidate = true;
              }
            });

            return hasCandidate;
          });
          // Check if candidate doesn't exist yet in row and column, and if can still add interview.
          if (
            rowTracker[r].indexOf(c) < 0 &&
            columnTracker[h].indexOf(c) < 0 &&
            isFreeCandidate &&
            !candidateIsInFlexEventForThisCell &&
            cellToProcess.cellState &&
            cellToProcess.cellState.toLowerCase() !== 'unavailable'
          ) {
            if (viableCandidatesForBlock && !viableCandidatesForBlock.includes(c)) {
              viableCandidatesForBlock.push(c);
              if (candidatePossiblePlacementCounts[h] == null) {
                candidatePossiblePlacementCounts[h] = {};
              }
              if (candidatePossiblePlacementCounts && candidatePossiblePlacementCounts[h][c] != null) {
                const newData = candidatePossiblePlacementCounts[h][c];
                newData.count += 1;

                candidatePossiblePlacementCounts[h][c] = newData;
              } else {
                const newData = { pk_Candidate: c, count: 1 };
                candidatePossiblePlacementCounts[h][c] = newData;
              }
              if (viableCandidates[h] == null) {
                viableCandidates[h] = [];
              }
              if (!viableCandidates[h].includes(c)) {
                viableCandidates[h].push(c);
              }
            }
          }
        });
        if (cellValues[h] == null) {
          cellValues[h] = {};
        }

        if (cellValues[h][r] == null) {
          cellValues[h][r] = [];
        }

        if (!cellPossibleCandidateCounts[h]) {
          cellPossibleCandidateCounts[h] = [];
        }

        cellPossibleCandidateCounts[h].push({
          pk_Timeslot: r,
          SortOrder: blockIndex,
          count: viableCandidatesForBlock.length,
        });

        cellValues[h][r][blockIndex] = viableCandidatesForBlock;
      });
    });

    // SORT cellPossibleCandidateCounts h-arrays by ascending.
    Object.keys(cellValues).forEach((h) => {
      Object.keys(cellValues[h]).forEach((r) => {
        cellPossibleCandidateCounts[h].sort((a, b) => {
          return parseInt(a.count) - parseInt(b.count);
        });
      });
    });
  });

  // Sort candidatePossiblePlacementCounts h-arrays by ascending.
  Object.keys(candidatePossiblePlacementCounts).forEach((h) => {
    candidatePossiblePlacementCounts[h] = Object.values(candidatePossiblePlacementCounts[h]).sort((a, b) => {
      return parseInt(a.count) - parseInt(b.count);
    });
  });

  newPotentialCandidateTracker.cellValues = clone(cellValues);
  newPotentialCandidateTracker.cellPossibleCandidateCounts = clone(cellPossibleCandidateCounts);
  newPotentialCandidateTracker.candidatePossiblePlacementCounts = clone(candidatePossiblePlacementCounts);
  newPotentialCandidateTracker.viableCandidates = viableCandidates;
  return newPotentialCandidateTracker;
};

export const extractHeaderIds = (headers) => {
  const headerObject = {};
  headers.forEach((header) => {
    headerObject[header.pk_ScheduleAssignment] = [];
  });
  return headerObject;
};

const extractTimeSlotIds = (timeSlots) => {
  const timeSlotsObject = {};
  const newTimeSlots = timeSlots.slice().sort((item1, item2) => {
    return item1.SortOrder - item2.SortOrder;
  });
  newTimeSlots.forEach((slot) => {
    // if (slot.pk_Timeslot === 'END') {
    //   return;
    // }
    timeSlotsObject[slot.pk_Timeslot] = [];
  });
  return timeSlotsObject;
};

const extractCandidateIds = (candidates) => {
  return Object.keys(candidates);
};

/*
 *Finds candidate pk's avaialble for row. Basically fake generates schedule for row. Considers existing/assigned candidates when fake-generating. Used to spread out candidates across row when # of viable candidates for row is less than count of headers/columns.
 *
 * Used for when Flex A covers half the schedule and has half candidates, and Flex B has the other half of schedule and candidates.  */
export const getAvailableCandidatesForRow = (data) => {
  const {
    timeSlotRow,
    candidateKeys,
    currentRowToFill,
    columnsToFill,
    columnTracker,
    interviewCountTracker,
    scheduleData,
    options,
    showLogs,
  } = data;

  const tempColumnTracker = clone(columnTracker);
  const tempCurrentRowToFill = clone(currentRowToFill);

  const tempInterviewCountTracker = clone(interviewCountTracker);
  let candidateIndex = Math.floor(Math.random() * candidateKeys.length);

  columnsToFill.forEach((column) => {
    const rowCell = timeSlotRow[column];
    const { ScheduleBlockTotalNumberOfSplits, ColumnTotalNumberOfSplits } = rowCell || {};

    const scheduleBlockCount =
      ScheduleBlockTotalNumberOfSplits != null
        ? ScheduleBlockTotalNumberOfSplits
        : ColumnTotalNumberOfSplits != null
        ? ColumnTotalNumberOfSplits
        : 1;

    let scheduleBlockIndex = 0;

    const currentColumnToFill = tempColumnTracker[column];
    while (scheduleBlockIndex < scheduleBlockCount) {
      let currentCandidateToAdd = candidateKeys[candidateIndex];
      let scheduleAttempts = candidateKeys.length;
      let isFreeCandidate = canStillAddInterview(
        currentCandidateToAdd,
        tempInterviewCountTracker,
        scheduleData,
        options,
      );

      const scheduleBlock = rowCell.ScheduleBlockEntries[scheduleBlockIndex];
      const flexEventCandidates = (scheduleBlock && scheduleBlock.flexEventCandidates) || [];

      if (
        (rowCell && rowCell.isFlexEvent) ||
        // (headersToFillUp.length > 0 && headersToFillUp.indexOf(parseInt(column)) < 0) ||
        timeSlotRow.CustomMeetingTitle ||
        timeSlotRow.pk_Timeslot === 'END'
      ) {
        scheduleAttempts = -1;
      } else {
        let candidateIsInFlexEventForThisCell = flexEventCandidates.find((f) => {
          let hasCandidate = false;
          flexEventCandidates.forEach((c) => {
            if (c.pk_Candidate == currentCandidateToAdd) {
              hasCandidate = true;
            }
          });

          return hasCandidate;
        });

        // NOTE: Questionable use of tempCurrentRowToFill in while condition.
        // While loop doesn't reject candidates that exist in row. -Tere
        while (
          (currentColumnToFill.indexOf(currentCandidateToAdd) >= 0 ||
            tempCurrentRowToFill.indexOf(currentCandidateToAdd) >= 0 ||
            !isFreeCandidate ||
            candidateIsInFlexEventForThisCell) &&
          scheduleAttempts >= 0
        ) {
          candidateIndex++;
          if (candidateIndex >= candidateKeys.length) {
            candidateIndex = 0;
          }
          currentCandidateToAdd = candidateKeys[candidateIndex];

          scheduleAttempts--;

          isFreeCandidate = canStillAddInterview(currentCandidateToAdd, interviewCountTracker, scheduleData, options);
          candidateIsInFlexEventForThisCell = flexEventCandidates.find((f) => {
            let hasCandidate = false;
            flexEventCandidates.forEach((c) => {
              if (c.pk_Candidate == currentCandidateToAdd) {
                hasCandidate = true;
              }
            });

            return hasCandidate;
          });
        }

        if (scheduleAttempts >= 0 && currentCandidateToAdd != null) {
          currentColumnToFill.push(currentCandidateToAdd);
          tempCurrentRowToFill.push(currentCandidateToAdd);
          if (tempInterviewCountTracker[currentCandidateToAdd] == null) {
            tempInterviewCountTracker[currentCandidateToAdd] = 0;
          }
          tempInterviewCountTracker[currentCandidateToAdd] += 1;
        } else {
        }
      }
      if (scheduleAttempts >= candidateKeys.length) {
        candidateIndex++;
      }

      if (candidateIndex >= candidateKeys.length) {
        candidateIndex = 0;
      }
      scheduleBlockIndex++;
    }
  });

  return tempCurrentRowToFill;
};

/**
 * Gets a list of columns and returns columns that are not fully blocked by Unavailables.
 */
const getViableColumns = (data) => {
  const { scheduleData, options, candidates, rowTracker, columnTracker } = data;

  const { headers } = scheduleData;

  const headerObject = extractHeaderIds(headers);
  const candidateKeys = extractCandidateIds(candidates);

  Object.keys(headerObject).forEach((header) => {
    columnTracker[header] = [];
  });

  const viableColumns = [];

  const availableCandidatesPerColumn = processAvailableCandidatesPerCell({
    scheduleData,
    headersToFillUp: headers.map((h) => {
      return h.pk_ScheduleAssignment;
    }), // if null, assume we are to process ENTIRE table
    trackers: { rowTracker: clone(rowTracker), columnTracker: clone(columnTracker) },
    candidateKeys,
    interviewCountTracker: {},
    options,
    potentialCandidateTracker: {},
  });

  return Object.keys(availableCandidatesPerColumn.candidatePossiblePlacementCounts);
};
/**
 * Totals candidates' CountOfScheduleInterviews values.
 * @param {array} candidates
 */
export const countScheduledCandidates = (candidates) => {
  let scheduledCandidateCount = 0;

  candidates.forEach((candidate) => {
    scheduledCandidateCount += candidate.CountOfScheduleInterviews || 0;
  });

  return scheduledCandidateCount;
};

const shuffleItems = (items = []) => {
  const newItems = [...items];
  let currentIndex = items.length;
  let tempValue,
    randomIndex = null;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    tempValue = newItems[currentIndex];
    newItems[currentIndex] = newItems[randomIndex];
    newItems[randomIndex] = tempValue;
  }
  return newItems;
};

export const isTimeRangeOverlap = (timeRange1 = [], timeRange2 = []) => {
  const timeRangeStart1 = moment(timeRange1[0]);
  const timeRangeEnd1 = moment(timeRange1[1]);

  const timeRangeStart2 = moment(timeRange2[0]);
  const timeRangeEnd2 = moment(timeRange2[1]);

  let isOverlap = false;
  if (
    (timeRangeStart1.isSameOrAfter(timeRangeStart2) && timeRangeStart1.isBefore(timeRangeEnd2)) || // Event start is between rowstart and rowend
    (timeRangeEnd1.isAfter(timeRangeStart2) && timeRangeEnd1.isBefore(timeRangeEnd2)) || // event end is between rowstart and row end
    (timeRangeStart1.isSameOrBefore(timeRangeStart2) && timeRangeEnd1.isSameOrAfter(timeRangeEnd2)) // rowstart and row end is within event start and end
  ) {
    isOverlap = true;
  }

  return isOverlap;
};

export const clearTimeslots = (slots, clearEventsToo, absolute) => {
  const newTimeslots = [];
  const eventsToDelete = [];
  const scheduleBlockKeys = ['slot', 'slotEnd', 'SortOrder', 'isFlexEvent', 'flexEventCandidates'];

  slots.forEach((slot) => {
    const newSlot = clone(slot);

    Object.keys(slot).forEach((key) => {
      if (slot[key] != null && slot[key].ScheduleBlockEntries) {
        const newBlockEntries = [];

        if (absolute) {
          newSlot[key].ScheduleBlockEntries = newBlockEntries;
        } else {
          newSlot[key].ScheduleBlockEntries.forEach((scheduleBlockEntry) => {
            const newBlock = {};
            scheduleBlockKeys.forEach((key) => {
              newBlock[key] = scheduleBlockEntry[key];
            });
            newBlock.Candidates = [];
            newBlockEntries.push(newBlock);
          });
          newSlot[key].ScheduleBlockEntries = newBlockEntries;
        }
      }
    });
    if (clearEventsToo && slot.CustomMeetingTitle) {
      // ADD logic to add events to eventsToDelete
      eventsToDelete.push(slot.pk_Timeslot);
    }
    newTimeslots.push(newSlot);
  });

  if (clearEventsToo) {
    return { newSchedule: newTimeslots, eventsToDelete };
  } else {
    return newTimeslots;
  }
};

export const clearColumns = (scheduleData, columnsToClear) => {
  const { body = [], headers = [] } = scheduleData;
  let headerKeys = Object.keys(extractHeaderIds(headers));
  if (columnsToClear) {
    headerKeys = headerKeys.filter((key) => {
      return columnsToClear.includes(key);
    });
  }
  body.forEach((timeSlotRow) => {
    headerKeys.forEach((pk_ScheduleAssignment) => {
      const cell = timeSlotRow[pk_ScheduleAssignment];
      if (cell.ScheduleBlockEntries) {
        cell.ScheduleBlockEntries.forEach((scheduleBlock) => {
          scheduleBlock.Candidates = [];
        });
      }
    });
  });
  scheduleData.body = body;
  return scheduleData;
};

export const clearScheduleOfCandidates = (data) => {
  const { candidatesToClear = [], scheduleData } = data;

  const { body = [], headers = [] } = scheduleData;
  const headerKeys = headers.map((h) => {
    return h.pk_ScheduleAssignment;
  });
  const newBody = clone(body);

  newBody.forEach((timeSlotRow) => {
    headerKeys.forEach((pk_ScheduleAssignment) => {
      const cell = timeSlotRow[pk_ScheduleAssignment];
      if (cell.ScheduleBlockEntries) {
        cell.ScheduleBlockEntries.forEach((scheduleBlock) => {
          const { Candidates = [] } = scheduleBlock;
          scheduleBlock.Candidates = Candidates.filter((candidate) => {
            return !candidatesToClear.includes(candidate.pk_Candidate);
          });
        });
      }
    });
  });

  // return scheduleData;
  return { ...scheduleData, body: newBody };
};

// Checks if Candidate's candidate count is below limit.
const canStillAddInterview = (candidateId, interviewCountTracker, scheduleData, options) => {
  const maxCount = options.allowInterviewLimit
    ? options.interviewLimit > scheduleData.headers.length
      ? scheduleData.headers.length
      : options.interviewLimit
    : scheduleData.headers.length;
  const currentCount = interviewCountTracker[candidateId] != null ? interviewCountTracker[candidateId] : 0;

  return currentCount < maxCount;
};

export const trackExistingCandidates = (body, rowTracker, columnTracker, headersToFillUp) => {
  const newRowTracker = clone(rowTracker);
  const newColumnTracker = clone(columnTracker);

  body.forEach((row) => {
    const { pk_Timeslot } = row;
    // if (pk_Timeslot === 'END') {
    //   return;
    // }
    if (newRowTracker[pk_Timeslot] == null) {
      newRowTracker[pk_Timeslot] = [];
    }

    Object.keys(newColumnTracker).forEach((pk_ScheduleAssignment) => {
      const column = row[pk_ScheduleAssignment];

      if (newColumnTracker[pk_ScheduleAssignment] == null) {
        newColumnTracker[pk_ScheduleAssignment] = [];
      }

      // Maintain column to populate as empty in the tracker,
      // so it would overwrite everything. Remove this if
      // filling up gaps in a column with pre-existing candidates.
      if (headersToFillUp.indexOf(pk_ScheduleAssignment) >= 0) {
        return;
      }

      if (column && column.ScheduleBlockEntries) {
        column.ScheduleBlockEntries.forEach((scheduleBlock) => {
          if (scheduleBlock.Candidates) {
            scheduleBlock.Candidates.forEach((candidate) => {
              const { pk_Candidate } = candidate;
              newRowTracker[pk_Timeslot].push(pk_Candidate.toString());
              newColumnTracker[pk_ScheduleAssignment].push(pk_Candidate.toString());
            });
          }
        });
      } else {
        // Assume empty space.
        // newRowTracker[pk_Timeslot].push(null);
        // newColumnTracker[pk_ScheduleAssignment].push(null);
      }
    });
  });

  return { newRowTracker, newColumnTracker };
};

export const clone = (toClone) => {
  if (toClone) {
    return JSON.parse(JSON.stringify(toClone));
  } else {
    return null;
  }
};

export const setupSplitCountTrackers = (scheduleData) => {
  const { headers, body = [] } = scheduleData;

  const trackers = {}; // keys should be 'x_<pk_Timeslot>_<pk_ScheduleAssignment>_y'

  if (headers == null || body == null) {
    return trackers;
  }
  const headerKeys = headers.map((header) => {
    return header.pk_ScheduleAssignment;
  });
  body.forEach((row) => {
    headerKeys.forEach((headerKey) => {
      const cell = row[headerKey];
      const trackerKey = `x_${row.pk_Timeslot}_${headerKey}_y`;

      if (cell) {
        const splitCount =
          cell.ScheduleBlockTotalNumberOfSplits != null
            ? cell.ScheduleBlockTotalNumberOfSplits
            : cell.ColumnTotalNumberOfSplits;
        let highestSortOrder = 0;
        const { ScheduleBlockEntries = [] } = cell;
        ScheduleBlockEntries.forEach((scheduleBlock) => {
          if (
            scheduleBlock.Candidates &&
            scheduleBlock.Candidates.length > 0 &&
            scheduleBlock.SortOrder > highestSortOrder
          ) {
            highestSortOrder = scheduleBlock.SortOrder;
          }
        });
        trackers[trackerKey] = { splitCount, highestSortOrder };
      }
    });
  });
  return trackers;
};

export const setupSplitBlockCandidateTrackers = (scheduleData) => {
  const { headers, body = [] } = scheduleData;

  const trackers = {};

  if (headers == null || body == null) {
    return trackers;
  }
  const headerKeys = headers.map((header) => {
    return header.pk_ScheduleAssignment;
  });
  body.forEach((row) => {
    headerKeys.forEach((headerKey) => {
      const cell = row[headerKey];
      const { ScheduleBlockEntries = [], ScheduleBlockTotalNumberOfSplits, ColumnTotalNumberOfSplits } = cell;

      ScheduleBlockEntries.forEach((scheduleBlock) => {
        const { slot, slotEnd, pk_ScheduleBlock } = scheduleBlock;

        if (scheduleBlock.Candidates && scheduleBlock.Candidates.length > 0) {
          scheduleBlock.Candidates.forEach((candidate) => {
            if (!candidate) {
              return;
            }
            const { pk_Candidate } = candidate;
            const trackerKey = `${pk_Candidate}`;
            const trackerData = trackers[trackerKey] || [];
            trackerData.push({
              pk_Timeslot: row.pk_Timeslot,
              pk_ScheduleAssignment: headerKey,
              SortOrder: scheduleBlock.SortOrder,
              ScheduleBlockTotalNumberOfSplits,
              ColumnTotalNumberOfSplits,
              slot,
              slotEnd,
              pk_ScheduleBlock,
            });
            trackers[trackerKey] = trackerData;
          });
        }
      });
    });
  });

  return trackers;
};
/**
 * Returns true if a candidate is about to be unscheduled because of a Split.
 * @param {Object} trackerData  Object with 'x_<pk_Timeslot>_<pk_ScheduleAssignment>_y' keys used to
 *                              track how many splits a cell have and it's highest assigned SortOrder/Schedule block
 * @param {Object} options      Object with list of affected timeSlots, headers (pk_Timeslot, pk_ScheduleAssignment)
 */
export const isCandidateWillBeUnscheduled = (trackerData, options) => {
  const { timeSlots = [], headers = [], splitValue } = options;
  let willBeUnscheduled = false;
  let xIndex = 0; // for headers
  let yIndex = 0; // for timeslots

  if (timeSlots.length > 0 && headers.length > 0) {
    while (yIndex < timeSlots.length && !willBeUnscheduled) {
      const pk_Timeslot = timeSlots[yIndex];

      while (xIndex < headers.length && !willBeUnscheduled) {
        const pk_ScheduleAssignment = headers[xIndex];

        const details = trackerData[`x_${pk_Timeslot}_${pk_ScheduleAssignment}_y`];
        const { splitCount, highestSortOrder } = details;
        if (highestSortOrder > splitValue - 1) {
          willBeUnscheduled = true;
        }
        xIndex++;
      }
      xIndex = 0;
      yIndex++;
    }
  }
  return willBeUnscheduled;
};

export const addTimeToTimeSlots = (data, timeZone) => {
  const { metaData = {}, headers = [], body = [] } = data || {};
  const newData = clone(data);
  const {
    StandardDurationInMinutes,
    StandardPassingDurationInMinutes,
    StartDateAndTime,
    DateOfInterview,
    StartTime,
  } = metaData;
  const benchmarkStartTime = moment.tz(`${DateOfInterview} ${StartTime}`, null);
  const baseTime = moment.tz(`${DateOfInterview} ${StartTime}`, null);

  const newBody = [];
  const headerKeys = [];

  if (headers == null || headers.length <= 0) {
    newData.headers = [];
  }

  if (body == null || body.length <= 0) {
    newData.body = [];
  }

  if (body.length == 1 && body[0].pk_Timeslot == null) {
    newData.body = [];
  }

  // else {
  headers.forEach((header) => {
    headerKeys.push(header.pk_ScheduleAssignment);
  });

  // Dummy row for End of schedule.
  const endOfTheLine = {
    pk_Timeslot: 'END',
    SortOrder: newData.body.length + 1000,
  };

  headerKeys.forEach((h) => {
    endOfTheLine[h] = { ScheduleBlockEntries: [{}] };
  });

  // endOfTheLine[headerKeys[0]] = { ScheduleBlockEntries: [{}] };
  newData.body.push(endOfTheLine);
  if (newData.body) {
    newData.body.map((row) => {
      const { CustomDurationInMinutes, CustomPassingDurationInMinutes } = row;
      let duration = 0;

      if (CustomDurationInMinutes != null) {
        duration = CustomDurationInMinutes;
      } else {
        duration = StandardDurationInMinutes;
      }

      if (CustomPassingDurationInMinutes != null) {
        duration += CustomPassingDurationInMinutes;
      } else {
        duration += StandardPassingDurationInMinutes;
      }

      row.slot = baseTime.format('MMM DD, YYYY hh:mm A');
      if (row.pk_Timeslot !== 'END') {
        headerKeys.forEach((key) => {
          const baseTimeSchedleBlock = moment(baseTime);
          const { ScheduleBlockEntries } = row[key];
          const blockDuration = duration / row[key].ScheduleBlockEntries.length;
          ScheduleBlockEntries.forEach((scheduleBlock) => {
            scheduleBlock.slot = baseTimeSchedleBlock.format('MMM DD, YYYY hh:mm A');

            baseTimeSchedleBlock.add(blockDuration, 'minutes');
            scheduleBlock.slotEnd = baseTimeSchedleBlock.format('MMM DD, YYYY hh:mm A');
          });
        });
      } else if (row.pk_Timeslot != null && headerKeys.length > 0) {
        const key = headerKeys[0];
        const baseTimeSchedleBlock = moment(baseTime);

        const blockDuration = duration / row[key].ScheduleBlockEntries.length;
        const end = baseTimeSchedleBlock;
        end.add(blockDuration, 'minutes');

        row[key].ScheduleBlockEntries = [
          {
            slot: baseTimeSchedleBlock.format('MMM DD, YYYY hh:mm A'),
            slotEnd: end.format('MMM DD, YYYY hh:mm A'),
          },
        ];
        const timeDurationTotal = baseTime.diff(benchmarkStartTime, 'minutes');
        const timeDurationHours = timeDurationTotal / 60;
        const timeDurationMinutes = timeDurationTotal % 60;

        row.endTimesText = `End of Schedule | Total runtime: ${benchmarkStartTime.format(
          'hh:mm A',
        )} - ${baseTime.format('hh:mm A')} (${Math.trunc(timeDurationHours)} hours ${
          timeDurationMinutes ? `${timeDurationMinutes} minutes` : ''
        })`;
      }

      baseTime.add(duration, 'minutes');
      row.slotEnd = baseTime.format('MMM DD, YYYY hh:mm A');

      newBody.push(row);
    });
    newData.body = newBody;
  }
  // }
  return newData;
};

export const getTimeSlotsWithinTimeInterval = (scheduleData, StartDateAndTime, rowStartTime, rowEndTime) => {
  const timeSlotsWithin = [];

  const monthDate = StartDateAndTime
    ? moment.tz(StartDateAndTime, null).format('MMM DD, YYYY')
    : moment.tz(null, null).format('MMM DD, YYYY');

  scheduleData.body.forEach((timeSlot) => {
    const slotStart = moment.tz(`${monthDate} ${timeSlot.slot}`, null);
    const slotEnd = moment.tz(`${monthDate} ${timeSlot.slotEnd}`, null);

    if (
      (slotStart.isSameOrAfter(rowStartTime) && slotStart.isBefore(rowEndTime)) || // Event start is between rowstart and rowend
      (slotEnd.isAfter(rowStartTime) && slotEnd.isBefore(rowEndTime)) || // event end is between rowstart and row end
      (slotStart.isSameOrBefore(rowStartTime) && slotEnd.isAfter(rowEndTime)) // rowstart and row end is within event start and end
    ) {
      timeSlotsWithin.push(timeSlot);
    }
  });

  return timeSlotsWithin;
};

export const getFlexEventsWithinTimeInterval = (flexEvents = [], StartDateAndTime, rowStartTime, rowEndTime) => {
  const flexEventsWithin = [];
  const monthDate = StartDateAndTime
    ? moment.tz(StartDateAndTime, null).format('MMM DD, YYYY')
    : moment.tz(null, null).format('MMM DD, YYYY');

  flexEvents.forEach((f) => {
    const { FlexStartTime, FlexTimeEnd, StartDateTime, EndDateTime } = f;

    if ((FlexStartTime && FlexTimeEnd) || (StartDateTime && EndDateTime)) {
      const eventStart = moment.tz(`${FlexStartTime || StartDateTime}`, null);
      const eventEnd = moment.tz(`${FlexTimeEnd || EndDateTime}`, null);

      if (
        (eventStart.isSameOrAfter(rowStartTime) && eventStart.isBefore(rowEndTime)) || // Event start is between rowstart and rowend
        (eventEnd.isAfter(rowStartTime) && eventEnd.isBefore(rowEndTime)) || // event end is between rowstart and row end
        (eventStart.isSameOrBefore(rowStartTime) && eventEnd.isSameOrAfter(rowEndTime)) // rowstart and row end is within event start and end
      ) {
        flexEventsWithin.push(f);
      }
    }
  });

  return flexEventsWithin;
};

export const getEvaluatorColumn = (scheduleData = {}, evaluator) => {
  const { headers = [] } = scheduleData;
  let column = null;
  let index = 0;
  while (index < headers.length && column == null) {
    const header = headers[index];
    if (header.Evaluators && header.Evaluators.Evaluators) {
      if (
        header.Evaluators.Evaluators.find((e) => {
          return e.pk_User === evaluator.pk_User;
        })
      ) {
        column = header;
      }
    }
    index++;
  }

  return column;
};

/**
 * Used primarily for attendance looping checker, to check if meeting is in progress.
 * Runs through schedule data timeslots to find the true end of interview date
 * by adding Standard/Custom durations to StartTime. True StartTime is also calculated
 * by setting back start time by EarlyJoinInMinutes minutes.
 * @param {Object} scheduleData
 * @returns {Object} dataToReturn
 */
export const getScheduleStartAndEndTime = (scheduleData, ignoreTimeZone) => {
  let dataToReturn = {};
  if (scheduleData && scheduleData.metaData && scheduleData.metaData.StartDateAndTime) {
    let {
      StandardDurationInMinutes = 0,
      StandardPassingDurationInMinutes = 0,
      EarlyJoinInMinutes = 0,
    } = scheduleData.metaData;

    const scheduleDataStartTime = ignoreTimeZone
      ? moment.tz(scheduleData.metaData.StartDateAndTime, null)
      : moment(scheduleData.metaData.StartDateAndTime);
    const scheduleEndTime = ignoreTimeZone
      ? moment.tz(scheduleData.metaData.StartDateAndTime, null)
      : moment(scheduleData.metaData.StartDateAndTime);
    scheduleDataStartTime.subtract(EarlyJoinInMinutes || 0, 'minutes');
    // scheduleEndTime.add(5, 'minutes');

    if (scheduleData.body) {
      scheduleData.body.forEach((timeSlot, i) => {
        if (i < scheduleData.body.length - 1) {
          if (timeSlot.CustomDurationInMinutes) {
            scheduleEndTime.add(timeSlot.CustomDurationInMinutes, 'minutes');
          } else {
            scheduleEndTime.add(StandardDurationInMinutes, 'minutes');
          }

          if (timeSlot.CustomPassingDurationInMinutes) {
            scheduleEndTime.add(timeSlot.CustomPassingDurationInMinutes, 'minutes');
          } else {
            scheduleEndTime.add(StandardPassingDurationInMinutes, 'minutes');
          }
        }
      });

      dataToReturn = { StartTime: scheduleDataStartTime, EndTime: scheduleEndTime };
    }
  }

  return dataToReturn;
};
// Very long. Don't open.
export const colors = [
  '#A07BEA',
  '#0D9C93',
  '#C29121',
  '#C77CD0',
  '#2AB139',
  '#2EAAA9',
  '#4A6D7F',
  '#041A64',
  '#B2C3E9',
  '#403F17',
  '#9464E0',
  '#D2B402',
  '#3165CC',
  '#A7F6BD',
  '#0FE4F0',
  '#C842D3',
  '#2B5DF6',
  '#037693',
  '#34B2B6',
  '#0B5E04',
  '#FCD3EB',
  '#716CF6',
  '#3FAA54',
  '#D989B4',
  '#FB05A0',
  '#99D7DB',
  '#24C91B',
  '#04AAF0',
  '#FD9865',
  '#C50DAA',
  '#D44BB1',
  '#6E6377',
  '#F8CA3B',
  '#A294AF',
  '#975A4F',
  '#5249D0',
  '#B4E176',
  '#96E601',
  '#2B3B0E',
  '#A95F3A',
  '#F9D32A',
  '#3EE206',
  '#8AD70E',
  '#939CEA',
  '#B93197',
  '#B808C3',
  '#302122',
  '#8BC53D',
  '#3C0D9F',
  '#748B51',
  '#21A397',
  '#7D79FA',
  '#C768C9',
  '#6066DF',
  '#654E0A',
  '#03BB65',
  '#2E2A81',
  '#448BC5',
  '#535B93',
  '#BF8FE8',
  '#9FC5A3',
  '#AC1D69',
  '#EBE76B',
  '#DBE377',
  '#727F16',
  '#8B5400',
  '#7BC983',
  '#37F1F6',
  '#CC2A09',
  '#27CA6D',
  '#0F9649',
  '#6B534D',
  '#775684',
  '#D51FF1',
  '#9285CF',
  '#3CFC23',
  '#68ECC3',
  '#93BE94',
  '#379469',
  '#50BE57',
  '#E94D57',
  '#0C0E8C',
  '#22FD19',
  '#140B97',
  '#4E7FDC',
  '#7B5B8B',
  '#298371',
  '#FA4F75',
  '#89A843',
  '#08278F',
  '#4FB9B4',
  '#5EDE15',
  '#F1F307',
  '#981315',
  '#61E987',
  '#9C7462',
  '#8C06A1',
  '#BA3BE3',
  '#27B3BD',
  '#92E8B7',
  '#04479B',
  '#927A82',
  '#C4DFFC',
  '#224E6F',
  '#B01A9D',
  '#29260C',
  '#2AEA47',
  '#C0CAFB',
  '#61EAF4',
  '#AB5C5E',
  '#977CE8',
  '#8027A7',
  '#F77B26',
  '#688538',
  '#AD3F99',
  '#E316FF',
  '#E801FB',
  '#8E3095',
  '#70EF61',
  '#80EC08',
  '#6EB74B',
  '#733753',
  '#3E16EB',
  '#C5CA52',
  '#BD2AA3',
  '#301E76',
  '#AFAD70',
  '#0AE20C',
  '#DC1977',
  '#C34FEF',
  '#21F407',
  '#BA757E',
  '#5884A9',
  '#08621D',
  '#9E0EDE',
  '#EF0D40',
  '#5FAC52',
  '#ABB3F5',
  '#C0D22F',
  '#0CD0BB',
  '#F9BF7F',
  '#0CEB94',
  '#E4F97E',
  '#E3EC61',
  '#B6E979',
  '#473F7A',
  '#F77E30',
  '#2141AB',
  '#F9FE56',
  '#F1DDA4',
  '#7FCAF3',
  '#4FBBDE',
  '#CF001A',
  '#C14BF9',
  '#1FBED6',
  '#3450E5',
  '#434FF1',
  '#EB1FC2',
  '#983E43',
  '#76234F',
  '#983E82',
  '#4C68C5',
  '#AB1CAD',
  '#E4622A',
  '#6A08D5',
  '#86EC56',
  '#F93520',
  '#BCBC86',
  '#A7A1AC',
  '#C07D94',
  '#31B1E0',
  '#8D9C2B',
  '#CE88B1',
  '#E58873',
  '#534D1E',
  '#14FC5E',
  '#DDB69D',
  '#26BBE6',
  '#CDC564',
  '#8C8D99',
  '#BBDB73',
  '#9504BA',
  '#2E1E90',
  '#56D1B3',
  '#64FE19',
  '#7D458C',
  '#82196D',
  '#49B575',
  '#5CB3E6',
  '#64B63D',
  '#FBA403',
  '#09512C',
  '#E75ADC',
  '#F29B67',
  '#2DCD30',
  '#A3B58C',
  '#885EA8',
  '#A8FF3C',
  '#C3F5E5',
  '#D03AE6',
  '#A9A5E3',
  '#17B755',
  '#FFEFF2',
  '#BB5B13',
  '#1E4492',
  '#B3B69A',
  '#9A1526',
  '#B16A05',
  '#CC1182',
  '#5A19EA',
  '#CAE807',
  '#2A23F4',
  '#AA5046',
  '#50372C',
  '#986B25',
  '#6EF46F',
  '#B8ADC9',
  '#163768',
  '#EC923B',
  '#ED4486',
  '#AF593B',
  '#9E43C0',
  '#CE705E',
  '#84A26E',
  '#E4B294',
  '#833DD7',
  '#966E92',
  '#E61666',
  '#4FAE10',
  '#DF592F',
  '#DA218F',
  '#916FFF',
  '#2E535C',
  '#A9B48D',
  '#55DBEF',
  '#ACACE2',
  '#C74264',
  '#2837C8',
  '#4620BA',
  '#D00BBE',
  '#A5B429',
  '#7F0B27',
  '#0403CC',
  '#C02E1D',
  '#9FE9AA',
  '#840383',
  '#CF2DB4',
  '#5B95F6',
  '#1C74B8',
  '#2470E1',
  '#B65BAE',
  '#E7E14A',
  '#6A6FF6',
  '#512C6E',
  '#582C91',
  '#E473F2',
  '#2E91FE',
  '#B58247',
  '#8A8B7E',
  '#9C7C90',
  '#D755B4',
  '#7D30F2',
  '#EA76DB',
  '#E5670D',
  '#699D53',
  '#E3A385',
  '#8F3BF2',
  '#DDD54A',
  '#02F7A0',
  '#40AEE0',
  '#1C3B0B',
  '#19A45A',
  '#63738C',
  '#D8FD09',
  '#E4A838',
  '#845C06',
  '#71F254',
  '#CB84F0',
  '#0D90B9',
  '#A9D421',
  '#DB5276',
  '#8F781D',
  '#DD9519',
  '#526F22',
  '#8DB61E',
  '#7E9D11',
  '#3A0B21',
  '#80F250',
  '#BB31A6',
  '#323F95',
  '#D037D5',
  '#81F9C2',
  '#A52978',
  '#A24E4E',
  '#48187A',
  '#D5B0D8',
  '#56A5F6',
  '#79BA32',
  '#2E56C9',
  '#511799',
  '#26ED7C',
  '#6D2021',
  '#6AB24E',
  '#57CAD9',
  '#33A5A3',
  '#EC9D90',
  '#90DB10',
  '#D7A8AE',
  '#7F6393',
  '#DD6721',
  '#B0D5D7',
  '#678439',
  '#401F9B',
  '#E0A444',
  '#811F93',
  '#054EA2',
  '#519E6C',
  '#7BD8A6',
  '#396EDB',
  '#35C456',
  '#85BC36',
  '#F9888E',
  '#5F9373',
  '#ABC0AA',
  '#CD0825',
  '#FBBA8E',
  '#178900',
  '#750198',
  '#53414E',
  '#C0CC8F',
  '#27AD22',
  '#C494D9',
  '#55F668',
  '#EBB3DF',
  '#2A0E69',
  '#D80853',
  '#A17465',
  '#ECBD31',
  '#61D24B',
  '#26525C',
  '#B35CBA',
  '#06C8B3',
  '#D90873',
  '#AE4809',
  '#430966',
  '#619BE5',
  '#6EC368',
  '#6426D9',
  '#988E09',
  '#85C9F2',
  '#F54902',
  '#F71CA8',
  '#40C67A',
  '#0028F3',
  '#178E07',
  '#10D59E',
  '#88594F',
  '#290EF7',
  '#8F8B88',
  '#E9F670',
  '#E80BEA',
  '#962F07',
  '#F20F10',
  '#7A0058',
  '#8318A9',
  '#892941',
  '#3E76D8',
  '#DCBECC',
  '#E46F4C',
  '#7C094A',
  '#2C3568',
  '#697EF5',
  '#6D22BE',
  '#EA2596',
  '#1031A7',
  '#CD402A',
  '#894073',
  '#5E070C',
  '#5F6850',
  '#F6128B',
  '#A58E5B',
  '#9E4DEC',
  '#13B2BC',
  '#E0B590',
  '#6977B0',
  '#365C4A',
  '#8E9CC7',
  '#9EB406',
  '#0F7C41',
  '#CAA425',
  '#46C921',
  '#4AFD7C',
  '#51A05A',
  '#769E2E',
  '#FF9A4C',
  '#F82C25',
  '#D60C48',
  '#8993AF',
  '#D15708',
  '#508FAC',
  '#BA4E66',
  '#43D402',
  '#C9B6C6',
  '#5A8B19',
  '#379B64',
  '#72FC05',
  '#349099',
  '#262ECF',
  '#529DDA',
  '#7021C9',
  '#53B40C',
  '#A3133A',
  '#D6105E',
  '#FA098E',
  '#30F61B',
  '#F7FBCF',
  '#7628A6',
  '#38D582',
  '#38D415',
  '#3553B7',
  '#EBEE6A',
  '#ED016B',
  '#7A340C',
  '#745496',
  '#ACCFF8',
  '#6E35D9',
  '#FB70CE',
  '#B49F99',
  '#B0E047',
  '#7E44E4',
  '#C52FA0',
  '#99B1A8',
  '#F6C955',
  '#31512A',
  '#898CD7',
  '#0AC3A7',
  '#330F5B',
  '#7414E6',
  '#918F2A',
  '#AA4372',
  '#A8C7B6',
  '#EFAFEF',
  '#A54B92',
  '#234A36',
  '#5E1918',
  '#CC093C',
  '#01BA09',
  '#ABFF79',
  '#19C540',
  '#CAED40',
  '#60C79F',
  '#3A1F42',
  '#8FD8E4',
  '#10EE63',
  '#E2DD01',
  '#D629DC',
  '#916A0B',
  '#C9AA19',
  '#E29D0F',
  '#0F2DC2',
  '#FA8146',
  '#ED8489',
  '#F52AF4',
  '#3E7CB7',
  '#CC7E94',
  '#09E9B3',
  '#0918D9',
  '#2DF57F',
  '#9BD089',
  '#84502F',
  '#4283C3',
  '#6CC759',
  '#1871DF',
  '#07AD82',
  '#716C19',
  '#B5C398',
  '#6F537B',
  '#D934A0',
  '#3A7E8F',
  '#0F07B2',
  '#068CA1',
  '#511A49',
  '#51296C',
  '#997180',
  '#18F20B',
  '#412CDC',
  '#E3DFD2',
  '#833BC9',
  '#763F7B',
  '#F94FB4',
  '#AF5CC3',
  '#9A77A5',
  '#928DD5',
  '#75CA54',
  '#65DB48',
  '#FE85B5',
  '#94F1C9',
  '#65CF2A',
  '#4A0045',
  '#4BA26E',
  '#A6D3C9',
  '#86E77A',
  '#1DFEC2',
  '#80C811',
  '#D6C883',
  '#739465',
  '#EFBC72',
  '#68F56C',
  '#8FD47E',
  '#5552ED',
  '#551EA8',
  '#48704E',
  '#2A720E',
  '#E8DCD5',
  '#A3B059',
  '#EAFF99',
  '#8D2FE7',
  '#E01532',
  '#42B2E8',
  '#89B391',
  '#D8BC75',
  '#5038F1',
  '#FD2135',
  '#388FA6',
  '#73036A',
  '#43200A',
  '#56A9E9',
  '#AD7CEB',
  '#224A1F',
  '#1921C9',
  '#B0DD0D',
  '#8632F1',
  '#CAF46E',
  '#090432',
  '#44B667',
  '#F18DF9',
  '#A70EAF',
  '#F69FB0',
  '#DBAE0D',
  '#042CB9',
  '#4BBD8B',
  '#62370D',
  '#60400F',
  '#1EBBCB',
  '#9D0C9B',
  '#5E60BF',
  '#B22E93',
  '#CF3004',
  '#022CDF',
  '#77C836',
  '#394AA0',
  '#952C4B',
  '#F0B31B',
  '#72EAEC',
  '#9636E7',
  '#C201DA',
  '#270435',
  '#F25A57',
  '#6C9467',
  '#00D46C',
  '#B3EC5B',
  '#7727B6',
  '#CA825D',
  '#9CA301',
  '#A11867',
  '#41A3EF',
  '#F562D2',
  '#89D0C2',
  '#E5E89F',
  '#931AC7',
  '#119C47',
  '#B3D25A',
  '#4D32EF',
  '#4C535E',
  '#3E249F',
  '#CA0574',
  '#D38A5E',
  '#4AC8CB',
  '#817D5C',
  '#C8BFD4',
  '#E9FEF6',
  '#3C9C7E',
  '#AC9745',
  '#2D7ABC',
  '#674262',
  '#65B54B',
  '#B7754F',
  '#4FBD60',
  '#39DA38',
  '#6AFBC6',
  '#D2B980',
  '#447C9C',
  '#BDB6B2',
  '#6EB38E',
  '#CF9BB5',
  '#288E11',
  '#E6562E',
  '#2DCD47',
  '#0B5BFE',
  '#ABE379',
  '#9A2183',
  '#2698DC',
  '#7FA3DE',
  '#BDA19A',
  '#036F7D',
  '#807A20',
  '#E578D4',
  '#444F81',
  '#E4F868',
  '#36C953',
  '#6E3E86',
  '#E7B608',
  '#A4DE46',
  '#02B0A1',
  '#9D757D',
  '#9EBDC4',
  '#3BCE1C',
  '#7C14B5',
  '#BC76D2',
  '#760FC7',
  '#D55822',
  '#D5E940',
  '#6BC708',
  '#CB5312',
  '#698695',
  '#D6A3FD',
  '#5D3377',
  '#3D2042',
  '#91DE4F',
  '#0A9913',
  '#EE9919',
  '#6679AE',
  '#1EB3E0',
  '#671465',
  '#460DF1',
  '#0E0A72',
  '#4E8B73',
  '#9915C0',
  '#52866E',
  '#CA4D84',
  '#F93F4A',
  '#189A31',
  '#EDAF26',
  '#F897E0',
  '#6ABA83',
  '#F7D261',
  '#C8953D',
  '#61B10D',
  '#705184',
  '#1EFC97',
  '#08EDA5',
  '#E7D8D0',
  '#641152',
  '#ED24EB',
  '#9F98C3',
  '#FCBD96',
  '#28E01B',
  '#070286',
  '#4737C1',
  '#351DDD',
  '#0CACA4',
  '#2CD0DE',
  '#CE5D0D',
  '#3557D1',
  '#906BD4',
  '#8D039A',
  '#7C74D4',
  '#392873',
  '#6B8C26',
  '#DB6463',
  '#8F183B',
  '#3A7AF4',
  '#F2E2B5',
  '#520EE3',
  '#4BEAF5',
  '#E85F18',
  '#A431F3',
  '#6F5411',
  '#A63B41',
  '#E8DD3D',
  '#215806',
  '#0BB4DA',
  '#749D8A',
  '#F312E6',
  '#4CE192',
  '#EC3454',
  '#A605AD',
  '#021AFA',
  '#5EE61C',
  '#C59B73',
  '#D50195',
  '#54B595',
  '#3A7547',
  '#096E08',
  '#BDD484',
  '#76A873',
  '#DD8822',
  '#E6C5B1',
  '#DCB3AB',
  '#C589A1',
  '#E288AF',
  '#C7C5FF',
  '#7D6331',
  '#51E058',
  '#B1E4B8',
  '#69AE24',
  '#EFCA4C',
  '#ABA0FE',
  '#24F044',
  '#906751',
  '#0BAAAC',
  '#F9CC40',
  '#8EB2FA',
  '#11E022',
  '#5B3B38',
  '#C347DF',
  '#4D809C',
  '#01BB4A',
  '#65C56E',
  '#7BAB7D',
  '#3407BE',
  '#7FBFF5',
  '#B077CC',
  '#122D88',
  '#CF46BC',
  '#3E046B',
  '#2DBEDB',
  '#49BB07',
  '#81AAF2',
  '#3B281D',
  '#45C4A3',
  '#0C1BBE',
  '#6072C8',
  '#A4A5F9',
  '#824AA6',
  '#8EC536',
  '#933AFF',
  '#85F08C',
  '#920370',
  '#DF21AD',
  '#83B59F',
  '#B22EC1',
  '#E57D6F',
  '#9F2A3A',
  '#A0AD25',
  '#AB8601',
  '#5B48A0',
  '#A8616D',
  '#10D4B0',
  '#E61833',
  '#DEE64F',
  '#8ABC35',
  '#DDE4F4',
  '#105E40',
  '#F360BA',
  '#39820D',
  '#287D5A',
  '#228067',
  '#587A6C',
  '#A5015B',
  '#B953C5',
  '#0ED8B3',
  '#3E5BFC',
  '#254AFA',
  '#7A07B6',
  '#37F9AC',
  '#EBAC46',
];

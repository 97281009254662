import { faCalendarAlt, faChevronDown, faMinus, faTimesCircle, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from 'Body/Statuses/Loading';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Col, Modal, ModalBody, Popover, PopoverBody, PopoverHeader, Row, Spinner } from 'reactstrap';
import { useAuth0 } from '../../../../auth0/reactAuth0Spa';
import { formatBearerToken } from '../../../../Common.functions';
import { fetchDataAgnostic } from '../../../../Services/dataApi';
import './AdminEvaluationStatus.css';
import style from './style.js';

function AdminEvaluationStatus({ activeDepartment, activeSeason, interviewDates }) {
  const { getTokenSilently, loginWithRedirect } = useAuth0();
  const [data, setData] = useState([]);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [dateShowing, setDateShowing] = useState({});
  const [showingAllDates, setShowingAllDates] = useState(true);
  const [showInterviewDatePopover, setShowInterviewDatePopover] = useState(false);
  const [dateSelected, setDateSelected] = useState('');
  const [allDatesSelected, setAllDatesSelected] = useState(true);
  const [loading, setLoading] = useState(true);
  const [usersWithFailedImage, setUsersWithFailedImage] = useState([]);
  const history = useHistory();
  const { location } = history;
  const { pathname } = location;

  const query = new URLSearchParams(useLocation().search);

  //sorting data by last name
  data.sort((a, b) => (a.UserLast > b.UserLast ? 1 : b.UserLast > a.UserLast ? -1 : 0));

  useEffect(() => {
    if (interviewDates) {
      const pk_InterviewDate = query.get('pk_InterviewDate');
      if (pk_InterviewDate === 'prescreen') {
        setDateShowing('prescreen');
        setShowingAllDates(false);
      } else {
        if (pk_InterviewDate != null) {
          setShowingAllDates(false);
        }
        getEvaluator(pk_InterviewDate);
        const interviewDate = interviewDates.find((date) => {
          return date.pk_InterviewDate == pk_InterviewDate;
        });

        setDateShowing(interviewDate);
      }
    }
  }, [interviewDates]);

  useEffect(() => {
    getSeasonSettings();
  }, []);

  const getSeasonSettings = () => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/season',
          {
            pk_Department: activeDepartment.pk_Department,
            pk_Season: activeSeason.pk_Season,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            if (res.data.EnablePrescreenMode) {
              handleIntervewDate('prescreen');
              setShowingAllDates(false);
              setDateShowing('prescreen');
              setAllDatesSelected(false);
              setShowInterviewDatePopover(false);
            } else {
              handleIntervewDate();
              setShowingAllDates(true);
              setAllDatesSelected(true);
            }
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const candidatesBackground = (candidates) => {
    const statusLength = candidates.length;
    const elements = 5 - statusLength;

    if (elements > 0) {
      for (let index = 0; index < elements; index++) {
        candidates.push({ placeHolder: true });
      }
    }
    return candidates;
  };

  const getEvaluator = (pk_InterviewDate) => {
    // TODO: Add support for Pre-screen

    const data = {
      pk_Department: activeDepartment.pk_Department,
      pk_Season: activeSeason.pk_Season,
    };

    if (pk_InterviewDate !== 'prescreen') {
      if (pk_InterviewDate && !isNaN(Number(pk_InterviewDate))) {
        data.pk_interviewDate = pk_InterviewDate;
      }
      data.type = 'evaluation';
    } else {
      data.type = 'prescreen';
    }

    setLoading(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic('department/season/evaluators/candidateEvaluationStatus', data, formatBearerToken(token))
          .then((results) => {
            setData(results.data);
          })
          .catch((err) => {
            setTimeout(() => {
              setLoading(false);
            }, 500);
          })
          .finally(() => {
            setTimeout(() => {
              setLoading(false);
            }, 500);
          });
      })
      .catch((err) => {
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const handleIntervewDate = (pk_InterviewDate = 'all') => {
    getEvaluator(pk_InterviewDate);
    history.push(`/administrator/evaluation_status?pk_InterviewDate=${pk_InterviewDate}`);
  };

  const processFilterButtonLabel = () => {
    if (showingAllDates || dateShowing == null) {
      return 'Showing All Dates';
    } else if (dateShowing === 'prescreen') {
      return 'Showing Prescreen';
    } else {
      return `Showing ${dateShowing ? dateShowing.MomentDateOfInterview.format('dddd, MMMM D, YYYY') : ''}`;
    }
  };

  const renderLoading = () => {
    return (
      <>
        <div style={{ width: '100%', height: '60vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <div style={{ display: 'flex', width: 100, height: 100 }}>
            {' '}
            <Loading options={{ labelText: 'Loading. . .' }} />
          </div>
        </div>
      </>
    );
    return (
      <Modal className="backless-modal" isOpen={loading} centered style={{ backgroundColor: null }}>
        <ModalBody>
          <div
            style={{
              ...style.simpleRow,
              alignItems: 'center',
              justifyContent: 'center',
              height: '30vh',
              width: '100%',
            }}
          >
            <div style={{ height: 100, width: 100 }}>
              {' '}
              <Loading options={{ labelText: 'Loading. . .' }} />
            </div>

            {/* <Spinner color="primary" style={{ marginRight: 20 }} />
            Loading. . . */}
          </div>
        </ModalBody>
      </Modal>
    );
  };

  return (
    <div className="mainWrapper">
      {
        <>
          <div style={{ ...style.simpleRow }}>
            <Col lg={6} />
            <Col lg={3}>
              <Button id="Popover1" color="primary" className="theInput" style={{ width: '100%' }}>
                <div style={{ ...style.simpleRow, justifyContent: 'space-between' }}>
                  <FontAwesomeIcon icon={faCalendarAlt} style={{ color: '#fafafa', marginRight: '15px' }} size="lg" />
                  {processFilterButtonLabel()}
                  <FontAwesomeIcon icon={showInterviewDatePopover ? faMinus : faChevronDown} />
                </div>
              </Button>
            </Col>

            <Popover
              flip={false}
              className="override_popover"
              isOpen={showInterviewDatePopover}
              target="Popover1"
              toggle={() => {
                setPopoverOpen(!popoverOpen);
                setShowInterviewDatePopover(!showInterviewDatePopover);
              }}
              trigger="legacy"
              style={{ border: '2px solid #0059d5' }}
            >
              <PopoverHeader>
                <div
                  className="divUnderPopoverHeader"
                  style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between', width: '100%' }}
                >
                  <div style={{ fontSize: '20px' }}>Select Date</div>
                  <FontAwesomeIcon
                    icon={faTimesCircle}
                    style={{ cursor: 'pointer' }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowInterviewDatePopover(false);
                    }}
                  />
                </div>
              </PopoverHeader>
              <PopoverBody style={{ padding: '18px' }}>
                <div
                  className="interviewDatesWrapper"
                  style={{
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    marginBottom: '12px',
                    marginTop: '12px',
                    border: '2px solid #d6d6d6',
                    borderRadius: '5px',
                    maxHeight: '350px',
                  }}
                >
                  {interviewDates.map(({ MomentDateOfInterview, Label, pk_InterviewDate }, i) => {
                    return (
                      <div
                        className={`blue_overlay ${dateSelected === pk_InterviewDate && 'selectedBG'}`}
                        style={{
                          background: allDatesSelected
                            ? i % 2 === 0
                              ? '#d3f2fe'
                              : '#c7e6f2'
                            : i % 2 === 0
                            ? '#ffffff'
                            : '#d6d6d6',
                        }}
                      >
                        <Col
                          // className="clickable"
                          style={{ padding: '5px', cursor: 'pointer' }}
                          onClick={() => {
                            handleIntervewDate(pk_InterviewDate);
                            setDateShowing({ pk_InterviewDate, MomentDateOfInterview, Label });
                            setShowingAllDates(false);
                            setDateSelected(pk_InterviewDate);
                            setAllDatesSelected(false);
                            setShowInterviewDatePopover(false);
                          }}
                        >
                          <div style={{ fontWeight: 'bold', textAlign: 'center' }}>
                            {MomentDateOfInterview.format('dddd, MMMM D, YYYY ')}
                          </div>
                          <div style={{ textAlign: 'center', fontSize: 11 }}> {Label || ' '}</div>
                        </Col>
                      </div>
                    );
                  })}
                </div>
                <Button
                  color="primary"
                  onClick={() => {
                    handleIntervewDate();
                    setShowingAllDates(true);
                    setAllDatesSelected(true);
                  }}
                  style={{ width: '100%' }}
                >
                  Select All Dates
                </Button>
                <Button
                  disabled={activeSeason && !activeSeason.AllowPrescreen}
                  color="primary"
                  onClick={() => {
                    handleIntervewDate('prescreen');
                    setShowingAllDates(false);
                    setDateShowing('prescreen');
                    setAllDatesSelected(false);
                    setShowInterviewDatePopover(false);
                  }}
                  style={{ width: '100%', marginTop: 10 }}
                >
                  Prescreen
                </Button>
              </PopoverBody>
            </Popover>
          </div>
          {loading ? (
            renderLoading()
          ) : data.length === 0 ? (
            <Col
              md={{ size: 4, offset: 4 }}
              style={{
                width: '40rem',
                height: '50px',
                backgroundColor: '#d0e4fd',
                marginTop: '18%',
              }}
            >
              <div
                style={{
                  fontSize: '30px',
                  display: 'flex',
                  alignItems: 'center',
                  color: '#516f91',
                  justifyContent: 'center',
                }}
              >
                No results available
              </div>
            </Col>
          ) : (
            data.map((d) => {
              const { Candidates, UserFirst, UserLast, UserMiddle, UserPhotoUrl, pk_User } = d;

              const sortedCandidates = Candidates.sort((a, b) => {
                const textA = `${a.LastName ? `${a.LastName}, ` : ''}${a.FirstName}`;
                const textB = `${b.LastName ? `${b.LastName}, ` : ''}${b.FirstName}`;

                return textA < textB ? -1 : textA > textB ? 1 : 0;
              });
              //These variables displays count for completed, in progress, and pending
              const completed = sortedCandidates.filter(
                (candidate) => candidate.Status === 'Complete' || candidate.Status === 'Unable To Evaluate',
              );
              const inProgress = sortedCandidates.filter((candidate) => candidate.Status === 'In Progress');
              const pending = sortedCandidates.filter(
                (candidate) => candidate.Status === 'Pending' || candidate.Status == null,
              );

              const completedLength = completed.length;
              const inProgressLength = inProgress.length;
              const pendingLength = pending.length;
              //These variables are to display the different background colors
              const elementCompleted = candidatesBackground(completed);
              const elementInProgress = candidatesBackground(inProgress);
              const elementPending = candidatesBackground(pending);

              return (
                //hover_blue class doesn't work here
                <Row
                  className="wrapperRow blue_overlay"
                  style={{ paddingTop: '12px', background: 'linear-gradient(to bottom, #fafafa, #dedede)' }}
                >
                  <Col lg={3} className="imgAndNameColWrapper" style={{ height: '100%', textAlign: 'center' }}>
                    <div
                      className="imgWrapper"
                      style={{ height: '150px', width: '100%', paddingTop: '12px', paddingBottom: '10px' }}
                    >
                      {usersWithFailedImage.includes(pk_User) || !UserPhotoUrl ? (
                        <FontAwesomeIcon icon={faUserAlt} style={{ height: '130px', width: '100%' }} />
                      ) : (
                        <img
                          src={UserPhotoUrl}
                          alt=""
                          style={style.imageSizing}
                          onError={() => {
                            const newList = usersWithFailedImage.slice();
                            if (!newList.includes(pk_User)) {
                              newList.push(pk_User);
                              setUsersWithFailedImage(newList);
                            }
                          }}
                        />
                      )}
                    </div>

                    <div className="surgeonName" style={{ fontSize: '20px' }}>
                      <strong>
                        {UserLast}, {UserFirst} {''}
                        {UserMiddle}
                      </strong>
                    </div>
                    <div className="numScheduled" style={{ paddingTop: '5px', fontSize: '15px', marginBottom: '12px' }}>
                      {/* <strong>(# scheduled)</strong> */}
                    </div>
                  </Col>
                  <Col md={4} lg={3} className="PendingCandidateWrapper" style={{ height: '200px' }}>
                    <div
                      className="PendingTitle"
                      style={{
                        textAlign: 'center',
                        paddingTop: '9px',
                        paddingBottom: '9px',
                        borderRadius: '4px',
                        marginBottom: '12px',
                        backgroundColor: '#8a132d',
                        color: 'white',
                        fontSize: '16px',
                      }}
                    >
                      {pendingLength} Pending
                    </div>
                    <div
                      className="CandidatesWrapper"
                      style={{
                        overflowY: 'scroll',
                        height: '142px',
                        textAlign: 'center',
                        border: '1px solid grey',
                      }}
                    >
                      {elementPending.map((pending, i) => {
                        const { LastName, FirstName, MiddleName, placeHolder } = pending;

                        return (
                          <div
                            className="Candidates"
                            style={{
                              paddingTop: '2px',
                              paddingBottom: '6px',
                              backgroundColor: i % 2 === 0 ? '#d90a00' : '#fe4940',
                              color: 'white',
                              fontSize: '14px',
                            }}
                          >
                            {placeHolder ? (
                              <span>---</span>
                            ) : (
                              <span>
                                <strong>
                                  {LastName}, {FirstName} {''}
                                  {MiddleName}
                                </strong>
                              </span>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </Col>
                  <Col md={4} lg={3} className="InProgressCandidateWrapper" style={{ height: '200px' }}>
                    <div
                      className="InProgressTitle"
                      style={{
                        paddingTop: '9px',
                        paddingBottom: '9px',
                        borderRadius: '4px',
                        textAlign: 'center',
                        marginBottom: '12px',
                        backgroundColor: '#ffc507',
                        fontSize: '16px',
                      }}
                    >
                      {inProgressLength} In Progress
                    </div>
                    <div
                      className="CandidatesWrapper"
                      style={{
                        overflowY: 'scroll',
                        height: '142px',
                        textAlign: 'center',
                        border: '1px solid grey',
                      }}
                    >
                      {elementInProgress.map((progress, i) => {
                        const { LastName, FirstName, MiddleName, placeHolder } = progress;
                        return (
                          <div
                            className="Candidates"
                            style={{
                              paddingTop: '2px',
                              paddingBottom: '6px',
                              backgroundColor: i % 2 === 0 ? '#c97100' : '#fd9a01',
                              color: 'white',
                              fontSize: '14px',
                            }}
                          >
                            {placeHolder ? (
                              <span>---</span>
                            ) : (
                              <span>
                                <strong>
                                  {LastName}, {FirstName} {''}
                                  {MiddleName}
                                </strong>
                              </span>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </Col>
                  <Col md={4} lg={3} className="CompletedCandidateWrapper">
                    <div
                      className="CompletedTitle"
                      style={{
                        paddingTop: '9px',
                        paddingBottom: '9px',
                        borderRadius: '4px',
                        textAlign: 'center',
                        marginBottom: '12px',
                        backgroundColor: '#7eb438',
                        color: 'white',
                        fontSize: '16px',
                      }}
                    >
                      {completedLength} Completed
                    </div>
                    <div
                      className="CandidatesWrapper"
                      style={{
                        overflowY: 'scroll',
                        height: '142px',
                        textAlign: 'center',
                        border: '1px solid grey',
                      }}
                    >
                      {elementCompleted.map(({ LastName, FirstName, MiddleName, placeHolder, Status }, i) => {
                        return (
                          <div
                            className="Candidates"
                            style={{
                              paddingTop: '2px',
                              paddingBottom: '6px',
                              backgroundColor: i % 2 === 0 ? '#548e2a' : '#66b132',
                              color: 'white',
                              fontSize: '14px',
                            }}
                          >
                            {placeHolder ? (
                              <div>---</div>
                            ) : (
                              <div>
                                <strong>
                                  {LastName}, {FirstName} {''}
                                  {MiddleName}
                                </strong>
                              </div>
                            )}
                            {Status === 'Unable To Evaluate' && (
                              <div style={{ fontSize: 11 }}> {`(Unable to Evaluate)`}</div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </Col>
                </Row>
              );
            })
          )}
        </>
      }
    </div>
  );
}

export default AdminEvaluationStatus;

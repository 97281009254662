import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'reactstrap';
import NotificationItem from './NotificationItem/NotificationItem';
import style from './Notifications.style';
import { clone } from '../../../../../Services/schedule';
import { Provider as AlertProvider } from 'react-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faTimes } from '@fortawesome/free-solid-svg-icons';
import { DepartmentContext } from '../../../../../DepartmentWrapper';
import { formatBearerToken } from '../../../../../Common.functions';
import { useAuth0 } from '../../../../../auth0/reactAuth0Spa';
import { fetchDataAgnostic } from '../../../../../Services/dataApi';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import AddNotificationModal from './AddNotificationModal/AddNotificationModal';
import EditNotificationModal from './EditNotificationModal/EditNotificationModal';

const fakeData = [
  {
    pk_Notification: 0,
    minutes: 12,
    when: 'end_of_meeting', // end_of_passing, end_of_meeting
    alertEvaluator: true,
    alertCandidate: false,
    notifEndsMeeting: false,
    message: 'BRUH WAKE UP MEETING ENDING',
    sound: 'Bell', //Bell, silent
  },
  {
    pk_Notification: 1,
    minutes: 5,
    when: 'end_of_passing', // end_of_passing, end_of_meeting
    alertEvaluator: true,
    alertCandidate: true,
    notifEndsMeeting: true,
    message: 'Block ending!',
    sound: 'Bell', //Bell, silent
  },
  {
    pk_Notification: 2,
    minutes: 1,
    when: 'end_of_meeting', // end_of_passing, end_of_meeting
    alertEvaluator: false,
    alertCandidate: true,
    notifEndsMeeting: false,
    message: 'Warning, last 60 seconds!',
    sound: 'silent', //Bell, silent
  },
];

const AlertTemplate = ({ style, options, message, close }) => {
  // setTimeout(() => {
  //   close();
  // }, 2000);

  return (
    <div
      style={{
        display: 'flex',
        width: 300,
        backgroundColor: 'black',
        padding: 10,
        color: 'white',
        marginTop: 10,
        marginBotom: 10,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '20%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <FontAwesomeIcon icon={faBell} style={{ fontSize: 26 }} />
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', width: '60%', alignItems: 'center' }}>{message}</div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '20%',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'center',
          }}
          onClick={() => {
            close();
          }}
        >
          <FontAwesomeIcon icon={faTimes} style={{ fontSize: 26, cursor: 'pointer' }} />
        </div>
      </div>
    </div>
  );
};

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [editableNotifications, setEditableNotifications] = useState([]);
  const [showAddNotificationModal, setShowAddNotificationModal] = useState(false);

  const { getTokenSilently, loginWithRedirect } = useAuth0();

  const dContext = useContext(DepartmentContext);

  useEffect(() => {
    getNotifications();
  }, []);

  useEffect(() => {
    setEditableNotifications(notifications.slice());
  }, [notifications]);

  const getNotifications = () => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/alerts',
          { pk_Department: dContext.department.pk_Department },
          formatBearerToken(token),
        )
          .then((res) => {
            setNotifications(res.data || []);
            // setInterviewDates(res.data);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const updateNotification = (newNotif, index) => {
    const newNotifs = clone(notifications);
    newNotifs[index] = newNotif;
    setEditableNotifications(newNotifs);
  };

  const deleteNotification = (index) => {
    const newNotifs = clone(notifications);
    const notifToDelete = clone(newNotifs[index]);
    newNotifs.splice(index, 1);
    setEditableNotifications(newNotifs);
  };

  const renderAddNotificationModal = () => {
    return (
      <Modal isOpen={showAddNotificationModal} centered={true} style={{ maxWidth: '60%' }}>
        <ModalHeader
          closeButton
          toggle={() => {
            setShowAddNotificationModal(!showAddNotificationModal);
          }}
        >
          Add New Notification
        </ModalHeader>
        <ModalBody>
          <AddNotificationModal
            getNotifications={getNotifications}
            setShowAddNotificationModal={setShowAddNotificationModal}
          />
        </ModalBody>
      </Modal>
    );
  };

  const renderNotifHeader = () => {
    return (
      <div
        style={{
          ...style.simpleRow,
          // marginBottom: 10,
          padding: 10,
          backgroundColor: 'white',
          fontWeight: 'bold',
        }}
      >
        <div style={{ ...style.simpleColumn, width: 50, textAlign: 'center' }}></div>
        <div style={{ ...style.simpleColumn, width: '40%', textAlign: 'center' }}>Alert Message</div>
        <div style={{ ...style.simpleColumn, width: '15%', marginRight: 10, textAlign: 'center' }}>Alert Evaluator</div>
        <div style={{ ...style.simpleColumn, width: '15%', marginRight: 10, textAlign: 'center' }}>Alert Candidate</div>
        <div style={{ ...style.simpleColumn, width: '20%', textAlign: 'center' }}>Action</div>
      </div>
    );
  };
  return (
    <div style={style.simpleRow}>
      <div style={style.simpleColumn}>
        <div style={{ ...style.simpleRow, justifyContent: 'center' }}>
          <div style={{ ...style.simpleColumn, width: '70%' }}>
            {editableNotifications && editableNotifications.length > 0 ? renderNotifHeader() : null}
          </div>
        </div>
        <div style={{ ...style.simpleRow, justifyContent: 'center' }}>
          <AlertProvider template={AlertTemplate} timeout={8000}>
            <div style={{ ...style.simpleColumn, width: '70%', height: '60vh', overflowY: 'scroll' }}>
              {editableNotifications && editableNotifications.length > 0 ? (
                editableNotifications.map((n, i) => {
                  return (
                    <NotificationItem
                      notification={n}
                      index={i}
                      updateNotification={updateNotification}
                      deleteNotification={deleteNotification}
                      getNotifications={getNotifications}
                    />
                  );
                })
              ) : (
                <div style={{ ...style.simpleRow, justifyContent: 'center', height: '100%', alignItems: 'center' }}>
                  No Notifications
                </div>
              )}
            </div>
          </AlertProvider>
        </div>
        <div style={{ ...style.simpleRow, alignItems: 'center', justifyContent: 'center', padding: 20 }}>
          <Button
            style={{ width: 200 }}
            color="success"
            onClick={() => {
              setShowAddNotificationModal(true);
              // saveNotifs();
            }}
          >
            Add Notification
          </Button>
           
        </div>
      </div>
      {renderAddNotificationModal()}
    </div>
  );
};
export default Notifications;

import { faFilePdf, faImage, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Tooltip } from 'reactstrap';
import Spinner from 'reactstrap/lib/Spinner';
import UncontrolledTooltip from 'reactstrap/lib/UncontrolledTooltip';
import { useAuth0 } from '../../auth0/reactAuth0Spa';
import { formatBearerToken, swalErrorMessage } from '../../Common.functions';
import { DepartmentContext } from '../../DepartmentWrapper';
import { fetchDataAgnostic } from '../../Services/dataApi';

const FileViewer = (props) => {
  const [uri, setUri] = useState(null);
  const [MimeType, setMimeType] = useState(null);
  const [info, setInfo] = useState({});
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState();
  const [showTimedTooltips, setShowTimedTooltips] = useState(false);
  const [myToken, setToken] = useState();
  const dContext = useContext(DepartmentContext);

  useEffect(() => {
    getCandidateDetails();
    // getFile(props.match.params);
  }, []);

  useEffect(() => {
    if (documents && documents.length > 0) {
      setShowTimedTooltips(true);

      setTimeout(() => {
        setShowTimedTooltips(false);
      }, 4000);
    }
  }, [documents]);

  useEffect(() => {
    if (info) {
      document.title = `${info.LastName || ''}, ${info.FirstName || ''} | ${
        selectedDocument ? selectedDocument.DocumentTypeName : 'No Document Name'
      }`;
    }
  }, [info, selectedDocument]);

  const { getTokenSilently, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (!selectedDocument) return;
    const { pk_File, fk_Department, fk_Candidate, Name, fk_DocumentType, MimeType } = selectedDocument;
    getTokenSilently()
      .then((token) => {
        setToken(token);
        fetchDataAgnostic(
          'files/candidateFile',
          {
            pk_File,
            pk_DocumentType: fk_DocumentType,
            fileName: Name,
            pk_Department: fk_Department,
          },
          formatBearerToken(token),
        )
          .then((result) => {
            if (result && result.data && result.data.uri) {
              setUri(result.data.uri);
            } else {
              swalErrorMessage();
            }
          })
          .catch((err) => {
            swalErrorMessage();
          });
      })
      .catch((err) => {
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  }, [selectedDocument]);

  const getCandidateDetails = () => {
    const { pk_File, pk_DocumentType, fileName, pk_Candidate } = props.match.params;
    if (pk_Candidate != null) {
      setIsLoading(true);
      getTokenSilently()
        .then((token) => {
          fetchDataAgnostic(
            'department/candidate',
            { pk_Candidate, pk_Department: dContext.department.pk_Department },
            formatBearerToken(token),
          )
            .then((result) => {
              const newDocuments = result.data.Documents || [];
              const defaultDocument = newDocuments.find((d) => {
                return d.pk_File == pk_File;
              });
              if (defaultDocument) {
                setSelectedDocument(defaultDocument);
              }
              setInfo(result.data);
              setDocuments(result.data.Documents);
              setIsLoading(false);
            })
            .catch((err) => {
              setIsLoading(false);
            });
        })
        .catch((err) => {
          setIsLoading(false);
          if (
            err.message === 'Login required' ||
            err.message === 'Unauthorized' ||
            (err.response && err.response.status && err.response.status.toString() === '401')
          ) {
            loginWithRedirect();
          }
        });
    } else {
    }
  };

  const processIcon = (type) => {
    let icon = null;

    if (type.indexOf('/pdf') >= 0) {
      icon = faFilePdf;
    } else if (type.indexOf('image') >= 0) {
      icon = faImage;
    }

    return icon;
  };

  const renderTopBar = () => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          height: 80,
          padding: 5,
          paddingLeft: 30,
          paddingRight: 30,
          marginBottom: 30,
          borderBottom: '1px solid grey',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ fontSize: 30, fontWeight: 'bold' }}>{`${
            info ? `${info.LastName || ''}, ${info.FirstName || ''}` : ''
          }`}</div>
          <div style={{ fontSize: 15 }}>
            <span style={{ fontWeight: 'bold' }}>{selectedDocument ? selectedDocument.DocumentTypeName : 'None'}</span>
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right' }}>
            <Button
              disabled={!selectedDocument}
              color="primary"
              onClick={() => {
                document.getElementById(`fileDownload`).click();
              }}
            >
              Download
            </Button>

            <a
              id="fileDownload"
              style={{ visibility: 'hidden' }}
              href={uri ? uri : ''}
              target="_blank"
              download={`${info.LastName}_${info.FirstName}_${
                selectedDocument ? selectedDocument.DocumentTypeName : ''
              }`}
            >
              Download
            </a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {renderTopBar()}
      <div style={{ height: 'calc(100vh - 43px)' }}>
        {/* {correctViewer()} */}
        {/* <div style={{ fontWeight: 'bold', justifyContent: 'center', textAlign: 'center', width: '30%' }}>Documents</div> */}
        <div style={{ display: 'flex', flexDirection: 'row', height: '80vh' }}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '10%', maxWidth: 80, paddingLeft: 20 }}>
            <div>
              <div style={{ display: 'flex', flexDirection: 'column', height: '100%', overflowY: 'scroll' }}>
                {documents && documents.length > 1
                  ? documents.map((d, i) => {
                      return (
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginRight: 10,
                            marginLeft: 10,
                            paddingTop: 10,
                            paddingLeft: 10,
                            cursor: 'pointer',
                            borderBottom: '1px grey solid',
                            backgroundColor:
                              selectedDocument && selectedDocument.pk_File == d.pk_File
                                ? '#d9f5ff'
                                : i % 2 == 0
                                ? '#c7cbd1'
                                : 'white',
                          }}
                          onClick={() => {
                            setSelectedDocument(d);
                          }}
                        >
                          <div
                            id={`file_${i}`}
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              width: '100%',

                              paddingBottom: 10,
                            }}
                          >
                            <FontAwesomeIcon icon={processIcon(d.MimeType)} style={{ fontSize: 20 }} size={30} />
                          </div>
                          <Tooltip
                            isOpen={showTimedTooltips}
                            target={`file_${i}`}
                            placement="right"
                            flip={false}
                            modifiers={{ preventOverflow: { boundariesElement: 'viewport' } }}
                          >
                            {d.DocumentTypeName || 'Unknown Document Type'}
                          </Tooltip>
                          <UncontrolledTooltip
                            target={`file_${i}`}
                            placement="right"
                            flip={false}
                            modifiers={{ preventOverflow: { boundariesElement: 'viewport' } }}
                          >
                            {d.DocumentTypeName || 'Unknown Document Type'}
                          </UncontrolledTooltip>
                        </div>
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', flexDirection: 'column', width: '90%', paddingRight: 20 }}>
            {selectedDocument ? (
              // selectedDocument.MimeType.indexOf('image') >= 0 ? (
              //   <image src={selectedDocument.FullUrl} height={'100%'} width={'100%'} />
              // ) :
              //   (
              <iframe src={uri} title={selectedDocument.Name} style={{ height: '100%', width: '100%' }} />
            ) : (
              // )
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  height: '70vh',
                  width: '100%',
                  backgroundColor: '#d6d6d6',
                  justifyContent: 'center',
                  alignItems: 'center',
                  alignSelf: 'center',
                  fontWeight: 'bold',
                }}
              >
                {' '}
                {isLoading ? <Spinner color="primary" size="sm" style={{ marginRight: 10 }} /> : 'Select a Document'}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FileViewer;

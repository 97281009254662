import React, { useState, useEffect } from 'react';
import { Popover, PopoverHeader, PopoverBody, Button, CustomInput } from 'reactstrap';
import SeasonCreationAndEditWidget from './SeasonCreationAndEditWidget';
import Swal from 'sweetalert2';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAuth0 } from '../../../../../auth0/reactAuth0Spa';
import { formatBearerToken, swalErrorMessage } from '../../../../../Common.functions';
import { putData } from '../../../../../Services/dataApi';
import { useAlert } from 'react-alert';

const SeasonListItem = ({
  Active,
  CandidatesRegistered,
  CandidatesTotal,
  EnablePrescreenMode,
  updateSeasonArray,
  seasonPopoverOpen,
  SeasonName,
  handleSeasonToggle,
  pk_Season,
  InterviewDateCount,
  saveSeason, // For saving active status
  updatingActiveSeason,
  isFetchingDepartment,
}) => {
  const [seasonName, setSeasonName] = useState(SeasonName);
  const [active, setActive] = useState(false);

  const { getTokenSilently, loginWithRedirect } = useAuth0();

  const alert = useAlert();

  const saveSeasonName = (e) => {
    e.preventDefault();
    getTokenSilently()
      .then((token) => {
        putData(
          'organization/department/season',
          {
            pk_Season,
          },
          {
            payload: {
              SeasonName: seasonName,
            },
          },
          formatBearerToken(token),
        )
          .then((res) => {
            if (res.status === 200) {
              updateSeasonArray(res.data);
              handleSeasonToggle(e, pk_Season);
              alert.success('Department settings have been updated!');
              // Swal.fire({
              //   title: 'Success!',
              //   text: 'Department settings have been updated!',
              //   icon: 'success',
              // });
            } else {
              swalErrorMessage();
            }
          })
          .catch((err) => {
            swalErrorMessage();
          });
      })
      .catch((err) => {
        swalErrorMessage();
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  // useEffect to update the season name
  useEffect(() => {
    setSeasonName(SeasonName);
  }, [SeasonName]);

  useEffect(() => {
    setActive(Active || false);
  }, [Active]);

  return (
    <tr>
      <td>
        <CustomInput
          disabled={updatingActiveSeason || isFetchingDepartment}
          type="switch"
          id={`activeSwitch_${pk_Season}`}
          name={`activeSwitch_${pk_Season}`}
          checked={active}
          style={{ cursor: 'pointer', color: 'red' }}
          onClick={() => {
            const newValue = !active;
            setActive(newValue);
            saveSeason({ pk_Season, Active: newValue ? 1 : 0 });
          }}
        />
      </td>
      <td>
        <div style={{ display: 'flex', flexDirection: 'row' }}>{SeasonName}</div>
      </td>
      <td>{`${CandidatesRegistered}/${CandidatesTotal}`}</td>
      <td>{InterviewDateCount}</td>
      <td>{EnablePrescreenMode ? 'Prescreen' : 'Evaluation'}</td>
      <td
        id={`Popover_${pk_Season}`}
        // onClick={(e) => {
        //   handleSeasonToggle(e, pk_Season);
        // }}
      >
        <FontAwesomeIcon
          disabled={isFetchingDepartment}
          icon={faPencilAlt}
          style={{ cursor: isFetchingDepartment ? 'not-allowed' : 'pointer' }}
        />
        <div className="admin_popover">
          <Popover
            placement="left"
            isOpen={seasonPopoverOpen === pk_Season}
            className="admin_popover"
            modifiers={{ preventOverflow: { boundariesElement: 'viewport' }, flip: false }}
            trigger="legacy"
            target={`Popover_${pk_Season}`}
            toggle={(e) => {
              handleSeasonToggle(e, pk_Season);
            }}
          >
            <PopoverHeader>Season</PopoverHeader>
            <PopoverBody>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <SeasonCreationAndEditWidget seasonName={seasonName} setSeasonName={setSeasonName} />
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                  {' '}
                  <Button
                    color="success"
                    onClick={(e) => {
                      saveSeasonName(e);
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </PopoverBody>
          </Popover>
        </div>
      </td>
    </tr>
  );
};

export default SeasonListItem;

import {
  faChevronDown,
  faExclamationTriangle,
  faSignOutAlt,
  faTimesCircle,
  faUnderline,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useEffect, useRef, useState, Fragment } from 'react';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import {
  Button,
  Col,
  Popover,
  PopoverBody,
  PopoverHeader,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  UncontrolledTooltip,
} from 'reactstrap';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import RezRateImg from '../../../assets/images/header/rezRate.gif';
import { useAuth0 } from '../../../auth0/reactAuth0Spa';
import { formatBearerToken, isDev } from '../../../Common.functions';
import moment from 'moment';
import { DepartmentContext } from '../../../DepartmentWrapper';
import { fetchDataAgnostic, fetchDataAuth0, postDataAgnosticWithoutCredentials } from '../../../Services/dataApi';
import DateDisplay from './DateDisplay/DateDisplay';
import Style from './Header.style';

import style from './Header.style.js';
import Navigation from './Navigation';
import SeasonPopover from './SeasonPopover/SeasonPopover';
import SettingsPopover from './SettingsPopover/SettingsPopover';
import UserInformationContainer from './UserInformation/UserInformation.container';
import ReactCSSTransitionReplace from 'react-css-transition-replace';

import './Header.style.css';
import { clone } from '../../../Services/schedule';

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
`;

const Header = (props) => {
  const [showSeasonPopover, setShowSeasonPopover] = useState(false);
  const [showSettingsPopover, setShowSettingsPopover] = useState(false);
  const [showEvaluatorOptions, setShowEvaluationOptions] = useState(false);
  const [showSeasonSelection, setShowSeasonSelection] = useState(false);
  const [departmentLogo, setDepartmentLogo] = useState(null);

  const [token, setToken] = useState();
  const [seasons, setSeasons] = useState();
  const [activeSeason, setActiveSeason] = useState();
  const [selectedSeason, setSelectedSeason] = useState();

  const [windowWidth, setWindowWidth] = useState(0);
  const [windowHeight, setWindowHeight] = useState(0);

  const [departments, setDepartments] = useState([]);
  const [settings, setSettings] = useState({});
  const [currentDepartment, setCurrentDepartment] = useState();
  const [isChartPreview, setIsChartPreview] = useState(false);
  const [isIE, setIsIE] = useState(false);
  const [isOld, setIsOld] = useState(false);
  const [isFetchingSettings, setIsFetchingSettings] = useState(false);
  const [isSystemTimeInSync, setIsSystemTimeInSync] = useState(true);
  const [showTimeSyncWarningBanner, setShowTimeSyncBanner] = useState(false);
  const [timeSyncOffset, setTimeSyncOffset] = useState(0); // minutes
  const [myIsAuthenticated, setMyIsAuthenticated] = useState(false);

  let hasFiredInactivityPopup = false;

  const wrapperEl = useRef(null);
  const { getTokenSilently, logout, isAuthenticated, loginWithRedirect } = useAuth0();
  const dContext = useContext(DepartmentContext);
  let history = useHistory();
  let isLoggedIn = isAuthenticated;
  const minWidthForNav = 1000;
  const auth0Object = useAuth0();
  const { interviewDates, header, setDate, setEvaluatorInterviewDates, setDateArray } = props;

  const { pathname } = window.location;
  const isMobile = windowWidth < 500;
  const inactivityWait = 15; // in minutes
  const inactivityWaitMax = 120; // in minutes, even if isRouteIsExempted

  useEffect(() => {
    if (pathname.toUpperCase().includes('/USER/EVALUATE')) {
      setShowEvaluationOptions(true);
    } else {
      setShowEvaluationOptions(false);
    }
    if (pathname === '/') {
      setShowSeasonSelection(false);
    } else {
      setShowSeasonSelection(true);
    }

    if (pathname.indexOf('/administrator/rank/chart_print_preview') >= 0) {
      setIsChartPreview(true);
    } else {
      setIsChartPreview(false);
    }
  }, [pathname]);

  useEffect(() => {
    if (dContext) {
      setSelectedSeason(dContext.season);
    }
  }, [dContext]);
  //TODO: Find a more elegant way to do this.  Need to listen to clientHeight of the wrapperEl div and set it in redux

  useEffect(() => {
    // setTimeout(() => {
    //   setIsOld(true);
    // }, 5000);
    if (process.env.NODE_ENV === 'development') {
      //return;
    }
    if (wrapperEl.current && props.header.headerHeight !== wrapperEl.current.clientHeight) {
      props.setHeaderHeight(wrapperEl.current.clientHeight);
    }
    if (dContext && dContext.department && dContext.season) {
      getSettings();
    }

    setIsIE(/*@cc_on!@*/ false || !!document.documentMode);
    checkInactivity(true);

    setInterval(() => {
      checkInactivity();
    }, 10000);

    document.addEventListener('mousemove', () => {
      if (!isRouteExempted()) {
        const now = moment();
        localStorage.setItem('lastActivity', now.toISOString());
      }
    });
    document.addEventListener('click', () => {
      if (!isRouteExempted()) {
        const now = moment();
        localStorage.setItem('lastActivity', now.toISOString());
      }
    });

    window.addEventListener('resize', () => {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    });

    return () => {
      // window.removeEventListener('resize');
    };
  }, []);

  useEffect(() => {
    getTokenSilently()
      .then((token) => {
        setToken(token);
        fetchDataAuth0('user/allDepartments', null, formatBearerToken(token))
          .then((result) => {
            setDepartments(result.data);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
    setWindowWidth(window.innerWidth);
    setWindowHeight(window.innerHeight);
  }, []);

  useEffect(() => {
    console.log('isAuthenticated: ', isAuthenticated);
    if (!isAuthenticated) {
      sessionStorage.removeItem('roleChoice');
      logout();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isOld) {
      // return;
    }

    updateDContext(seasons);
  }, [seasons]);

  useEffect(() => {
    if (token && dContext && dContext.department) {
      fetchSeasons();
    }
  }, [token, JSON.stringify(dContext.department)]);

  useEffect(() => {
    if (
      dContext &&
      dContext.department &&
      (dContext.department.DepartmentName !== settings.DepartmentName ||
        dContext.department.DepartmentLogo !== departmentLogo) &&
      !isFetchingSettings
    ) {
      getSettings(true);
    }
  }, [dContext]);

  useEffect(() => {
    if (sessionStorage.getItem('department') == null) {
      setSettings({});
    }
  }, [sessionStorage.getItem('department')]);

  useEffect(() => {
    if (selectedSeason) {
      dContext.setSeason({ ...dContext.season, ...selectedSeason });
    }
  }, [JSON.stringify(selectedSeason)]);

  const getDepartmentFromSession = (pk_Department, cb) => {
    getTokenSilently()
      .then((token) => {
        return fetchDataAgnostic('department', { pk_Department }, formatBearerToken(token))
          .then((result) => {
            const serverTime = result.data.currentServerTime;
            const convertedCorrectedTime = moment.tz(serverTime, moment.tz.guess());
            const systemTime = moment();
            const timeDifference = convertedCorrectedTime.diff(systemTime, 'minutes');

            if (cb) {
              cb(result.data);
            }

            if (Math.abs(timeDifference) >= 2) {
              setShowTimeSyncBanner(true);
              setIsSystemTimeInSync(true);
            } else {
              setIsSystemTimeInSync(false);
            }

            setTimeSyncOffset(timeDifference);

            return result.data;
          })
          .catch((err) => {
            if (cb) {
              cb({});
            }
            sessionStorage.removeItem('department');
            return false;
          });
      })
      .catch((err) => {
        if (cb) {
          cb({});
        }
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const updateDContext = (seasons) => {
    if (seasons && dContext.department) {
      let activeSeason = seasons.find((s) => {
        return s.Active;
      });
      let detailedDeptData = {};
      getDepartmentFromSession(dContext.department.pk_Department, (data) => {
        detailedDeptData = data || {};
        const { season = [] } = detailedDeptData;
        activeSeason = season
          ? season.find((s) => {
              return s.Active;
            })
          : null;

        setActiveSeason(activeSeason);

        if (selectedSeason) {
          let sSeason = season.find((s) => {
            return selectedSeason.pk_Season == s.pk_Season; //s.Active;
          });

          if (!sSeason) {
            sSeason = season.find((s) => {
              return s.Active;
            });
          }

          if (sSeason) {
            setSelectedSeason(sSeason);
            // if (JSON.stringify(dContext.season) !== JSON.stringify(sSeason)) {
            dContext.setSeason({ ...dContext.season, ...sSeason });
            // }
          }
        } else if (activeSeason) {
          setSelectedSeason(activeSeason);
          dContext.setSeason({ ...dContext.season, ...activeSeason });
        } else {
          if (dContext.department) {
            if (seasons.length <= 0) {
              Swal.fire({
                title: 'No Seasons found!',
                text: 'Please contact RezRATE support if you believe you should already have seasons in this program.',
                type: 'error',
                icon: 'error',
              });
            } else {
              const { EnableSuperUser } = dContext.department;
              Swal.fire({
                title: 'No Active Season!',
                text: 'Please contact RezRATE support if you believe you should have access to this program.',
                type: 'error',
                icon: 'error',
              }).then((res) => {
                if (EnableSuperUser && seasons && seasons.length > 0) {
                  const seasonsCopy = clone(seasons);
                  seasonsCopy.sort((a, b) => {
                    return a.pk_Season - b.pk_Season;
                  });
                  const seasonToUse = seasonsCopy[seasonsCopy.length - 1];
                  setSelectedSeason(seasonToUse);
                  dContext.setSeason({ ...dContext.season, ...seasonToUse });
                } else {
                  sessionStorage.removeItem('department');

                  dContext.setDepartment(null);
                  console.log("Error: Couldn't find active season");
                }
              });
            }
          }
        }
      });
    }
  };

  const checkInactivity = (forceLogout) => {
    const now = moment();
    const lastActivity = localStorage.getItem('lastActivity');
    // const lastActivityMoment = moment('March 18, 2023');
    const lastActivityMoment = lastActivity ? moment(lastActivity) : null;
    const currentInactivityLength = now.diff(lastActivityMoment, 'minutes');

    if (
      !lastActivity ||
      // (((!isRouteExempted() && lastActivity && currentInactivityLength > inactivityWait) ||
      //   currentInactivityLength > inactivityWaitMax) &&
      //   !hasFiredInactivityPopup)
      (!isRouteExempted() && lastActivity && currentInactivityLength > inactivityWait && !hasFiredInactivityPopup)
      // &&
      // dContext &&
      // dContext.department &&
      // dContext.season
    ) {
      hasFiredInactivityPopup = true;
      // const autoLogoutTimeout = setTimeout(() => {
      //   logout();
      // }, 40000);

      if (forceLogout) {
        sessionStorage.removeItem('roleChoice');
        logout();
      } else {
        Swal.fire({
          title: 'Inactivity Detected',
          text: 'Are you still there?',
          showDenyButton: true,
          confirmButtonText: 'Yes',
          denyButtonText: 'No, Log me out',
          denyButtonColor: 'red',
          timer: 30000,
          timerProgressBar: true,
        }).then((res) => {
          hasFiredInactivityPopup = false;
          if (res.dismiss === Swal.DismissReason.timer) {
            logout();
            sessionStorage.removeItem('roleChoice');
          } else if (res.isConfirmed) {
            // YES STILL HERE
            // clearTimeout(autoLogoutTimeout);
          } else {
            // logout
            logout();
            sessionStorage.removeItem('roleChoice');
          }
        });
      }
    } else {
      // console.log("checkInactivity header! - didn't meet criteria");
    }
  };

  const isRouteExempted = () => {
    // SuperUserPanel route is not here, cause it's not a real route. It's just Login page with a different state. We use dContext to make sure that dContext has dept and season too before dealing with inactivity
    const exemptedRoutes = ['/administrator/evaluate', '/administrator/schedule/', '/user/evaluate'];
    let isExempt = false;
    exemptedRoutes.forEach((r) => {
      if (window.location.pathname.indexOf(r) >= 0) {
        isExempt = true;
      }
    });

    return isExempt;
  };

  const getSettings = (setDcontext) => {
    setIsFetchingSettings(true);
    // setSettings({});
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/settings',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          formatBearerToken(token),
        )
          .then((res) => {
            const fetchedDepartment = res.data && Array.isArray(res.data) && res.data[0] ? res.data[0] : {};
            setIsFetchingSettings(false);
            setSettings(fetchedDepartment);
            setCurrentDepartment(fetchedDepartment);
            setDepartmentLogo(fetchedDepartment.DepartmentLogo);
            if (setDcontext) {
              dContext.setDepartment({ ...dContext.department, ...fetchedDepartment });
            }
          })
          .catch((err) => {
            setIsFetchingSettings(false);
          });
      })
      .catch((err) => {
        setIsFetchingSettings(false);
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const fetchSeasons = () => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/seasons',
          {
            pk_Department: dContext.department.pk_Department,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            if (res.data && res.data.error == null) {
              if (JSON.stringify(res.data) !== JSON.stringify(seasons)) {
                const newSeasons = res.data;

                newSeasons.sort((a, b) => {
                  const aTimestamp = a.createdAt ? moment(a.createdAt) : null;
                  const bTimestamp = b.createdAt ? moment(b.createdAt) : null;
                  let newSortOrder = 0;

                  if (aTimestamp == null && bTimestamp == null) {
                    newSortOrder = 0;
                  } else if (aTimestamp == null) {
                    newSortOrder = 1;
                  } else if (bTimestamp == null) {
                    newSortOrder = -1;
                  } else {
                    if (aTimestamp.isAfter(bTimestamp)) {
                      newSortOrder = -1;
                    } else {
                      newSortOrder = 1;
                    }
                  }

                  return newSortOrder;
                });

                setSeasons(newSeasons);
              } else {
                if (!dContext.season && seasons) {
                  updateDContext(seasons);
                } else {
                  // console.log("not updating dContext because dContext.season is null or seasons hasn't changed");
                }
              }
            }
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const showLogoutButton = () => {
    return <div>hello</div>;
  };

  const clearCurrentDepartment = () => {
    getTokenSilently()
      .then((token) => {
        postDataAgnosticWithoutCredentials(
          'user/setDefaultDepartment',
          null,
          { departmentId: null },
          formatBearerToken(token),
        )
          .then((result) => {
            sessionStorage.removeItem('department');
            dContext.setDepartment(null);
            dContext.setSeason(null);

            history.push('/');

            // dContext.setSeason(null);
          })
          .catch((err) => {
            Swal.fire('Something went wrong...', 'Please try again later or contact support', 'error');
          });
      })
      .catch((err) => {
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const determineTitle = () => {
    if (settings && settings.DepartmentName) {
      const { department = {} } = dContext || {};
      return (
        <div>
          <div>
            {settings.DepartmentName} <span style={{ color: 'red' }}>{isDev() === 1 ? 'DEV' : ''}</span>
          </div>
          <div style={{ fontStyle: 'bold', fontSize: 15 }}>
            {settings.OrganizationName || department.OrganizationName || ' '}
          </div>
        </div>
      );
    }
    return `RezRATE ${isDev() === 1 ? 'DEV' : ''}`;
  };

  const conditionalLogo = () => {
    const { activeDepartment } = props;
    const isEvaluator = window.location.pathname === '/user/evaluate';
    return (
      <Row style={{ display: 'flex' }} className={isEvaluator ? 'browser_display' : ''}>
        <Col>
          <div style={{ paddingLeft: isMobile ? 0 : '23px', display: 'flex' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div
                tere={settings.DepartmentLogo ? 'logoProper' : 'logoPlaceholder'}
                key={settings.DepartmentLogo ? 'logoProper' : 'logoPlaceholder'}
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <div style={{ height: '50px', maxWidth: '300px', width: 'auto' }}>
                  <img
                    style={{ maxWidth: '100%', maxHeight: '100%', cursor: 'pointer' }}
                    src={departmentLogo ? departmentLogo : RezRateImg}
                    alt="department logo"
                    onClick={() => {
                      if (dContext && dContext.department && dContext.department.roleType) {
                        if (dContext.department.roleType === 'administrator') {
                          return history.push('/administrator');
                        }
                        if (dContext.department.roleType === 'evaluator') {
                          return;
                          // return history.push('/user/evaluate');
                        }

                        return history.push('/');
                      }
                    }}
                  />
                </div>

                <h4
                  style={{
                    color: '#ffffff',
                    cursor: 'pointer',
                    alignItems: 'center',
                    display: 'flex',
                    marginLeft: 10,
                  }}
                  onClick={() => {
                    if (dContext && dContext.department && dContext.department.roleType) {
                      if (dContext.department.roleType === 'administrator') {
                        return history.push('/administrator');
                      }
                      if (dContext.department.roleType === 'evaluator') {
                        return;
                        // return history.push('/user/evaluate');
                      }

                      return history.push('/');
                    }
                  }}
                  id="logo_title"
                >
                  {determineTitle()}
                </h4>
              </div>
            </div>
            {/* </a> */}
            <HeaderWrapper
              style={{
                color: 'white',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
              }}
            >
              {props.header.showName && props.user && props.user.name && <h5>{props.user.name}</h5>}
            </HeaderWrapper>
          </div>
        </Col>
      </Row>
    );
  };

  const renderSeasonPopover = () => {
    return (
      <Popover
        placementPrefix="filterPopover bs-popover"
        isOpen={showSeasonPopover}
        trigger="legacy"
        target="season_button"
        flip={false}
        modifiers={{ preventOverflow: { boundariesElement: 'viewport' } }}
        toggle={() => {
          setShowSeasonPopover(!showSeasonPopover);
        }}
      >
        <PopoverHeader>
          <div style={style.spacedBetweenRow}>
            <div style={{ textAlign: 'center', width: '100%' }}>Select a season</div>
            <div>
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setShowSeasonPopover(false);
                }}
              />
            </div>
          </div>
        </PopoverHeader>
        <PopoverBody>
          <SeasonPopover
            seasons={seasons}
            fetchSeasons={fetchSeasons}
            setSelectedSeason={setSelectedSeason}
            selectedSeason={selectedSeason}
            setShowSeasonPopover={setShowSeasonPopover}
          />
        </PopoverBody>
      </Popover>
    );
  };

  const logoutClick = () => {
    const { EnableAdmin = false, EnableEvaluator = false, EnableSuperUser = false } = dContext.department || {};

    const hasMultipleRoles = (EnableSuperUser || EnableAdmin) && EnableEvaluator;
    if (departments && (departments.length > 1 || (departments.length == 1 && hasMultipleRoles))) {
      Swal.fire({
        text: `Multiple ${departments.length > 1 ? 'departments' : 'roles'} found. Do you want to switch ${
          departments.length > 1 ? 'departments' : 'roles'
        } or logout?`,
        icon: 'warning',
        showCancelButton: true,
        showDenyButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: 'grey',
        confirmButtonText: 'Switch',
        denyButtonText: 'Logout',
      }).then((result) => {
        if (result.isConfirmed) {
          sessionStorage.removeItem('roleChoice');
          setDate(null);
          setEvaluatorInterviewDates([]);
          setDateArray([]);
          clearCurrentDepartment();
        } else if (result.isDenied) {
          sessionStorage.removeItem('roleChoice');
          setDate(null);
          logout();
        } else {
        }
      });
    } else {
      Swal.fire({
        text: 'Are you sure you want to logout?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result.value) {
          sessionStorage.removeItem('roleChoice');
          logout();
        } else {
        }
      });
    }
  };

  if (isMobile) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: '100%',
            maxWidth: '100%',
            margin: 0,
            minHeight: '100px',
            paddingLeft: '5px',
            paddingRight: '5px',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            marginTop: isMobile ? 10 : 20,
            marginBottom: isMobile ? 10 : 0,
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', width: 'auto' }}>{conditionalLogo()}</div>
          <div style={{ ...style.simpleColumn, width: isMobile && windowWidth < 400 ? '100%' : 400 }}>
            <div style={{ ...style.simpleRow, justifyContent: 'right', marginLeft: isMobile ? 0 : 20 }}>
              {showEvaluatorOptions && (
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <UserInformationContainer
                    setUser={props.setUser}
                    zoomBreakRoom={props.header.zoomBreakRoom}
                    logoutClick={logoutClick}
                    dContext={dContext}
                    date={props.header.date}
                    seasonType={props.header.seasonType}
                    dateArray={interviewDates}
                    setDate={props.setDate}
                    isMobile={isMobile}
                  />
                </div>
              )}
              {showSeasonSelection && !showEvaluatorOptions && (
                <Fragment>
                  {isChartPreview ? null : selectedSeason &&
                    activeSeason &&
                    selectedSeason.pk_Season != activeSeason.pk_Season ? (
                    <div>
                      <FontAwesomeIcon icon={faExclamationTriangle} style={style.hazardIcon} />
                    </div>
                  ) : (
                    <div>
                      <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: 5 }} />
                    </div>
                  )}

                  {isChartPreview ? null : (
                    <>
                      <Button
                        id="season_button"
                        outline
                        color="secondary"
                        style={style.seasonButton(activeSeason, selectedSeason)}
                      >
                        <div style={style.spacedBetweenRow}>
                          <div style={{ textAlign: 'center', width: '100%' }}>
                            {selectedSeason
                              ? selectedSeason.SeasonName
                              : activeSeason
                              ? activeSeason.SeasonName
                              : 'Season'}
                            {selectedSeason && selectedSeason.EnablePrescreenMode ? ' (Prescreen)' : ' (Evaluation)'}
                          </div>
                          <div>
                            <FontAwesomeIcon icon={faChevronDown} />
                          </div>
                        </div>
                      </Button>
                      {renderSeasonPopover()}
                    </>
                  )}
                </Fragment>
              )}

              {isChartPreview ? null : windowWidth > 500 ? (
                <Button
                  id="logout_button"
                  color="danger"
                  style={style.topBtn}
                  onClick={(e) => {
                    e.preventDefault();
                    logoutClick();
                  }}
                >
                  <div>
                    <FontAwesomeIcon icon={faSignOutAlt} />
                  </div>
                </Button>
              ) : (
                <Navigation departments={departments} />
              )}
            </div>
          </div>
        </div>

        {isIE ? (
          <Row
            style={{
              justifyContent: 'center',
              backgroundColor: 'red',
              color: 'white',
              padding: 20,
              paddingLeft: 30,
              paddingRight: 30,

              // fontWeight: 'bold',
            }}
          >
            <div style={{ fontSize: 20 }}>
              <FontAwesomeIcon style={{ color: 'white', marginRight: 10 }} icon={faExclamationTriangle} />
              Internet Explorer is not supported. We recommend{' '}
              <a
                href={'https://www.google.com/chrome/downloads/'}
                target={`”_blank`}
                style={{ color: 'white', textDecoration: 'underline', fontWeight: 'bold' }}
              >
                Google Chrome
              </a>
              , Microsoft Edge, or Apple Safari.{' '}
            </div>
          </Row>
        ) : null
        //   (
        //   <br />
        // )
        }
      </div>
    );
  }

  return (
    <div ref={wrapperEl} id="header_wrapper" style={{}}>
      <Col>
        <Row
          style={{
            alignItems: 'center',
            height: '100%',
            maxWidth: '100%',
            margin: 0,
            minHeight: '100px',
            paddingLeft: '5px',
            paddingRight: '5px',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', width: 'calc(100% - 400px)' }}>
            {conditionalLogo()}
          </div>

          <div style={{ ...style.simpleColumn, width: 400 }}>
            <div style={{ ...style.simpleRow, justifyContent: 'right', marginLeft: 20 }}>
              {showEvaluatorOptions && (
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                  <UserInformationContainer
                    setUser={props.setUser}
                    zoomBreakRoom={props.header.zoomBreakRoom}
                    logoutClick={logoutClick}
                    dContext={dContext}
                    date={props.header.date}
                    seasonType={props.header.seasonType}
                    dateArray={interviewDates}
                    setDate={props.setDate}
                  />
                </div>
              )}
              {showSeasonSelection && !showEvaluatorOptions && (
                <Fragment>
                  {isChartPreview ? null : selectedSeason &&
                    activeSeason &&
                    selectedSeason.pk_Season != activeSeason.pk_Season ? (
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <FontAwesomeIcon icon={faExclamationTriangle} style={style.hazardIcon} id="hazardInfoIcon" />
                      <UncontrolledTooltip target="hazardInfoIcon">
                        The selected season is not active
                      </UncontrolledTooltip>
                    </div>
                  ) : null
                  // (
                  // <div>
                  //   <FontAwesomeIcon id="hazardInfoIcon" icon={faExclamationTriangle} style={{ marginRight: 5 }} />
                  //   <UncontrolledTooltip target="hazardInfoIcon">
                  //     The selected season is not active x
                  //   </UncontrolledTooltip>
                  // </div>
                  // )
                  }

                  {isChartPreview ? null : (
                    <>
                      <Button
                        id="season_button"
                        outline
                        color="secondary"
                        style={style.seasonButton(activeSeason, selectedSeason)}
                      >
                        <div style={style.spacedBetweenRow}>
                          <div style={{ textAlign: 'center', width: '100%' }}>
                            {selectedSeason
                              ? selectedSeason.SeasonName
                              : activeSeason
                              ? activeSeason.SeasonName
                              : 'Season'}
                            {selectedSeason && selectedSeason.AllowPrescreen && selectedSeason.EnablePrescreenMode
                              ? ' (Prescreen)'
                              : ' (Evaluation)'}
                          </div>
                          <div>
                            <FontAwesomeIcon icon={faChevronDown} />
                          </div>
                        </div>
                      </Button>
                      {renderSeasonPopover()}
                    </>
                  )}
                </Fragment>
              )}
              {isChartPreview ? null : !showEvaluatorOptions || !dContext.department ? (
                <Button
                  id="logout_button"
                  color="danger"
                  style={style.topBtn}
                  onClick={(e) => {
                    e.preventDefault();
                    logoutClick();
                  }}
                >
                  <div>
                    <FontAwesomeIcon icon={faSignOutAlt} />
                  </div>
                </Button>
              ) : null}
            </div>
          </div>
        </Row>
        {!isChartPreview ? (
          <Row style={{ justifyContent: 'center' }}>
            <Navigation departments={departments} />
          </Row>
        ) : null}
        {isIE ? (
          <Row
            style={{
              justifyContent: 'center',
              backgroundColor: 'red',
              color: 'white',
              padding: 20,
              paddingLeft: 30,
              paddingRight: 30,

              // fontWeight: 'bold',
            }}
          >
            <div style={{ fontSize: 20 }}>
              <FontAwesomeIcon style={{ color: 'white', marginRight: 10 }} icon={faExclamationTriangle} />
              Internet Explorer is not supported. We recommend{' '}
              <a
                href={'https://www.google.com/chrome/downloads/'}
                target={`”_blank`}
                style={{ color: 'white', textDecoration: 'underline', fontWeight: 'bold' }}
              >
                Google Chrome
              </a>
              , Microsoft Edge, or Apple Safari.{' '}
            </div>
            {/* <div>
              <FontAwesomeIcon
                style={{ color: 'white', cursor: 'pointer' }}
                icon={faTimesCircle}
                onClick={() => {
                  setIsIE(!isIE);
                }}
              />
            </div> */}
          </Row>
        ) : null
        //   (
        //   <br />
        // )
        }
        {showTimeSyncWarningBanner && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              textAlign: 'center',
              // paddingTop: 5,
              // paddingBottom: 5,
              backgroundColor: '#f0ad4e',
              justifyContent: 'center',
            }}
          >
            <span>
              <b>
                WARNING: Your system time appears to be improperly set. This may cause issues with the schedule,
                notifications, and joining meetings.
              </b>
            </span>
          </div>
        )}
      </Col>
    </div>
  );
};

export default Header;

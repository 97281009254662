import { faAward, faBalanceScale, faCheck, faCog, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, useContext } from 'react';
import { Link, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { ButtonGroup, Button, Nav, NavItem } from 'reactstrap';
import AdminEvaluationPreview from '../AdminEvaluationSettings/AdminEvaluationPreview/AdminEvaluationPreview';
import AdminEvluationSettingsLanding from '../AdminEvaluationSettings/AdminEvaluationSettingsLanding';
import AdminWeightCategories from './AdminWeightCategories/AdminWeightCategories';
import EvaluationQuestions from '../AdminEvaluationSettings/EvaluationQuestions/EvaluationQuestions';
import style from './AdminEvaluationTemplateLanding.style';
import PreviewEvaluation from './PreviewEvaluation/PreviewEvaluation';
import CustomInput from 'reactstrap/lib/CustomInput';
import { useAuth0 } from '../../../../auth0/reactAuth0Spa';
import { fetchDataAgnostic, putData } from '../../../../Services/dataApi';
import { formatBearerToken } from '../../../../Common.functions';
import { DepartmentContext } from '../../../../DepartmentWrapper';
import { useAlert } from 'react-alert';
import { RouterPrompt } from '../../Common/RouterPrompt/RouterPrompt';

let updateTimeout = null;
const AdminEvaluationTmplateLanding = ({ activeDepartment, activeSeason }) => {
  const { getTokenSilently, loginWithRedirect } = useAuth0();
  const [tabToGo, setTabToGo] = useState(1);
  const [activeTab, setActiveTab] = useState(1);
  const [enablePreScreenQuestionaire, setEnablePreScreenQuestionaire] = useState(false);
  const [enableEvaluationQuestionaire, setEnableEvaluationQuestionaire] = useState(false);
  const [isUpdatingQuestionnaire, setIsUpdatingQuestionnaires] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [pageChanges, setPageChanges] = useState({});
  const history = useHistory();
  const dContext = useContext(DepartmentContext);
  const alert = useAlert();

  const { location } = history;
  const { pathname } = location;

  let hasChanges = false;

  Object.values(pageChanges).forEach((value) => {
    if (value) {
      hasChanges = true;
    }
  });

  useEffect(() => {
    if (pathname) {
      if (pathname.indexOf('/evaluation_questions') >= 0) {
        if (activeTab != 1) {
          setActiveTab(1);
          setTabToGo(1);
        }
      } else if (pathname.indexOf('/weight_categories') >= 0) {
        if (dContext.department.ProgramType === 'Mock Oral Board') {
          if (activeTab != 1) {
            setActiveTab(1);
            setTabToGo(1);
            return;
          }
        }

        if (activeTab != 2) {
          setActiveTab(2);
          setTabToGo(2);
        }
      }
      // else if (pathname.indexOf('/evaluation_settings') >= 0) {
      //   if (activeTab != 3) {
      //     setActiveTab(3);
      //     setTabToGo(3);
      //   }
      // }
      else if (pathname.indexOf('/preview_evaluation') >= 0) {
        if (activeTab != 4) {
          setActiveTab(4);
          setTabToGo(4);
        }
      } else {
        setActiveTab(1);
        setTabToGo(1);
      }
    }
  }, [pathname]);

  useEffect(() => {
    getSeason();
  }, []);

  useEffect(() => {
    if (initialized) {
      updateQuestionnaire();
    }
  }, [enablePreScreenQuestionaire, enableEvaluationQuestionaire]);

  const getSeason = () => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/season',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          formatBearerToken(token),
        )
          .then((res) => {
            if (res.data) {
              setEnableEvaluationQuestionaire(res.data.EnableEvaluationQuestionnaire);
              setEnablePreScreenQuestionaire(res.data.EnablePrescreenQuestionnaire);
              setInitialized(true);
              dContext.setSeason(res.data);
            }
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const updateQuestionnaire = () => {
    clearTimeout(updateTimeout);
    // this timeout is intended to not save settings if the user is toggling the button back and forth. It will only save to the database once the user has
    // not touched the button for 800ms
    updateTimeout = setTimeout(() => {
      setQuestionnaireStatuses();
    }, 800);
  };

  const setQuestionnaireStatuses = () => {
    setIsUpdatingQuestionnaires(true);
    getTokenSilently()
      .then((token) => {
        putData(
          'department/season',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          {
            seasonObject: {
              EnablePrescreenQuestionnaire: enablePreScreenQuestionaire,
              EnableEvaluationQuestionnaire: enableEvaluationQuestionaire,
            },
          },
          formatBearerToken(token),
        )
          .then((res) => {
            alert.success('Updated!');
            setIsUpdatingQuestionnaires(false);
            getSeason();
          })
          .catch((err) => {
            alert.success('Error setting value!');
            setIsUpdatingQuestionnaires(false);
            getSeason();
          });
      })
      .catch((err) => {
        alert.success('Error setting value!');
        setIsUpdatingQuestionnaires(false);
        getSeason();
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const renderContent = () => {
    switch (activeTab) {
      case 1:
        return (
          <EvaluationQuestions
            activeDepartment={activeDepartment}
            activeSeason={activeSeason}
            setPageChanges={setPageChanges}
            pageChanges={pageChanges}
          />
        );
      case 2:
        return (
          <AdminWeightCategories
            activeDepartment={activeDepartment}
            activeSeason={activeSeason}
            setPageChanges={setPageChanges}
            pageChanges={pageChanges}
          />
        );
      // case 3:
      //   return (
      //     <AdminEvluationSettingsLanding
      //       activeDepartment={(activeDepartment, (activeSeason = { activeSeason }))}
      //       setPageChanges={setPageChanges}
      //       pageChanges={pageChanges}
      //     />
      //   );
      case 4:
        return (
          <PreviewEvaluation
            activeDepartment={activeDepartment}
            activeSeason={activeSeason}
            setPageChanges={setPageChanges}
            pageChanges={pageChanges}
          />
        );
    }
  };

  return (
    <div>
      <div style={{ ...style.simpleRow, marginBottom: 20, justifyContent: 'left' }}>
        <div style={{ ...style.simpleColumn, width: '75%', paddingRight: 10 }}>
          <Nav className="nav-tabs">
            <NavItem
              active={activeTab == 1}
              onClick={() => {
                if (!hasChanges) {
                  setActiveTab(1);
                }
                setTabToGo(1);
              }}
            >
              <Link
                className={`nav-link ${activeTab == 1 ? 'active' : ''}`}
                to="/administrator/evaluation_template/evaluation_questions"
              >
                <FontAwesomeIcon icon={faCheck} /> Evaluation Questions
              </Link>
            </NavItem>
            {dContext.department && dContext.department.ProgramType === 'Mock Oral Board' ? null : (
              <NavItem
                active={activeTab == 2}
                onClick={() => {
                  if (!hasChanges) {
                    setActiveTab(2);
                  }
                  setTabToGo(2);
                }}
              >
                <Link
                  className={`nav-link ${activeTab == 2 ? 'active' : ''}`}
                  to="/administrator/evaluation_template/weight_categories"
                >
                  <FontAwesomeIcon icon={faBalanceScale} style={style.buttonIcon} />
                  Weight Categories
                </Link>
              </NavItem>
            )}

            {/* <NavItem
              active={activeTab == 3}
              onClick={() => {
                if (!hasChanges) {
                  setActiveTab(3);
                }
                setTabToGo(3);
              }}
            >
              <Link
                className={`nav-link ${activeTab == 3 ? 'active' : ''}`}
                to="/administrator/evaluation_template/evaluation_settings"
              >
                <FontAwesomeIcon icon={faCog} style={style.buttonIcon} /> Evaluation Settings
              </Link>
            </NavItem> */}
            <NavItem
              active={activeTab == 4}
              onClick={() => {
                if (!hasChanges) {
                  setActiveTab(4);
                }
                setTabToGo(4);
              }}
            >
              <Link
                className={`nav-link ${activeTab == 4 ? 'active' : ''}`}
                to="/administrator/evaluation_template/preview_evaluation"
              >
                <FontAwesomeIcon icon={faEye} style={style.buttonIcon} /> Preview Evaluation
              </Link>
            </NavItem>
          </Nav>
        </div>
        {/* <div style={{ ...style.simpleColumn, width: '25%' }}>
          <CustomInput
            disabled={isUpdatingQuestionnaire}
            id="enableEval"
            type="switch"
            size="sm"
            label={
              <div style={{ color: enableEvaluationQuestionaire ? 'green' : 'red' }}>{`Evaluation Questionnaire is ${
                enableEvaluationQuestionaire ? 'Enabled' : 'Disabled'
              }`}</div>
            }
            checked={enableEvaluationQuestionaire}
            onChange={(e) => {
              setEnableEvaluationQuestionaire(!enableEvaluationQuestionaire);
            }}
          />
          {dContext && dContext.season && dContext.season.AllowPrescreen ? (
            <CustomInput
              disabled={isUpdatingQuestionnaire}
              id="enablePre"
              style={{ fontSize: 1 }}
              type="switch"
              size="sm"
              label={
                <div style={{ color: enablePreScreenQuestionaire ? 'green' : 'red' }}>{`Prescreen Questionnaire is ${
                  enablePreScreenQuestionaire ? 'Enabled' : 'Disabled'
                }`}</div>
              }
              checked={enablePreScreenQuestionaire}
              onChange={(e) => {
                setEnablePreScreenQuestionaire(!enablePreScreenQuestionaire);
              }}
            />
          ) : (
            <br />
          )}
        </div> */}
      </div>
      <div>{renderContent()}</div>
      <RouterPrompt
        hasChanges={hasChanges}
        okLabel={'Discard Changes'}
        onOk={() => {
          setActiveTab(tabToGo);
          setPageChanges({
            weightCategories: false,
          });

          return true;
        }}
      />
    </div>
  );
};

export default AdminEvaluationTmplateLanding;

import React, { useState, useEffect, useContext } from 'react';
import {
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Label,
  Input,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
  CustomInput,
  UncontrolledTooltip,
} from 'reactstrap';
import { useAuth0 } from '../../../../../auth0/reactAuth0Spa';
import { DepartmentContext } from '../../../../../DepartmentWrapper';

import style from './PreviewEvaluation.style';
import AdminEvaluationDreyfusItem from '../../AdminEvaluationSettings/AdminEvaluationQuestionTemplate/AdminEvaluationScaleItem/AdminEvaluationDreyfusItem';
import AdminEvaluationLikertItem from '../../AdminEvaluationSettings/AdminEvaluationQuestionTemplate/AdminEvaluationScaleItem/AdminEvaluationLikertItem';
import { fetchDataAgnostic } from '../../../../../Services/dataApi';
import { formatBearerToken } from '../../../../../Common.functions';
import {
  defaultLikert5QuestionState,
  defaultLikert10QuestionScale,
  defaultDreyfus10QuestionScale,
  questionScaleKeys,
} from '../../AdminEvaluationSettings/AdminEvaluationSettingsConstants';
import {
  faCheck,
  faCheckSquare,
  faChevronDown,
  faChevronUp,
  faInfo,
  faInfoCircle,
  faSquare,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clone } from '../../../../../Services/schedule';
import FocusPalette from '../../../Coordinator/Schedule/FocusPalette';
import UncontrolledPopover from 'reactstrap/lib/UncontrolledPopover';

const PreviewEvaluation = () => {
  const [isUpdating, setIsUpdating] = useState();
  const [questions, setQuestions] = useState([]);

  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [defaultOptions, setDefaultOptions] = useState([]);

  const [focuses, setFocuses] = useState([]);
  const [selectedFocuses, setSelectedFocuses] = useState([]); // pk_Focuses
  const [questionScale, setQuestionScale] = useState(questionScaleKeys.likert5);
  const [showFocusPopover, setShowFocusPopover] = useState(false);
  const [season, setSeason] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [viewMode, setViewMode] = useState('Evaluation');

  const { AllowPrescreen } = season && season.data ? season.data : {};
  const dContext = useContext(DepartmentContext);
  const { getTokenSilently, loginWithRedirect } = useAuth0();

  useEffect(() => {
    getQuestions();
    getScaleData();
    getSeason();
    getFocuses();
  }, []);

  useEffect(() => {
    filterQuestions();
  }, [JSON.stringify(selectedFocuses), viewMode]);

  const filterQuestions = (forcedQuestions) => {
    const newQuestions = [];
    const questionsToFilter = forcedQuestions || questions;
    questionsToFilter.forEach((question) => {
      const newSubQuestions = [];
      const newQuestion = { ...question };

      question.questions.forEach((q) => {
        const { EnableEvaluationForm, EnablePrescreenForm, pk_EvaluationQuestion } = q;
        const qFocuses = q.Focuses.map((f) => {
          return f.pk_Focus;
        });
        const filterFocuses = selectedFocuses.map((f) => {
          return f.pk_Focus;
        });
        let isValid = false;
        let fIndex = 0;

        while (fIndex < qFocuses.length && isValid == false) {
          isValid = filterFocuses.includes(qFocuses[fIndex]);

          fIndex++;
        }

        if (isValid || qFocuses.length == 0) {
          if (
            (viewMode === 'Evaluation' && !EnableEvaluationForm) ||
            (viewMode === 'Prescreen' && !EnablePrescreenForm)
          ) {
          } else {
            newSubQuestions.push(q);
          }
        }
      });
      newSubQuestions.sort((a, b) => {
        const a_index = a.EvaluationQuestionSortOrder;
        const b_index = b.EvaluationQuestionSortOrder;

        return a_index - b_index;
      });
      newQuestion.questions = newSubQuestions;
      newQuestions.push(newQuestion);
    });

    newQuestions.sort((a, b) => {
      return a.SortOrder - b.SortOrder;
    });
    setFilteredQuestions(newQuestions);
  };

  const getQuestions = () => {
    setIsUpdating(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/questions',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          formatBearerToken(token),
        )
          .then((result) => {
            setIsUpdating(false);
            if (result.data && result.data.EvalQuestions) {
              setQuestions(clone(result.data.EvalQuestions));
              filterQuestions(clone(result.data.EvalQuestions));
            } else {
              setQuestions([]);
              setFilteredQuestions([]);
            }

            if (result.data && result.data.DefaultScale) {
              setDefaultOptions(result.data.DefaultScale);
            }
          })
          .catch((err) => {
            setIsUpdating(false);
          });
      })
      .catch((err) => {
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const getScaleData = () => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/season/scale',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          formatBearerToken(token),
        )
          .then((res) => {
            if (res.data) {
              setQuestionScale(res.data.ScaleType || questionScaleKeys.likert5);
            }
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const getSeason = () => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/season',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          formatBearerToken(token),
        )
          .then((res) => {
            setSeason(res);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const getFocuses = () => {
    getTokenSilently().then((token) => {
      fetchDataAgnostic(
        'department/focuses',
        { pk_Department: dContext.department.pk_Department },
        formatBearerToken(token),
      )
        .then((res) => {
          setFocuses(res.data);
        })
        .catch((err) => {
          if (
            err.message === 'Login required' ||
            err.message === 'Unauthorized' ||
            (err.response && err.response.status && err.response.status.toString() === '401')
          ) {
            loginWithRedirect();
          }
        });
    });
  };

  const countFilteredQuestions = () => {
    const rawQuestions = [];
    const questionTracker = { total: 0 };
    const filterFocuses = selectedFocuses.map((f) => {
      return f.pk_Focus;
    });

    questions.forEach((question) => {
      question.questions.forEach((q) => {
        const { Focuses = [], EnableEvaluationForm, EnablePrescreenForm } = q;
        // Check if Question's focuses are in selectedFocuses.
        let isValidQuestion = false;
        Focuses.forEach((f) => {
          const { pk_Focus, Name } = f;
          if (filterFocuses.includes(pk_Focus)) {
            questionTracker[pk_Focus] = questionTracker[pk_Focus] ? questionTracker[pk_Focus] + 1 : 1;
            isValidQuestion = true;
          }
        });

        if (isValidQuestion || Focuses.length == 0) {
          if (
            (viewMode === 'Evaluation' && !EnableEvaluationForm) ||
            (viewMode === 'Prescreen' && !EnablePrescreenForm)
          ) {
          } else {
            questionTracker.total += 1;

            if (Focuses.length <= 0) {
              questionTracker.unfocused = questionTracker.unfocused ? questionTracker.unfocused + 1 : 1;
            } else {
              questionTracker.focused = questionTracker.focused ? questionTracker.focused + 1 : 1;
            }
          }

          // rawQuestions.push(q);
        }

        return;
        // const qFocuses = q.Focuses.map((f) => {
        //   return f.pk_Focus;
        // });
        // const filterFocuses = selectedFocuses.map((f) => {
        //   return f.pk_Focus;
        // });
        // let isValid = false;
        // let fIndex = 0;

        // while (fIndex < qFocuses.length && isValid == false) {
        //   isValid = filterFocuses.includes(qFocuses[fIndex]);
        //   fIndex++;
        // }

        // if (isValid || qFocuses.length == 0) {
        //   rawQuestions.push(q);
        // }
      });
    });

    return questionTracker;
  };

  const renderQuestions = () => {
    const toRender = [];
    if (filteredQuestions.length > 0 && !isUpdating) {
      filteredQuestions.forEach((question) => {
        const questionsActual = question.questions;
        const questionsToRender = [];

        questionsActual.sort((a, b) => {
          const a_index = a.EvaluationQuestionSortOrder;
          const b_index = b.EvaluationQuestionSortOrder;

          return a_index - b_index;
        });

        questionsActual.forEach((q, i) => {
          const { EnableRequired, WeightQuestionType, EnableEvaluationForm, EnablePrescreenForm, Focuses } = q;

          let focusNames = '';
          Focuses.forEach((f) => {
            focusNames += `${f.Name}, `;
          });

          if (viewMode === 'Evaluation' && !EnableEvaluationForm) {
            return;
          }

          if (viewMode === 'Prescreen' && !EnablePrescreenForm) {
            return;
          }

          questionsToRender.push(
            <Card style={{ marginBottom: 20 }}>
              <CardHeader>
                <div style={{ fontWeight: 'bold' }}>
                  {`${questionsToRender.length + 1}.`} {EnableRequired ? <span style={{ color: 'red' }}>*</span> : ''}
                  {`${q.QuestionText}`}
                </div>
              </CardHeader>
              <CardBody>
                <div style={style.simpleRow}>
                  {' '}
                  {WeightQuestionType === 'scale' ? (
                    renderChoices(q)
                  ) : (
                    <Input type="textarea" style={{ width: '100%' }} disabled={true} rows={4} />
                  )}
                </div>
              </CardBody>
            </Card>,
          );
        });

        if (questionsToRender.length > 0) {
          toRender.push(
            <Card style={{ marginBottom: 30 }}>
              <CardHeader>
                <div style={{ fontWeight: 'bold', textAlign: 'center' }}>{`${question.GroupName}`}</div>
              </CardHeader>
              <CardBody>
                <div style={{ ...style.simpleColumn, minHeight: '40vh' }}>{questionsToRender}</div>
              </CardBody>
            </Card>,
          );
        }
      });
    } else {
      toRender.push(
        <div style={{ width: '100%', textAlign: 'center', marginTop: '20vh', marginBottom: '20vh' }}>
          {isUpdating ? 'Loading . . .' : 'No Content'}
        </div>,
      );
    }

    return <div style={style.simpleColumn}>{toRender}</div>;
  };

  const renderChoices = (question) => {
    const choicesToRender = [];
    let choices = defaultOptions;
    let length = 5;
    if (question.CustomScale && question.CustomScale.length > 0) {
      choices = question.CustomScale;
    }
    choices.forEach((item, i) => {
      if (questionScale === 'dreyfus10') {
        choicesToRender.push(
          <AdminEvaluationDreyfusItem i={i} key={item.number} length={choices.length} item={item} isReadOnly={true} />,
        );
      } else {
        choicesToRender.push(
          <AdminEvaluationLikertItem i={i} key={item.number} length={choices.length} item={item} isReadOnly={true} />,
        );
      }
    });

    return choicesToRender;
  };

  const renderFocusPopover = () => {
    return (
      <Popover
        flip={false}
        popperClassName="markPopover"
        isOpen={showFocusPopover}
        trigger="legacy"
        target="focus_button"
        toggle={() => {
          setShowFocusPopover(!showFocusPopover);
        }}
      >
        <PopoverHeader>
          <div style={{ ...style.simpleRow, justifyContent: 'right' }}>
            <FontAwesomeIcon
              icon={faTimes}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setShowFocusPopover(false);
              }}
            />
          </div>
        </PopoverHeader>
        <PopoverBody>
          <div style={{ ...style.simpleColumn, overflowY: 300 }}>
            <FocusPalette
              isUpdating={isUpdating}
              allowEdit={false}
              showIgnoreSwitch={false}
              reference={selectedFocuses}
              updateSelectedFocuses={(newFocuses) => {
                setSelectedFocuses(newFocuses);
              }}
              returnEntireFocusObject={true}
            />
          </div>
        </PopoverBody>
      </Popover>
    );
  };

  const processButtonLabel = () => {
    let label = '';
    const countTracker = countFilteredQuestions();
    const { total = 0, unfocused = 0, focused = 0 } = countTracker;

    if (selectedFocuses.length == 0) {
      label = `${unfocused} unfocused question${unfocused > 1 ? 's' : ''} (Click to filter by focus)`;
    } else {
      let focusNames = '';
      selectedFocuses.forEach((f) => {
        if (focusNames == '') {
          focusNames = f.Name || '(No Name)';
        } else {
          focusNames += `, ${f.Name || '(No Name)'}`;
        }
      });
      label = (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {`${unfocused} unfocused + ${focused} focused questions`}
          </div>
          {
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: 50,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <FontAwesomeIcon icon={faInfoCircle} id="filterInfo" />
              <UncontrolledTooltip target={'filterInfo'}>
                {selectedFocuses.map((f) => {
                  const { pk_Focus, Name } = f;
                  const questionCount = countTracker[pk_Focus] || 0;
                  return (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div>
                        {Name} {`(${questionCount} question${questionCount > 1 ? 's' : ''})`}
                      </div>
                    </div>
                  );
                })}
              </UncontrolledTooltip>
            </div>
          }

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: 50,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <FontAwesomeIcon icon={showFocusPopover ? faChevronUp : faChevronDown} />
          </div>
        </div>
      );
    }

    return label;
  };

  return (
    <div>
      <div style={style.simpleRow}>
        <Card style={{ width: '100%', marginBottom: 30 }} className="z-depth-10">
          <CardHeader>
            <div style={{ ...style.simpleRow, alignItems: 'center', justifyContent: 'left' }}>
              <div style={{ ...style.simpleColumn, width: '10%', verticalAlign: 'middle', fontWeight: 'bold' }}>
                {' '}
                Preview
              </div>
              <div style={{ ...style.simpleColumn, width: '10%', minWidth: 150 }}>
                {AllowPrescreen ? (
                  <div>
                    <Input
                      type="radio"
                      name="radio1"
                      checked={viewMode === 'Evaluation'}
                      onChange={(e) => {
                        setViewMode('Evaluation');
                      }}
                    />{' '}
                    Evaluation
                  </div>
                ) : (
                  'Evaluation'
                )}
              </div>
              <div style={{ ...style.simpleColumn, width: '10%', minWidth: 150 }}>
                {AllowPrescreen ? (
                  <>
                    <Input
                      type="radio"
                      name="radio1"
                      checked={viewMode === 'Prescreen'}
                      onChange={(e) => {
                        setViewMode('Prescreen');
                      }}
                    />{' '}
                    Prescreen
                  </>
                ) : null}
              </div>

              <div style={{ ...style.simpleColumn, width: '70%' }}>
                <div style={{ ...style.simpleRow, alignItems: 'center', justifyContent: 'right' }}>
                  <div style={{ ...style.simpleColumn, width: '50%', minWidth: 450 }}>
                    <Button id="focus_button">{processButtonLabel()}</Button>
                    {renderFocusPopover()}
                  </div>
                </div>
              </div>
            </div>
          </CardHeader>
        </Card>
      </div>

      <div style={{ ...style.simpleRow, height: '50vh', overflowY: 'scroll' }}>{renderQuestions()}</div>
      <div style={{ ...style.simpleRow, position: 'sticky', bottom: -1, backgroundColor: 'white', padding: 20 }}>
        <div style={{ ...style.simpleColumn, alignItems: 'center' }}>
          <div style={style.simpleRow}>
            <div style={{ marginRight: 20, alignItems: 'center' }}>
              <CustomInput
                type="switch"
                id="doNotRank"
                name="doNotRank"
                label="Do Not Rank"
                style={{ cursor: 'pointer', color: 'red' }}
              />
            </div>
            <div style={{ marginRight: 20, alignItems: 'center' }}>
              {/* <CustomInput
                type="switch"
                id="unableToEvaluate"
                name="unableToEvaluate"
                label="Unable To Evaluate"
                style={{ cursor: 'pointer' }}
              /> */}

              <Button id={'unable_to_evaluate'} color="primary">
                Unable To Evaluate
              </Button>
              <UncontrolledPopover target="unable_to_evaluate" trigger="legacy">
                <PopoverHeader></PopoverHeader>
                <PopoverBody>
                  This button will ask your evaluators if they want to clear their feedback and mark as unable to
                  evaluate
                </PopoverBody>
              </UncontrolledPopover>
            </div>

            {/* <Button style={{ marginRight: 10 }}>Do Not Rank</Button>
            <Button style={{ marginRight: 10 }}>Unable To Evaluate</Button> */}
          </div>
        </div>
        <div style={{ ...style.simpleColumn, alignItems: 'center' }}>
          <div style={{ ...style.simpleRow, textAlign: 'center', fontWeight: 'bold' }}>
            Questions marked with "{<span style={{ color: 'red' }}>*</span>}" are required.
          </div>
        </div>
        <div style={style.simpleColumn}>
          <div style={{ ...style.simpleRow, justifyContent: 'right' }}>
            <Button style={{ width: 200 }} color="success" id="complete_btn">
              <div style={{ ...style.spacedBetweenRow, alignItems: 'center' }}>
                <FontAwesomeIcon icon={faCheck} />
                <div style={style.centeredText}>Complete</div>
              </div>
            </Button>
            <UncontrolledPopover target="complete_btn" trigger="legacy">
              <PopoverHeader></PopoverHeader>
              <PopoverBody>This button will submit the evaluation form and mark it as complete</PopoverBody>
            </UncontrolledPopover>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewEvaluation;

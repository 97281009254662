import { faCircle, faDotCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState, useContext } from 'react';
import { Button, Card, CardBody, CardHeader, CustomInput, FormFeedback, Input } from 'reactstrap';
import { useAuth0 } from '../../../../auth0/reactAuth0Spa';
import { formatBearerToken, roundToDecimalPlace } from '../../../../Common.functions';
import { fetchDataAgnostic, postDataAgnostic, putData } from '../../../../Services/dataApi';
import style from './AdminEvaluationSettingsLanding.style';
import AdminEvaluationQuestionTemplate from './AdminEvaluationQuestionTemplate/AdminEvaluationQuestionTemplate';
import {
  defaultLikert5QuestionState,
  questionScaleKeys,
  defaultLikert10QuestionScale,
  defaultDreyfus10QuestionScale,
} from './AdminEvaluationSettingsConstants';
import { useHistory } from 'react-router-dom';
import { DepartmentContext } from '../../../../DepartmentWrapper';
import { clone } from '../../../../Services/schedule';
import Swal from 'sweetalert2';
import { useAlert } from 'react-alert';
import Focuses from '../AdminSettings/Focuses/Focuses';

let updateTimeout = null;

const AdminEvluationSettingsLanding = ({ setPageChanges, pageChanges, updateChanges }) => {
  const [questionScale, setQuestionScale] = useState(questionScaleKeys.likert5);
  const [scaleData, setScaleData] = useState(questionScaleKeys.likert5);
  const [questionChoices, setQuestionChoices] = useState([]);
  const [editableQuestionChoices, setEditableQuestionChoices] = useState([]);
  const [initialized, setInitialized] = useState(false);
  const [PassingScore, setPassingScore] = useState(0);
  const [maxPassingScore, setMaxPassingScore] = useState(0); // editableQuestionChoices.length
  const [rawSeasonSettings, setRawSeasonSettings] = useState({});

  const [season, setSeason] = useState({});

  const [prescreenState, setPrescreenState] = useState(false); // if Allow prescreen
  const [prescreenMode, setPrescreenMode] = useState(false); // if EnablePrescreenMode (the header dropdown thing)
  const [enablePreScreenQuestionaire, setEnablePreScreenQuestionaire] = useState(false);
  const [enablePrescreenResultsToEvaluators, setEnablePrescreenResultsToEvaluators] = useState(false);
  const [enableEvaluationQuestionaire, setEnableEvaluationQuestionaire] = useState(false);

  const [hasChange, setHasChange] = useState(false);
  const [questionsHaveChange, setQuestionsHaveChange] = useState(false);
  const [scaleHasChange, setScaleHasChange] = useState(false);

  const [isUpdatingPreScreenState, setIsUpdatingPreScreenState] = useState(false);
  const [isUpdatingQuestionnaire, setIsUpdatingQuestionnaires] = useState(false);
  const [isUpdatingOptions, setIsUpdatingOptions] = useState(false);
  const [isUpdatingScaleType, setIsUpdatingScaleType] = useState(false);

  const dContext = useContext(DepartmentContext);
  const { department } = dContext || {};
  const { ProgramType } = department || {};
  const { getTokenSilently, loginWithRedirect } = useAuth0();
  const alert = useAlert();

  useEffect(() => {
    getScaleData();
    getSeasonSettings();
  }, []);

  useEffect(() => {
    if (JSON.stringify(editableQuestionChoices) !== JSON.stringify(questionChoices)) {
      setQuestionsHaveChange(true);
    } else {
      setQuestionsHaveChange(false);
    }
  }, [editableQuestionChoices]);

  useEffect(() => {
    setPageChanges({
      evaluation: hasChange,
    });
  }, [hasChange]);

  useEffect(() => {
    if (initialized) {
      if (scaleData != questionScale) {
        setScaleHasChange(true);
      } else {
        setScaleHasChange(false);
      }
    }
  }, [questionScale, scaleData]);

  useEffect(() => {
    if (editableQuestionChoices) {
      setMaxPassingScore(editableQuestionChoices.length);
    }
  }, [editableQuestionChoices]);
  const updateLocalBooleans = (seasonData) => {
    const {
      AllowPrescreen,
      EnableEvaluationQuestionnaire,
      EnablePrescreenQuestionnaire,
      EnablePrescreenMode,
      EnablePrescreenVisibleToAllEvaluators,
    } = seasonData;
    setPrescreenState(AllowPrescreen);
    setEnableEvaluationQuestionaire(EnableEvaluationQuestionnaire);
    setEnablePreScreenQuestionaire(EnablePrescreenQuestionnaire);
    setPrescreenMode(EnablePrescreenMode);
    setEnablePrescreenResultsToEvaluators(EnablePrescreenVisibleToAllEvaluators);

    const newSeason = clone(dContext.season);
    newSeason.AllowPrescreen = AllowPrescreen;
    newSeason.EnableEvaluationQuestionnaire = EnableEvaluationQuestionnaire;
    newSeason.EnablePrescreenQuestionnaire = EnablePrescreenQuestionnaire;
    newSeason.EnablePrescreenMode = EnablePrescreenMode;
    newSeason.EnablePrescreenVisibleToAllEvaluators = EnablePrescreenVisibleToAllEvaluators;

    dContext.setSeason(newSeason);
  };

  const getSeasonSettings = () => {
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/season',
          {
            pk_Department: dContext.department.pk_Department,
            pk_Season: dContext.season.pk_Season,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            setSeason(res.data);
            updateLocalBooleans(res.data);
            setRawSeasonSettings(res.data);
            setPassingScore(res.data.PassingScore || 0);
            setInitialized(true);
          })
          .catch((err) => {});
      })
      .catch((err) => {
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const getScaleData = () => {
    setIsUpdatingOptions(true);
    getTokenSilently()
      .then((token) => {
        fetchDataAgnostic(
          'department/season/scale',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          formatBearerToken(token),
        )
          .then((res) => {
            setIsUpdatingOptions(false);
            if (res.data) {
              if (res.data.QuestionOptions) {
                setQuestionChoices(res.data.QuestionOptions);
                setEditableQuestionChoices(res.data.QuestionOptions);
              }
              setQuestionScale(res.data.ScaleType || questionScaleKeys.likert5);
              setScaleData(res.data.ScaleType || questionScaleKeys.likert5);
            }
          })
          .catch((err) => {
            setIsUpdatingOptions(false);
          });
      })
      .catch((err) => {
        setIsUpdatingOptions(false);
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const checkScaleMatch = (term) => {
    if (questionScale === term) return true;

    return false;
  };

  // Updates local editable question list. editable question list compared with
  // reference list to see changes
  const updateQuestion = (index, key, value) => {
    const newQuestionList = clone(editableQuestionChoices);
    const newItem = clone(newQuestionList[index]);
    newItem[key] = value;
    // newItem['DisplayValue'] = '1'; // Forced to fix the null value currently.

    newQuestionList[index] = newItem;
    setEditableQuestionChoices(newQuestionList);
  };

  const saveScaleType = (type) => {
    setIsUpdatingScaleType(true);
    getTokenSilently()
      .then((token) => {
        putData(
          'department/season/scale',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          {
            ScaleType: type,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            setScaleHasChange(false);
            setIsUpdatingScaleType(false);
            getScaleData();

            alert.success('Scale Type saved!');
          })
          .catch((err) => {
            setIsUpdatingScaleType(false);
            alert.error('Error saving scale type!');
          });
      })
      .catch((err) => {
        setIsUpdatingScaleType(false);
        alert.error('Error saving scale type!');
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const saveQuestionChoices = (callbacks) => {
    const { resolve, reject } = callbacks || {};

    editableQuestionChoices.forEach((eqc) => {
      delete eqc.ScaleType;
      delete eqc.pk_QuestionOptionGroup;
    });
    setIsUpdatingOptions(true);
    getTokenSilently()
      .then((token) => {
        putData(
          'department/season/scale/options',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          {
            payload: editableQuestionChoices,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            setQuestionsHaveChange(false);
            if (resolve) {
              resolve();
            }
            setIsUpdatingOptions(false);
            setHasChange(false);
            alert.success('Saved new Default options!');
          })
          .catch((err) => {
            if (reject) {
              reject();
            }
            setIsUpdatingOptions(false);
            alert.error('Error saving new Default options!');
          });
      })
      .catch((err) => {
        setIsUpdatingOptions(false);
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const updateNonQuestions = (keyValues) => {
    const seasonObject = {};
    Object.keys(keyValues).forEach((key) => {
      seasonObject[key] = keyValues[key];
    });

    // AllowPrescreen: prescreenState ? 1 : 0,
    // EnablePrescreenQuestionnaire: enablePreScreenQuestionaire,
    // EnableEvaluationQuestionnaire: enableEvaluationQuestionaire,
    // EnablePrescreenMode: prescreenMode,

    setIsUpdatingQuestionnaires(true);
    getTokenSilently()
      .then((token) => {
        putData(
          'department/season',
          { pk_Department: dContext.department.pk_Department, pk_Season: dContext.season.pk_Season },
          {
            seasonObject,
          },
          formatBearerToken(token),
        )
          .then((res) => {
            setIsUpdatingQuestionnaires(false);
            setSeason(res.data);
            // updateLocalBooleans(res.data);
            getSeasonSettings();
            alert.success('Settings Updated!');
          })
          .catch((err) => {
            setIsUpdatingQuestionnaires(false);
            alert.error('Settings Update Failed!');
          });
      })
      .catch((err) => {
        alert.error('Settings Update Failed!');
        setIsUpdatingQuestionnaires(false);
        if (
          err.message === 'Login required' ||
          err.message === 'Unauthorized' ||
          (err.response && err.response.status && err.response.status.toString() === '401')
        ) {
          loginWithRedirect();
        }
      });
  };

  const renderManageFocuses = () => {
    return <Focuses dContext={dContext} updateChanges={updateChanges} />;
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row', overflowY: 'scroll', height: 'calc(100vh - 320px)' }}>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div style={{ ...style.simpleRow, paddingLeft: 15, paddingBottom: 20 }}>
          <div style={{ ...style.simpleColumn, fontSize: 22, width: '75%', fontWeight: 'bold' }}>
            Season: {season && season.SeasonName ? season.SeasonName : '---'}
          </div>
        </div>
        <div
          style={{
            ...style.spaceBetweenRow,
            paddingLeft: 15,
            paddingRight: 15,
            marginBottom: 30,
            alignItems: 'flex-start',
          }}
        >
          {ProgramType !== 'Mock Oral Board' ? (
            <>
              <div style={{ ...style.simpleColumn, width: '27%' }}>
                <Card className="shadow">
                  <CardHeader>
                    <b>Prescreen with RezRATE</b>
                  </CardHeader>
                  <CardBody>
                    <div style={style.spaceBetweenRow}>
                      <div style={{ ...style.simpleColumn }}>
                        <CustomInput
                          disabled={isUpdatingQuestionnaire}
                          id="prescreenCandidates"
                          type="radio"
                          size="sm"
                          label={'Prescreen Candidates'}
                          checked={prescreenState}
                          onChange={(e) => {
                            setPrescreenState(true);
                            updateNonQuestions({ AllowPrescreen: 1 });
                          }}
                        />
                      </div>
                      <div style={{ ...style.simpleColumn }}>
                        <CustomInput
                          disabled={isUpdatingQuestionnaire}
                          id="doNotPrescreen"
                          type="radio"
                          size="sm"
                          label={'Do not Prescreen Candidates'}
                          checked={!prescreenState}
                          onChange={(e) => {
                            setPrescreenState(false);
                            const toUpdate = {};
                            toUpdate.AllowPrescreen = 0;

                            // setPrescreenMode(false);
                            // toUpdate.EnablePrescreenMode = 0;
                            // toUpdate.EnableEvaluationQuestionnaire = true;

                            updateNonQuestions(toUpdate);
                          }}
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>

              <div style={{ ...style.simpleColumn, width: '40%' }}>
                <Card className="shadow">
                  <CardHeader>
                    <b>Prescreen Questionnaire</b>
                  </CardHeader>
                  <CardBody>
                    <div style={style.spaceBetweenRow}>
                      <div style={{ ...style.simpleColumn }}>
                        <CustomInput
                          disabled={isUpdatingQuestionnaire || !prescreenState}
                          id="prescreenEnabled"
                          type="switch"
                          size="sm"
                          label={'Enable for Evaluators'}
                          checked={enablePreScreenQuestionaire && prescreenState}
                          onChange={(e) => {
                            const newValue = !enablePreScreenQuestionaire;
                            setEnablePreScreenQuestionaire(newValue);
                            const toUpdate = {};

                            if (!newValue) {
                              // setPrescreenMode(false);
                              // toUpdate.EnablePrescreenMode = false;
                              // toUpdate.EnableEvaluationQuestionnaire = true;
                            }
                            toUpdate.EnablePrescreenQuestionnaire = newValue;
                            updateNonQuestions(toUpdate);
                          }}
                        />
                      </div>
                      <div style={{ ...style.simpleColumn }}>
                        <CustomInput
                          disabled={isUpdatingQuestionnaire || !prescreenState}
                          id="prescreenResultsEnabled"
                          type="switch"
                          size="sm"
                          label={'Prescreen Results Visible to ALL Evaluators'}
                          checked={enablePrescreenResultsToEvaluators && prescreenState}
                          onChange={(e) => {
                            const newValue = !enablePrescreenResultsToEvaluators;
                            setEnablePrescreenResultsToEvaluators(newValue);
                            const toUpdate = {};

                            if (!newValue) {
                              // setPrescreenMode(false);
                              // toUpdate.EnablePrescreenMode = false;
                              // toUpdate.EnableEvaluationQuestionnaire = true;
                            }
                            toUpdate.EnablePrescreenVisibleToAllEvaluators = newValue;
                            updateNonQuestions(toUpdate);
                          }}
                        />
                      </div>
                      <div style={{ ...style.simpleColumn }}>
                        <CustomInput
                          disabled={isUpdatingQuestionnaire || !prescreenState}
                          id="defaultPrescreen"
                          type="checkbox"
                          size="sm"
                          label={'Default'}
                          checked={prescreenMode && prescreenState}
                          onChange={(e) => {
                            if (!prescreenMode) {
                              setPrescreenMode(true);

                              updateNonQuestions({ EnablePrescreenMode: true });
                            }
                          }}
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </>
          ) : (
            <div style={{ ...style.simpleColumn, width: '48%' }}>
              <Card className="shadow">
                <CardHeader>
                  <b>Passing Score</b>
                </CardHeader>
                <CardBody>
                  <div style={style.simpleRow}>
                    <div style={{ ...style.simpleColumn }}>
                      <Input
                        type="number"
                        disabled={isUpdatingQuestionnaire}
                        value={PassingScore}
                        invalid={
                          !isUpdatingQuestionnaire &&
                          (PassingScore < 1 || PassingScore > editableQuestionChoices.length)
                        }
                        onChange={(e) => {
                          let newValue = parseFloat(e.target.value);
                          if (newValue > editableQuestionChoices.length) {
                            newValue = editableQuestionChoices.length;
                          } else if (newValue < 1) {
                            newValue = 1;
                          }
                          setPassingScore(roundToDecimalPlace(newValue, 2));
                        }}
                      />
                      <FormFeedback>Passing Score is greater than the max score!</FormFeedback>
                    </div>
                    <div style={{ ...style.simpleColumn, paddingLeft: 10 }}>
                      <Button
                        disabled={
                          isUpdatingQuestionnaire ||
                          (rawSeasonSettings && PassingScore === rawSeasonSettings.PassingScore)
                        }
                        color={'success'}
                        onClick={() => {
                          updateNonQuestions({ PassingScore });
                        }}
                      >
                        {' '}
                        Save{' '}
                      </Button>
                    </div>
                    {/* <div style={{ ...style.simpleColumn }}>
                  <CustomInput
                    disabled={isUpdatingQuestionnaire || !prescreenState}
                    id="defaultPrescreen"
                    type="checkbox"
                    size="sm"
                    label={'Default'}
                    checked={prescreenMode && prescreenState}
                    onChange={(e) => {
                      if (!prescreenMode) {
                        setPrescreenMode(true);

                        updateNonQuestions({ EnablePrescreenMode: true });
                      }
                    }}
                  />
                </div> */}
                  </div>
                </CardBody>
              </Card>
            </div>
          )}

          <div style={{ ...style.simpleColumn, width: ProgramType === 'Mock Oral Board' ? '50%' : '28%' }}>
            <Card className="shadow">
              <CardHeader>
                <b>Evaluation Questionnaire</b>
              </CardHeader>
              <CardBody>
                <div style={style.spaceBetweenRow}>
                  <div style={{ ...style.simpleColumn }}>
                    <CustomInput
                      disabled={isUpdatingQuestionnaire}
                      id="evaluationEnabled"
                      type="switch"
                      size="sm"
                      label={'Enable for Evaluators'}
                      checked={enableEvaluationQuestionaire}
                      onChange={(e) => {
                        const newValue = !enableEvaluationQuestionaire;
                        setEnableEvaluationQuestionaire(newValue);
                        updateNonQuestions({ EnableEvaluationQuestionnaire: newValue });
                      }}
                    />
                  </div>
                  <div style={{ ...style.simpleColumn }}>
                    <CustomInput
                      disabled={isUpdatingQuestionnaire}
                      id="defaultEvaluation"
                      type="checkbox"
                      size="sm"
                      label={'Default'}
                      checked={!prescreenMode || !prescreenState}
                      onChange={(e) => {
                        if (prescreenMode) {
                          setPrescreenMode(false);
                          updateNonQuestions({ EnablePrescreenMode: false });
                        }
                      }}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>

        <div style={{ marginBottom: 30 }}>
          <AdminEvaluationQuestionTemplate
            questionOptions={editableQuestionChoices.slice()}
            questionScale={questionScale}
            scaleData={scaleData}
            saveScaleType={saveScaleType}
            updateQuestion={updateQuestion}
            isUpdatingOptions={isUpdatingOptions}
            isUpdatingScaleType={isUpdatingScaleType}
            setQuestionScale={setQuestionScale}
            checkScaleMatch={checkScaleMatch}
            saveQuestionChoices={saveQuestionChoices}
            questionsHaveChange={questionsHaveChange}
          />
        </div>
        <div style={{ ...style.simpleRow, paddingLeft: 15, paddingBottom: 20 }}>
          <div style={{ ...style.simpleColumn, width: '100%' }}>
            <Card className="shadow">
              <CardHeader>
                {' '}
                <b>Manage Evaluation Focuses</b>
              </CardHeader>
              <CardBody>{renderManageFocuses()}</CardBody>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminEvluationSettingsLanding;

//  swap likert 10 and dryefus 10 buttons
